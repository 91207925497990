<!-- <ng-container *ngIf="latestModification.length > 0"> -->

<mat-card-header class="mcd-card-header-dateModified">
  <mat-card-title
    class="mcd-card-title"
    style="font-weight: none; padding: 6px 0 6px 0"
    >Global Approved Solutions</mat-card-title
  >
</mat-card-header>
<!-- <div class="latest-modification">Global Approved Solutions</div> -->
<mat-card class="mcd-latest-modification">
  <div *ngIf="dependentStandards.length == 0" style="font-size: 13px">
    There are currently no global approved solutions for this standard.
  </div>
  <mat-card-content
    *ngFor="let record of dependentStandards; let last = last"
    style="cursor: pointer"
    routerLink="{{
      redirectGlobalApproved + record.id
  }}"
  >
    <div class="mcd-latest-modification-title">{{record.title}}</div>
    <div class="mcd-latest-modification-date">{{ record.category.name }}</div>
    <div class="mcd-latest-modification-description">
      {{ record.description}}
    </div>
    <br />
    <!-- <div class="mcd-latest-modification-creator">Location</div>
    <div class="mcd-latest-modification-role">{{ record.location }}</div> -->
    <hr *ngIf="!last" />
  </mat-card-content>
</mat-card>
<!-- </ng-container> -->
