<!-- <ng-container *ngIf="latestModification.length > 0"> -->

<mat-card-header class="mcd-card-header-dateModified">
  <mat-card-title
    class="mcd-card-title"
    style="font-weight: none; padding: 6px 0 6px 0"
    >Exceptions</mat-card-title
  >
</mat-card-header>
<!-- <div class="latest-modification">Global Approved Solutions</div> -->
<mat-card class="mcd-latest-modification">
  <div *ngIf="addendums.length == 0" style="font-size: 13px">
    There are currently no Exceptions.
  </div>
  <mat-card-content
    *ngFor="let record of addendums; let last = last"
    style="cursor: pointer"
    routerLink="{{
      redirectUpcomingException + record.id
  }}"
  >
    <!-- <div class="mcd-latest-modification-title">{{record.title}}</div> -->
    <!-- <div class="mcd-latest-modification-date">{{ record.category.name }}</div> -->
    <div class="mcd-latest-modification-title">{{ record.description}}</div>
    <br />
    <div class="mcd-latest-modification-creator">Countries</div>
    <div class="mcd-latest-modification-role">{{ record.country.name }}</div>
    <hr *ngIf="!last" />
  </mat-card-content>
</mat-card>
<!-- </ng-container> -->
