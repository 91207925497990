<div class="mcd-left-panel">
  <div class="mcd-side-nav-header">
    <button class="side-nav close-button material-icons">close</button>
  </div>
  <mat-card class="mcd-nav-card">
    <mat-card-header>
      <div class="mat-card-header-text">
        <mat-card-title class="mat-card-header-text"></mat-card-title>
      </div>
    </mat-card-header>
    <mat-card-content>
      <ul
        class="mcd-nav-list clearfix"
        *ngIf="
          viewAllGrsStandard ||
          showLocation ||
          canAddNewGrs ||
          showAddNewEquipment
        "
      >
        <li class="col-4" *ngIf="showLocation">
          <a
            class=""
            routerLink="/locations"
            routerLinkActive="active"
            href="javascript:void(0)"
            (click)="resetFilteration('/locations')"
          >
            <span class="nav-icon mcd-location"></span>
            <span class="nav-text">Home</span>
          </a>
        </li>

        <li class="col-4" *ngIf="canAddNewGrs">
          <a
            class=""
            routerLink="/add-grs"
            routerLinkActive="active"
            href="javascript:void(0)"
            (click)="resetFilteration('/add-grs')"
          >
            <span class="nav-icon mcd-add-new-grbes"></span>
            <span class="nav-text">Add New System Standard</span>
          </a>
        </li>
        <li class="col-4" *ngIf="canAddEquipmentStandard">
          <a
            class=""
            routerLink="/add-eq-standards-global"
            (click)="resetFilteration('/add-eq-standards-global')"
          >
            <span class="nav-icon mcd-add-new-equipment"></span>
            <span class="nav-text">Add New Equipment Standard</span>
          </a>
        </li>
        <li class="col-4" *ngIf="viewAllGrsStandard">
          <a
            class=""
            routerLink="/view-all-standards"
            routerLinkActive="active"
            href="javascript:void(0)"
            (click)="resetFilteration('/view-all-minimum-standard')"
          >
            <span class="nav-icon mcd-view-all-min-standards"></span>
            <span class="nav-text">View All Standards</span>
          </a>
        </li>
        <!-- <li class="col-4" *ngIf="viewAllGrsStandard">
          <a
            class=""
            routerLink="/view-all-minimum-standard"
            routerLinkActive="active"
            href="javascript:void(0)"
            (click)="resetFilteration('/view-all-minimum-standard')"
          >
            <span class="nav-icon mcd-view-all-min-standards"></span>
            <span class="nav-text">View All Building Standards</span>
          </a>
        </li> -->
        <!-- <li class="col-4" *ngIf="viewEquipmentGlobalStandard">
          <a
            class=""
            routerLink="/view-equipments-standard-list"
            routerLinkActive="active"
            href="javascript:void(0)"
            (click)="resetFilteration('/view-equipments-standard-list')"
          >
            <span class="nav-icon mcd-view-all-equipment-standards"></span>
            <span class="nav-text">View All Equipment Standards</span>
          </a>
        </li> -->
        <!-- <li class="col-4" *ngIf="viewOperationsStandard">
          <a
            class=""
            routerLink="/view-all-operations-standards"
            routerLinkActive="active"
            href="javascript:void(0)"
            (click)="resetFilteration('/view-all-operations-standards')"
          >
            <span class="nav-icon mcd-view-all-operations-standards"></span>
            <span class="nav-text">View All Operations Standards</span>
          </a>
        </li> -->

        <!-- <li class="col-4" *ngIf="viewAllSharedLearningsBestPractices">
          <a
            class=""
            routerLink="/view-all-global-recommendations"
            routerLinkActive="active"
            href="javascript:void(0)"
            (click)="
              resetFilteration('/view-all-global-recomendations-standard')
            "
          >
            <span class="nav-icon mcd-view-all-min-standards"></span>
            <span class="nav-text">View Global Approved Solutions</span>
          </a>
        </li> -->
        <li class="col-4" *ngIf="viewFutureStandard">
          <a
            class=""
            routerLink="/view-all-upcoming-standards-list"
            routerLinkActive="active"
            href="javascript:void(0)"
            (click)="resetFilteration('/view-all-upcoming-standards-list')"
          >
            <span class="nav-icon mcd-view-all-upcoming-standards"></span>
            <span class="nav-text">View All Upcoming Standards</span>
          </a>
        </li>
        <li class="col-4" *ngIf="viewEquipmentObsoleteStandard">
          <a
            class=""
            routerLink="/view-all-obsolete-standards-list"
            routerLinkActive="active"
            href="javascript:void(0)"
            (click)="resetFilteration('/view-all-obsolete-standards-list')"
          >
            <span class="nav-icon mcd-view-all-obsolete-standards"></span>
            <span class="nav-text">View All Obsolete Equipment</span>
          </a>
        </li>
      </ul>
    </mat-card-content>
  </mat-card>

  <!-- <mat-card class="mcd-nav-card">
    <mat-card-header>
      <div class="mat-card-header-text">
        <mat-card-title class="mat-card-header-text"
          >Additional Information</mat-card-title
        >
      </div>
    </mat-card-header>
    <mat-card-content>
      <ul class="mcd-nav-list clearfix">
        <li class="col-4" *ngIf="viewFutureStandard">
          <a
            class=""
            routerLink="/view-all-upcoming-standards-list"
            routerLinkActive="active"
            href="javascript:void(0)"
            (click)="resetFilteration('/view-all-upcoming-standards-list')"
          >
            <span class="nav-icon mcd-view-all-upcoming-standards"></span>
            <span class="nav-text">View All Upcoming Standards</span>
          </a>
        </li>
        <li class="col-4" *ngIf="viewEquipmentObsoleteStandard">
          <a
            class=""
            routerLink="/view-all-obsolete-standards-list"
            routerLinkActive="active"
            href="javascript:void(0)"
            (click)="resetFilteration('/view-all-obsolete-standards-list')"
          >
            <span class="nav-icon mcd-view-all-obsolete-standards"></span>
            <span class="nav-text">View All Obsolete Equipment</span>
          </a>
        </li>
      </ul>
    </mat-card-content>
  </mat-card> -->

  <mat-card class="mcd-nav-card">
    <mat-card-header>
      <div class="mat-card-header-text">
        <mat-card-title class="mat-card-header-text"
          >Market & BU Standards</mat-card-title
        >
      </div>
    </mat-card-header>
    <mat-card-content>
      <ul class="mcd-nav-list clearfix">
        <li class="col-4" *ngIf="viewAllNrbesStandard || canAddNewNrbes">
          <a
            class=""
            *ngIf="canAddNewNrbes"
            href="javascript:void(0)"
            (click)="redirectToAddNrbes()"
          >
            <span class="nav-icon mcd-add-new-grbes2"></span>
            <span class="nav-text">Add New Market Standard</span>
          </a>
        </li>

        <li class="col-4" *ngIf="userRole == userRoleEnum.AOWAdmin">
          <a class="" href="javascript:void(0)" (click)="redirectToAddABU()">
            <span class="nav-icon mcd-add-new-grbes"></span>
            <span class="nav-text">Add New BU Standard</span>
          </a>
        </li>

        <li class="col-4" *ngIf="CanAddNationalEquipmentStandard">
          <a
            class=""
            routerLink="/add-eq-standards"
            routerLinkActive="active"
            (click)="resetFilteration('/add-eq-standards')"
          >
            <span class="nav-icon mcd-add-new-equipment"></span>
            <span class="nav-text">Add New Equipment Standard</span>
          </a>
        </li>

        <li class="col-4" *ngIf="viewAllNrbesStandard">
          <a
            routerLink="/nrbeslist"
            routerLinkActive="active"
            (click)="resetFilteration('/nrbeslist')"
          >
            <span class="nav-icon mcd-view-all-standards"></span>
            <span class="nav-text">View All BU / Market Standards</span>
          </a>
        </li>

        <li class="col-4" *ngIf="viewEquipmentNationalStandard">
          <a
            class=""
            routerLink="/view-all-national-equipment-standard-list"
            routerLinkActive="active"
            href="javascript:void(0)"
            (click)="
              resetFilteration('/view-all-national-equipment-standard-list')
            "
          >
            <span class="nav-icon mcd-view-all-equipment-standards"></span>
            <span class="nav-text">View All Equipment Standards</span>
          </a>
        </li>
        <li class="col-4" *ngIf="viewMarketAdminPanel">
          <a
            class=""
            routerLink="/market-admin-panel"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <span class="nav-icon mcd-market-admin-panel"></span>
            <span class="nav-text">Admin Panel</span>
          </a>
        </li>
      </ul>
    </mat-card-content>
  </mat-card>

  <mat-card class="mcd-nav-card" *ngIf="viewAllAddendum || viewAllNotification">
    <mat-card-header>
      <div class="mat-card-header-text">
        <mat-card-title class="mat-card-header-text">Exceptions</mat-card-title>
      </div>
    </mat-card-header>
    <mat-card-content>
      <ul class="mcd-nav-list clearfix">
        <li class="col-4" *ngIf="viewAllAddendum">
          <a
            routerLink="/equipment-addendum-list"
            routerLinkActive="active"
            (click)="resetFilteration('/equipment-addendum-list')"
          >
            <span class="nav-icon mcd-view-all-addendum2"></span>
            <span class="nav-text">View All Equipment Exceptions</span>
          </a>
        </li>
        <li class="col-4" *ngIf="viewAllAddendum">
          <a
            routerLink="/addendum-list"
            routerLinkActive="active"
            (click)="resetFilteration('/addendum-list')"
          >
            <span class="nav-icon mcd-view-all-addendum"></span>
            <span class="nav-text"
              >View all Exceptions</span
            >
          </a>
        </li>
        <li class="col-4" *ngIf="viewAllNotification">
          <a
            routerLink="/notification-list"
            routerLinkActive="active"
            (click)="resetFilteration('/notification-list')"
          >
            <span class="nav-icon mcd-view-all-notifications"></span>
            <span class="nav-text">View All Notifications</span>
          </a>
        </li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card class="mcd-nav-card" *ngIf="viewFaq || viewKeyContacts">
    <mat-card-header>
      <div class="mat-card-header-text">
        <mat-card-title class="mat-card-header-text"
          >Tool Information</mat-card-title
        >
      </div>
    </mat-card-header>
    <mat-card-content>
      <ul class="mcd-nav-list clearfix">
        <li class="col-4" *ngIf="viewGlobalAdminPanel">
          <a
            class=""
            routerLink="/global-admin-panel"
            routerLinkActive="active"
            href="javascript:void(0)"
          >
            <span class="nav-icon mcd-global-admin-panel"></span>
            <span class="nav-text">Global Admin Panel</span>
          </a>
        </li>
      </ul>

      <ul class="mcd-nav-list none-float-menu clearfix">
        <li *ngIf="viewKeyContacts">
          <a
            routerLink="/keycontact"
            routerLinkActive="active"
            (click)="resetFilteration('/keycontact')"
          >
            <span class="nav-icon mcd-key-contacts"></span>
            <span class="nav-text">Key Contacts</span>
          </a>
        </li>
        <li *ngIf="viewFaq">
          <a
            routerLink="/faq"
            routerLinkActive="active"
            (click)="resetFilteration('/faq')"
          >
            <span class="nav-icon mcd-faq"></span>
            <span class="nav-text">FAQ</span>
          </a>
        </li>

        <li *ngIf="viewFaq">
          <a
            href="https://spo.mcd.com/:f:/r/sites/atmcd_global/Global%20Development%20%20Design/GRBES/Training%20Materials?csf=1&web=1&e=WcPUFW"
            target="_blank"
          >
            <span class="nav-icon mcd-training-link"></span>
            <span class="nav-text">Training Link</span>
          </a>
        </li>
      </ul>
    </mat-card-content>
  </mat-card>

  <mat-card class="mcd-nav-card" *ngIf="viewFaq || viewKeyContacts">
    <mat-card-header>
      <div class="mat-card-header-text">
        <mat-card-title class="mat-card-header-text"
          >Useful Links</mat-card-title
        >
      </div>
    </mat-card-header>
    <mat-card-content>
      <ul
        *ngIf="usefulLinkList != null"
        class="mcd-nav-list none-float-menu clearfix"
      >
        <li *ngFor="let item of usefulLinkList.usefullinks">
          <div *ngIf="item.url != ''">
            <a href="{{ item.url }}" target="_blank">
              <span class="nav-icon mcd-up"></span>
              <span class="nav-text">{{ item.name }}</span>
            </a>
          </div>

          <div *ngIf="item.url == ''">
            <a
              href="javascript:void(0);"
              (click)="onDownloadSelectedUsefulLinkAttachment(item)"
            >
              <span class="nav-icon mcd-down"></span>
              <span class="nav-text">{{ item.name }}</span>
            </a>
          </div>
        </li>
        <!--<li>
    <a href="https://spo.mcd.com/sites/global_rsg/Pages/grbes.aspx" target="_blank">
      <span class="nav-icon mcd-equipment-capacity-tool"></span>
      <span class="nav-text">Equipment Capacity Tool</span>
    </a>
  </li>

  <li>
    <a href="https://rightsizingcalculator.com/" target="_blank">
      <span class="nav-icon mcd-cal"></span>
      <span class="nav-text">Right Sizing Calculator</span>
    </a>
  </li>
  <li>
    <a href="/Documents/GRBES - Standards Quick Reference Guide - Equipment - March 2021.pdf" target="_blank">
      <span class="nav-icon mcd-down"></span>
      <span class="nav-text">Core Equipment Quick Reference Guide</span>
    </a>
  </li>
  <li>
    <a href="https://mcd-gddg.com/development/foreignexchange/home" target="_blank">
      <span class="nav-icon mcd-up"></span>
      <span class="nav-text">Global Development Foreign Exchange</span>
    </a>
  </li>-->

        <!-- <li>-->
      </ul>
    </mat-card-content>
  </mat-card>
</div>
