<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title"
      >{{ grs.standard.id > 0 ? "Edit" : "Add New" }} Standard</mat-card-title
    >
  </mat-card-header>
  <div class="mat-card-wrapper">
    <mat-card-content class="mcd-card-content">
      <form [formGroup]="addGrsForm">
        <div class="mcd-edit-form mcd-grbes-form">
          <div class="row">
            <div class="col-5">
              <mat-form-field>
                <mat-label>Standard Type</mat-label>
                <mat-select
                  name="dropLocation"
                  [disabled]="grs.standard.id > 0"
                  [value]="addGrsForm['controls'].standardTypeId.value"
                  (selectionChange)="
                    addGrsForm['controls'].standardTypeId.setValue(
                      $event.value
                    );
                    onChangeStandardType($event.value)
                  "
                  required
                >
                  <mat-option
                    [value]="item.id"
                    *ngFor="let item of standardTypes"
                  >
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-5 offset-md-2">
              <mat-form-field
                *ngIf="
                  !globalstandards &&
                  addGrsForm['controls'].standardTypeId.value
                "
              >
                <mat-label
                  >Search for a Dependant Minimum / Operations Standard with
                  Title or Description</mat-label
                >
                <input
                  matInput
                  placeholder="Search for a Dependant Minimum / Operations Standard with Title or Description"
                  aria-label="Search for a Dependant Minimum / Operations Standard with Name"
                  [formControl]="minimumStandadControl"
                  required
                  (input)="onSearchInputChange($event.target.value)"
                  style="margin-bottom: 3px"
                />
                <mat-select
                  multiple
                  *ngIf="minimumStandads && minimumStandads.length"
                  [formControl]="this.addGrsForm.controls.minimumStandadId"
                  placeholder="Please select dependent standards from the list"
                >
                  <mat-label></mat-label>
                  <mat-option
                    *ngFor="
                      let minimumStandad of getMinimumStandardsForSearchAfterLoading(
                        minimumStandads
                      )
                    "
                    [value]="{
                      id: minimumStandad.id,

                      title: minimumStandad.title,

                      description: minimumStandad.description
                    }"
                    required
                  >
                    <span class="mcd-title"
                      >{{ minimumStandad.title
                      }}{{
                        minimumStandad.isUpcoming ? " (Upcoming Standard)" : ""
                      }}</span
                    >
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    minimumStandadControl.touched &&
                    minimumStandadControl.hasError('required')
                  "
                >
                  Minimum standard is required
                </mat-error>
              </mat-form-field>
              <p
                *ngIf="
                  !globalstandards &&
                  addGrsForm['controls'].standardTypeId.value &&
                  minimumStandads.length > 0
                "
                style="margin-top: -22.5px; font-size: smaller; color: #bbbbbb"
              >
                Select from
                {{
                  getMinimumStandardsForSearchAfterLoading(minimumStandads)
                    .length
                }}
                options
              </p>

              <app-inline-loader
                *ngIf="minimumStandadsLoading"
                class="inline-loader"
              ></app-inline-loader>
              <div *ngIf="globalstandards || !grs.standard.standardTypeId">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="Title"
                    name="Title"
                    formControlName="title"
                    required
                  />
                  <mat-error
                    *ngIf="
                      addGrsForm.get('title').touched &&
                      addGrsForm.get('title').hasError('required')
                    "
                  >
                    Title is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div
              class="col-12"
              *ngIf="!globalstandards && grs.standard.standardTypeId"
            >
              <mat-form-field>
                <input
                  matInput
                  placeholder="Title"
                  name="Title"
                  formControlName="title"
                  required
                />
                <mat-error
                  *ngIf="
                    addGrsForm.get('title').touched &&
                    addGrsForm.get('title').hasError('required')
                  "
                >
                  Title is required
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field
                *ngIf="
                  addGrsForm['controls'].standardTypeId.value ==
                  standardTypeEnum.GlobalMinimumRestaurantStandard
                "
              >
                <mat-label>Category</mat-label>
                <mat-select
                  name="dropCategory"
                  [disabled]="grs.standard.id > 0"
                  [value]="addGrsForm['controls'].categoryId.value"
                  #category
                  (selectionChange)="setCategory(category)"
                  required
                >
                  <mat-option
                    [value]="item.id"
                    *ngFor="let item of buildingCategories"
                  >
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                *ngIf="
                  addGrsForm['controls'].standardTypeId.value ==
                  standardTypeEnum.OperationsStandards
                "
              >
                <mat-label>Category</mat-label>
                <mat-select
                  name="dropCategory"
                  [disabled]="grs.standard.id > 0"
                  [value]="addGrsForm['controls'].categoryId.value"
                  #category
                  (selectionChange)="setCategory(category)"
                  required
                >
                  <mat-option
                    [value]="item.id"
                    *ngFor="let item of operationsCategories"
                  >
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <br />

            <div class="col-5">
              <mat-form-field *ngIf="globalstandards">
                <mat-label>Location</mat-label>
                <mat-select
                  name="dropLocation"
                  [disabled]="grs.standard.id > 0"
                  [value]="addGrsForm['controls'].locationTypeId.value"
                  #location
                  (selectionChange)="setLocationCategoryMapping(location)"
                  required
                  multiple
                >
                  <mat-option
                    [value]="item.id"
                    *ngFor="let item of locationTypes"
                  >
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-5 offset-md-2" *ngIf="showSublocationField()">
              <mat-form-field>
                <mat-label>Sub Location</mat-label>
                <mat-select
                  name="dropSubLocation"
                  [disabled]="grs.standard.id > 0"
                  [value]="addGrsForm['controls'].subLocationTypeId.value"
                  #location
                  (selectionChange)="setSubLocation(location)"
                  required
                >
                  <mat-option
                    [value]="item.id"
                    *ngFor="let item of subLocationTypes"
                  >
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Description for Existing Restaurants"
                  name="description"
                  formControlName="description"
                  required
                />
                <mat-error
                  *ngIf="
                    addGrsForm.get('description').touched &&
                    addGrsForm.get('description').hasError('required')
                  "
                >
                  Description is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-5">
              <mat-form-field>
                <input
                  matInput
                  [matDatepicker]="picker2"
                  placeholder="New Restaurant Effective Date"
                  formControlName="newRestaurantEffectiveFrom"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Description for New Restaurants"
                  name="description"
                  formControlName="newrestaurantdescription"
                  required
                />
                <mat-error
                  *ngIf="
                    addGrsForm.get('newrestaurantdescription').touched &&
                    addGrsForm
                      .get('newrestaurantdescription')
                      .hasError('required')
                  "
                >
                  Description is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-5">
              <mat-form-field>
                <input
                  matInput
                  [matDatepicker]="picker"
                  placeholder="Existing Restaurant Effective Date"
                  formControlName="effectiveFrom"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-label>Notes *</mat-label>
              <quill-editor
                name="note"
                formControlName="note"
                class="quill-editor-css"
              >
              </quill-editor>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div class="col-12">
              <br />
              <br />
              <br />
              <br />
              <mat-label>Additional Information *</mat-label>
              <quill-editor
                name="additionalInformation"
                formControlName="additionalInformation"
                class="quill-editor-css"
              >
              </quill-editor>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <div
            class="mcd-upload-images"
            *ngIf="
              addGrsForm['controls'].standardTypeId.value ==
              standardTypeEnum.OperationsStandards
            "
          >
            <br />
            <br />
            <br />
            <br />
            <div class="row attachements">
              <div class="col">
                <a href="javascript:void(0);" (click)="operationsImage.click()">
                  <span>
                    <img
                      alt="Upload File"
                      src="/assets/images/attachement.svg"
                    />
                  </span>
                  Upload Image (JPEG, PNG, BMP)
                </a>
                <mat-label *ngIf="grs.standard.imageAttachment?.fileName"
                  >Image File</mat-label
                >
                <div class="uploaded-file">
                  <span class="file-name">
                    <a
                      href="javascript:void(0);"
                      (click)="
                        onDownloadSelectedImage(
                          grs.standard.imageAttachment?.id
                        )
                      "
                      >{{ grs.standard.imageAttachment?.fileName }}</a
                    >
                  </span>
                  <span *ngIf="grs.standard.imageAttachment?.fileName">
                    <a href="javascript:void(0);" (click)="onRemoveImage()">
                      <i class="material-icons delete-icon"> cancel </i>
                    </a>
                  </span>
                </div>
                <input
                  hidden
                  type="file"
                  (change)="onSelectImage($event)"
                  #operationsImage
                />
                <mat-error class="mat-error-left-label" *ngIf="invalidImage">
                  Invalid file type.
                </mat-error>
                <mat-error class="mat-error-left-label" *ngIf="invalidImageRes">
                  Invalid image resolution.
                </mat-error>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
    <mat-card-footer class="mcd-card-footer">
      <button
        class="mcd-btn mcd-btn-primary"
        *ngIf="!grs.standard.id"
        mat-raised-button
        (click)="reset()"
      >
        Reset
      </button>
      <button
        class="mcd-btn mcd-btn-primary"
        *ngIf="grs.standard.id > 0"
        mat-raised-button
        (click)="onBackClickorEditRedirect()"
      >
        Back
      </button>
      <button
        class="mcd-btn mcd-btn-secondary"
        mat-raised-button
        [disabled]="addGrsForm.invalid"
        (click)="saveGrbes()"
      >
        {{ grs.standard.id > 0 ? "Update" : "Add" }}
      </button>
    </mat-card-footer>
  </div>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
