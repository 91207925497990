import { Standard } from "./standard";
import { Country } from "./country";

export class NrbesItem {
  id: number;
  countryId: Array<number>;
  standardId: number;
  name: string;
  country: Array<Country>;
  standard: Standard;
  isDeleted: boolean;
  availabileAddendumId?: number;
  addendumStatusId?: number;

  constructor(obj?: NrbesItem) {
    if (obj) {
      this.id = obj.id;
      this.countryId = obj.countryId;
      this.standardId = obj.standardId;
      this.name = obj.name;
      this.country = obj.country;
      this.standard = obj.standard;
      this.isDeleted = obj.isDeleted;
      this.availabileAddendumId = obj.availabileAddendumId;
      this.addendumStatusId = obj.addendumStatusId;
    }
  }
}
