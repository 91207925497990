<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title" *ngIf="!isEditMode">
      Add Global Equipment Standard
    </mat-card-title>
    <mat-card-title class="mcd-card-title" *ngIf="isEditMode">
      Update Global Equipment Standard
    </mat-card-title>
  </mat-card-header>
  <div class="mat-card-wrapper">
    <mat-card-content class="mcd-card-content">
      <form [formGroup]="addEquipmentStandardForm">
        <div class="mcd-edit-form mcd-grbes-form">
          <div class="row">
            <div class="col-12">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Model"
                  name="Model"
                  required
                  formControlName="model"
                  [readonly]="equipmentStandard.standardId > 0"
                />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <mat-form-field>
                <mat-label>Location</mat-label>
                <mat-select
                  name="dropLocation"
                  [disabled]="equipmentStandard.standardId > 0"
                  [value]="
                    addEquipmentStandardForm['controls'].locationTypeId.value
                  "
                  #location
                  (selectionChange)="setLocationEquipmentTypeMapping(location)"
                  required
                  multiple
                >
                  <mat-option
                    [value]="item.id"
                    *ngFor="let item of locationTypes"
                  >
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12" *ngIf="showSublocationField()">
              <mat-form-field>
                <mat-label>Sub Location</mat-label>
                <mat-select
                  name="dropLocation"
                  [disabled]="equipmentStandard.standardId > 0"
                  [value]="
                    addEquipmentStandardForm['controls'].subLocationTypeId.value
                  "
                  #subLocation
                  (selectionChange)="setSubLocation(subLocation)"
                  required
                >
                  <mat-option
                    [value]="item.id"
                    *ngFor="let item of subLocationTypes"
                  >
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field>
                <mat-label>Equipment Class</mat-label>
                <mat-select
                  name="dropLocation"
                  [value]="
                    addEquipmentStandardForm['controls'].classTypeId.value
                  "
                  #classType
                  (selectionChange)="setClassType(classType)"
                  required
                  [disabled]="equipmentStandard.standardId > 0"
                >
                  <mat-option
                    [value]="item.id"
                    *ngFor="let item of equipmentClassTypes"
                  >
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field
                *ngIf="
                  addEquipmentStandardForm['controls'].classTypeId.value == 2
                "
              >
                <mat-label>Country</mat-label>
                <mat-select
                  name="dropLocation"
                  [value]="addEquipmentStandardForm['controls'].countryId.value"
                  #country
                  (selectionChange)="
                    addEquipmentStandardForm['controls'].countryId.setValue(
                      $event.value
                    );
                    onSelectCountry($event.value)
                  "
                  required
                >
                  <mat-option
                    [value]="item.id"
                    *ngFor="let item of countries"
                    [disabled]="equipmentStandard.standardId > 0"
                  >
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div
              class="col-12"
              *ngIf="
                addEquipmentStandardForm['controls'].classTypeId.value == 1
              "
            >
              <mat-form-field>
                <input
                  matInput
                  [matDatepicker]="picker2"
                  placeholder="Effective From"
                  formControlName="effectiveFrom"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </div>
            <div
              class="col-12"
              *ngIf="
                addEquipmentStandardForm['controls'].classTypeId.value == 1
              "
            >
              <mat-form-field>
                <input
                  matInput
                  [matDatepicker]="picker1"
                  placeholder="Obsolete Date"
                  formControlName="obsoleteDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker1"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
              <!--<mat-error class="mdate-range-error" *ngIf="checkEffectiveAndObsoleteDateValidity()">
          Obsolete date should be greater than effective date.
        </mat-error>-->
            </div>

            <div class="col-12">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Title"
                  name="title"
                  required
                  formControlName="title"
                />
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Description"
                  name="description"
                  required
                  formControlName="description"
                />
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field>
                <mat-label>Equipment Type</mat-label>
                <mat-select
                  name="dropLocation"
                  [value]="
                    addEquipmentStandardForm['controls'].equipmentTypeId.value
                  "
                  #equipmentType
                  (selectionChange)="
                    addEquipmentStandardForm[
                      'controls'
                    ].equipmentTypeId.setValue($event.value)
                  "
                  required
                  [disabled]="equipmentStandard.standardId > 0"
                >
                  <mat-option
                    [value]="item.id"
                    *ngFor="let item of equipmentTypes"
                  >
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field>
                <mat-label>Supplier</mat-label>
                <mat-select
                  name="dropLocation"
                  [value]="
                    addEquipmentStandardForm['controls'].supplierId.value
                  "
                  #supplier
                  (selectionChange)="
                    addEquipmentStandardForm['controls'].supplierId.setValue(
                      $event.value
                    )
                  "
                  required
                  [disabled]="equipmentStandard.standardId > 0"
                >
                  <mat-option [value]="item.id" *ngFor="let item of suppliers">
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-label>Notes *</mat-label>
              <quill-editor
                name="note"
                formControlName="note"
                class="quill-editor-css"
              >
              </quill-editor>
            </div>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
        <br />
        <br />

        <div class="mcd-upload-images">
          <div class="row attachements">
            <div class="col">
              <a href="javascript:void(0);" (click)="equipmentImage.click()">
                <span>
                  <img
                    alt="Upload Image"
                    src="/assets/images/attachement.svg"
                  />
                </span>
                Upload Image (JPEG, PNG, BMP)
              </a>
              <mat-label
                *ngIf="
                  equipmentStandard.imageAttachment?.fileName && isEditMode
                "
                >Image File</mat-label
              >
              <div class="uploaded-file">
                <span class="file-name">
                  <a
                    href="javascript:void(0);"
                    (click)="
                      onDownloadSelectedImage(
                        equipmentStandard?.imageAttachment?.id
                      )
                    "
                    >{{ equipmentStandard.imageAttachment?.fileName }}</a
                  >
                </span>
                <span *ngIf="equipmentStandard.imageAttachment?.fileName">
                  <a href="javascript:void(0);" (click)="onRemoveImage()">
                    <i class="material-icons delete-icon"> cancel </i>
                  </a>
                </span>
              </div>
              <input
                hidden
                type="file"
                (change)="onSelectImage($event)"
                #equipmentImage
              />
              <mat-error class="mat-error-left-label" *ngIf="invalidImage">
                Invalid file type.
              </mat-error>
              <mat-error class="mat-error-left-label" *ngIf="invalidImageRes">
                Invalid image resolution.
              </mat-error>
            </div>

            <div class="col text-right">
              <a href="javascript:void(0);" (click)="equipmentDoc.click()">
                <span>
                  <img
                    alt="Upload Image"
                    src="/assets/images/attachement.svg"
                  />
                </span>
                Attach File (doc, docx, pdf)
              </a>
              <mat-label
                *ngIf="
                  equipmentStandard.equipmentAttachment?.fileName && isEditMode
                "
                >Attachment</mat-label
              >
              <div class="uploaded-file qr-code">
                <span class="file-name">
                  <a
                    href="javascript:void(0);"
                    (click)="
                      onDownloadSelectedAttachment(
                        equipmentStandard.equipmentAttachment
                      )
                    "
                    >{{ equipmentStandard.equipmentAttachment?.fileName }}</a
                  >
                </span>
                <span *ngIf="equipmentStandard.equipmentAttachment?.fileName">
                  <a href="javascript:void(0);" (click)="onRemoveAttachment()">
                    <i class="material-icons delete-icon"> cancel </i>
                  </a>
                </span>
              </div>
              <input
                hidden
                type="file"
                (change)="onSelectAttachment($event)"
                #equipmentDoc
              />
              <mat-error
                class="mat-error-right-label"
                *ngIf="invalidAttachment"
              >
                Invalid file type.
              </mat-error>
            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
    <mat-card-footer class="mcd-card-footer">
      <button
        class="mcd-btn mcd-btn-primary"
        *ngIf="!equipmentStandard.id"
        mat-raised-button
        (click)="resetForm()"
      >
        Reset
      </button>
      <button
        class="mcd-btn mcd-btn-primary"
        *ngIf="equipmentStandard.id > 0"
        mat-raised-button
        (click)="onBackClickorEditRedirect()"
      >
        Back
      </button>
      <button
        class="mcd-btn mcd-btn-secondary"
        mat-raised-button
        [disabled]="checkFormValidity()"
        (click)="save()"
        *ngIf="!isEditMode"
      >
        Add
      </button>
      <button
        class="mcd-btn mcd-btn-secondary"
        mat-raised-button
        [disabled]="checkFormValidity()"
        (click)="save()"
        *ngIf="isEditMode"
      >
        Update
      </button>
    </mat-card-footer>
  </div>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
