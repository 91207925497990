import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import {
  OAuthService,
  JwksValidationHandler,
  OAuthErrorEvent,
} from "angular-oauth2-oidc";
import { Router, NavigationEnd } from "@angular/router";
import * as jwt_decode from "jwt-decode";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { AuthConfiguration } from "./core/auth.configuration";
import { environment } from "../environments/environment";
import { slideInAnimation } from "./route-animation";
import { AuthProdConfiguration } from "./core/auth.prod.configuration";
import Quill from "quill";
import { NotificationService } from "./services/notification.service";
import { A } from "@angular/cdk/keycodes";
import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { NRInitializationService } from "./services/newrelic-initialization.service";
import { NRAuthInitializationService } from "./services/auth-init.service";

const parchment = Quill.import("parchment");
const block = parchment.query("block");
block.tagName = "DIV";
// or class NewBlock extends Block {} NewBlock.tagName = 'DIV'
Quill.register(block /* or NewBlock */, true);
declare var newrelic: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [slideInAnimation],
})
export class AppComponent implements OnInit {
  get token() {
    return this.oauthService.getAccessToken();
  }
  get claims() {
    return this.oauthService.getIdentityClaims();
  }

  title = "McD.GRBES.Web";
  http: HttpClient;
  activatedRoute: ActivatedRoute;

  constructor(
    private oauthService: OAuthService,
    private _router: Router,
    public notificationService: NotificationService,
    private location: Location,
    private nrInit: NRInitializationService,
    private nrAuthInit: NRAuthInitializationService
  ) {
    if (window.location.href.includes("access_token")) {
      this.nrAuthInit.setTokenReceieved();
    }
    if (!this.nrInit.isInitialized()) {
      this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (typeof newrelic !== "undefined" && newrelic.setCurrentRouteName) {
            newrelic.addPageAction("PageView", {
              path: event.urlAfterRedirects,
            });
          }
        }
      });
      this.nrInit.setInitialized();
    }
    //   // alert(oauthService.hasValidAccessToken());
    //   // if (!oauthService.hasValidAccessToken()) oauthService.logOut();
    //   // if(_router.url=="")
    //   oauthService.events.subscribe((e) =>
    //     e instanceof OAuthErrorEvent ? console.error(e) : console.warn(e)
    //   );
    //   oauthService.configure(AuthProdConfiguration);
    //   // Load information from Auth0 (could also be configured manually)
    //   // this.oauthService.logOut();
    //   // //;
    //   oauthService
    //     .loadDiscoveryDocument()
    //     // See if the hash fragment contains tokens (when user got redirected back)
    //     .then(() => oauthService.tryLogin())
    //     // If we're still not logged in yet, try with a silent refresh:
    //     .then(() => {
    //       // alert(success);
    //       // alert(oauthService.hasValidAccessToken());
    //       if (!oauthService.hasValidAccessToken())
    //         oauthService.initImplicitFlow();
    //       // alert(oauthS)
    //       console.log("ACCESS TOKEN", this.oauthService.getAccessToken());
    //     })
    //     // Get username, if possible.
    //     .then(() => {});
  }

  async sendAuthEvent(loginStatus, name, email) {
    await fetch(
      "https://insights-collector.newrelic.com/v1/accounts/4619405/events",
      {
        method: "POST",
        headers: {
          "Api-Key": "58be58f2497b3d9d4ed19a4bd40bd275FFFFNRAL",
        },
        body: JSON.stringify([
          {
            eventType: "GRSAuthenticationPROD",
            status: loginStatus,
            name,
            email,
            session: JSON.parse(localStorage.getItem("NRBA_SESSION")).value,
          },
        ]),
      }
    );
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  ngOnInit() {
    console.error = (a) => {};
    console.log(this.location.path());
    if (environment.skipAuthentication) {
      this._router.initialNavigation();
      this._router.navigateByUrl(this.location.path());
      return;
    }
    if (environment.useProdConfiguration) {
      this.oauthService.configure(AuthProdConfiguration);
    } else {
      // ;
      this.oauthService.configure(AuthConfiguration);
    }
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    // this.oauthService.initImplicitFlow();
    this.oauthService.tryLogin().then(async () => {
      console.log(this);
      if (!this.nrAuthInit.isInitialized()) {
        this.nrAuthInit.setInitialized();
        return;
      }
      if (!this.oauthService.hasValidAccessToken()) {
        /* Recursively wait until NRBA_SESSION in storage is set. */
        let totalDelay = 0;
        const waitUntilStorageSet = async () => {
          if (!localStorage.getItem("NRBA_SESSION")) {
            totalDelay += 50;
            await this.delay(50);
            await waitUntilStorageSet();
          }
          return;
        };
        await waitUntilStorageSet();
        debugger;
        console.log("TOTAL DELAY", totalDelay);
        await this.sendAuthEvent("LoginAttempt", "", "");
        this.oauthService.initImplicitFlow();
        return;
      } else {
        let email = this.getDecodedAccessToken(
          this.oauthService.getAccessToken()
        ).email;
        if (this.nrAuthInit.isTokenReceieved()) {
          await this.sendAuthEvent(
            "LoginSuccess",
            email,
            String(email).toString().split(".", 1)[0]
          );
        }
      }
      this._router.initialNavigation();
      this._router.navigateByUrl(this.location.path());
    });

    // this.oauthService.events.subscribe((e) =>
    //   e instanceof OAuthErrorEvent ? console.error(e) : console.warn(e)
    // );

    // this.oauthService.configure(AuthProdConfiguration);

    // // Load information from Auth0 (could also be configured manually)
    // // this.oauthService.logOut();
    // // //;
    // this.oauthService
    //   .loadDiscoveryDocument()

    //   // See if the hash fragment contains tokens (when user got redirected back)
    //   .then(() => this.oauthService.tryLogin())
    //   // If we're still not logged in yet, try with a silent refresh:
    //   .then(() => {
    //     if (!this.oauthService.hasValidAccessToken()) {
    //       // return this.oauthService.silentRefresh();
    //       alert(this.oauthService.hasValidAccessToken());
    //       this.oauthService.initImplicitFlow();
    //     }
    //   })

    //   // Get username, if possible.
    //   .then(() => {});

    // // this.oauthService.setupAutomaticSilentRefresh();
  }

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  // ngOnInit(): void {
  //   this._router.initialNavigation();
  //   this._router.navigateByUrl("/locations");
  // }
}
