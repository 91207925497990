import { Component, OnInit, Inject } from "@angular/core";
import { StandardService } from "../services/standard.service";
import {
  StandardTypeEnum,
  UserRoleEnum,
  StandardListType,
  AddendumStatusEnum,
} from "../core/constant";
import { Router, ActivatedRoute } from "@angular/router";
import { CryptographyService } from "../services/cryptography.service";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { SelectedStandard } from "../models/selected-standard";
import { NrbeStandard } from "../models/nrbestandard";
import { Attachment } from "../models/attachment";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NrbesItem } from "../models/nrbesItem";
import { AddendumService } from "../services/addendum.service";
import linkifyStr from "linkifyjs/string";
import { debug } from "util";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-view-all-national-equipments-standard-detail-view",
  templateUrl:
    "./view-all-national-equipments-standard-detail-view.component.html",
  styleUrls: [
    "./view-all-national-equipments-standard-detail-view.component.scss",
  ],
  providers: [DatePipe],
})
export class ViewAllNationalEquipmentsStandardDetailViewComponent
  implements OnInit
{
  grsId: number;


  loading: boolean = false;
  grsDetail: SelectedStandard = new SelectedStandard();
  userRole: number;
  error: string = "";
  nrbesId: number = 0;
  nrbesDetail: NrbeStandard = new NrbeStandard();
  get standardTypeEnum() {
    return StandardTypeEnum;
  }
  get userRoleEnum() {
    return UserRoleEnum;
  }

  constructor(
    private router: Router,
    private standardService: StandardService,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private activeRoute: ActivatedRoute,
    private ngbModal: NgbModal,
    private addendumService: AddendumService,
    private datePipe: DatePipe
  ) {
    this.grsId = parseInt(this.activeRoute.snapshot.paramMap.get("grsId"));
  }

  ngOnInit() {
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    this.getDetailByNrbesId(this.grsId);
  }

  /**
   * Get National Standard detail by standard id
   * @return void
   */
  getDetailByNrbesId(grsId: number) {
    this.loading = true;

    this.standardService
      .getNrbesDetailInfomationByNrbesId(
        grsId,
        [
          this.cryptographyService.decryptData(
            this.storage.get("selectedCountryId")
          ),
        ],
        this.standardService.nationalEquipmentStandardFiltration.filterSet,
        this.standardService.nationalEquipmentStandardFiltration.searchKey,
        true
      )
      .subscribe((data: any) => {
        //debugger;
        try {
          data.country = [data.country];
          data.countryId = [data.countryId];
          this.nrbesDetail = new NrbeStandard(data);
          console.log("My nrbes", this.nrbesDetail);
          this.loading = false;
        } catch (e) {
          console.log("ERROR IN NRBES", e);
        }
        this.nrbesDetail = new NrbeStandard(data);
        console.log("My nrbes", this.nrbesDetail);
        this.loading = false;
        (error: string) => {
          this.error = error;
          this.loading = false;
        };
      });
  }

  navigateAccToType(type, id) {
    if (type == 4) {
      this.router.navigate(["/minimum-standard-detail-view/" + id]);
      return;
    }
    if (type == 8) {
      this.router.navigate(["/operations-standards-detail-view/" + id]);
      return;
    }
    if (type == 0) {
      this.router.navigate(["/view-equipments-standard-detail/" + id]);
      return;
    }
    if (type == 1 || type == 2) {
      this.router.navigate(["/global-recommendations-detail-view/" + id]);
      return;
    }
  }

  slide(sID: number, isForward: boolean) {
    // this.loading = true;
    this.standardService
      .getNextPreviousNrbsInfomationByCurrentNrbesId(
        sID,
        isForward,
        [
          this.cryptographyService.decryptData(
            this.storage.get("selectedCountryId")
          ),
        ],
        this.standardService.nationalEquipmentStandardFiltration.filterSet,
        this.standardService.nationalEquipmentStandardFiltration.searchKey,
        true
      )
      .subscribe((data: NrbeStandard) => {
        this.router.navigate([
          "view-national-equipments-standard-detail",
          data.id,
        ]);
        this.nrbesDetail = new NrbeStandard(data);
        this.loading = false;
        (error: string) => {
          this.error = error;
          this.loading = false;
        };
      });
  }

  onDownloadSelectedEquipmentAttachment(e) {
    return this.standardService.getAttachmentUrl(e.id, false).subscribe(
      (response: any) => {
        const blob = new Blob([response], {
          type: response.type,
        });
        if (
          (window.navigator as any) &&
          (window.navigator as any).msSaveOrOpenBlob
        ) {
          (window.navigator as any).msSaveOrOpenBlob(blob, e.fileName);
          return;
        }
        var a = document.createElement("a");
        document.body.appendChild(a);

        let url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = e.fileName;
        a.click();
        window.URL.revokeObjectURL(url);

        //window.open(url);
      },
      (error: any) => {
        const modalRef = this.ngbModal.open(FailModuleComponent);
        modalRef.componentInstance.errorMessage = error.error
          ? error.error.Message
          : error.message;
      }
    );
  }

  addAddendum(standardId: number, event): void {
    event.stopPropagation();
    this.storage.set(
      "adCountryId",
      this.cryptographyService.encryptData(
        this.nrbesDetail.country.map((c) => c.id).length > 0
          ? this.nrbesDetail.country.map((c) => c.id)[0]
          : 0
      )
    );
    this.router.navigate(["/add-addendum/" + standardId]);
  }

  goToAddendum(nrbesItem: any): void {
    console.log("nrbesItem", nrbesItem);
    try {
      this.storage.set("adCountryId", nrbesItem.country);
      this.router.navigate(["/addendum/" + nrbesItem.availabileAddendumId]);
    } catch (e) {
      console.log("ERROR WHILE NAVIGATING TO ADDENDUM", e);
    }
  }

  getLocationNames(item): any {
    if (
      item.standard.standardLocation &&
      item.standard.standardLocation.length > 0
    ) {
      return item.standard.standardLocation
        .map((loc) => loc.locationType.name)
        .join(", ");
    } else {
      return item.standard.locationType.name;
    }
  }
}
