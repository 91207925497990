import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminService } from "../services/admin.service";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";
import { User } from "../models/user";
import { Country } from "../models/country";
import { UserRole } from "../models/user-role";

@Component({
  selector: "app-user-detail-view",
  templateUrl: "./user-detail-view.component.html",
  styleUrls: ["./user-detail-view.component.scss"],
})
export class UserDetailViewComponent implements OnInit {
  error: string = "";

  loading: boolean = false;
  user: User = new User();

  constructor(
    private activeRoute: ActivatedRoute,
    private adminService: AdminService,
    private ngbModal: NgbModal,
    private router: Router
  ) {
    this.user.id = parseInt(this.activeRoute.snapshot.paramMap.get("userId"));
  }

  ngOnInit() {
    this.getUserByUserId(this.user.id);
  }

  getCommaSeperatedCountries(countries) {
    return countries.map((c) => c.name).join(", ");
  }

  getUserByUserId(userId: number) {
    this.loading = true;
    this.adminService.GetUserDetailsById(userId).subscribe((data: any) => {
      data.country = data.countries.map((c) => c.country);
      data.countryId = data.countries.map((c) => c.countryId);
      this.user = new User(data);
      this.loading = false;
      (error: string) => {
        this.error = error;
        const modalRef = this.ngbModal.open(FailModuleComponent);
        this.error = error;
        modalRef.componentInstance.errorMessage = error;
        this.loading = false;
      };
    });
  }

  onBackClick() {
    this.router.navigate(["/view-all-user-list"]);
  }
}
