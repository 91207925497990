import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { Constant } from "../core/constant";
import { AdminService } from "../services/admin.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { MatMenuTrigger } from "@angular/material/menu";
import { Router } from "@angular/router";
import { UserList } from "../models/user-list";
import { User } from "../models/user";
import { DeleteDialogComponent } from "../delete-dialog/delete-dialog.component";
import { CryptographyService } from "../services/cryptography.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";

@Component({
  selector: "app-view-all-user-list",
  templateUrl: "./view-all-user-list.component.html",
  styleUrls: ["./view-all-user-list.component.scss"],
})
export class ViewAllUserListComponent implements OnInit {
  loading: boolean = false;
  pageSizes: any[] = Constant.PageSizes;
  searchKey: string;

  page: number = 1;
  pageSize: number = 10;
  collectionSize: number = 0;

  userList: UserList = new UserList();
  error: string = "";
  modalOption: NgbModalOptions = {};
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;

  constructor(
    private adminService: AdminService,
    private ngbModal: NgbModal,
    private router: Router,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {}

  ngOnInit() {
    this.getUsers();
  }

  selectedItemsPerPageChange(event) {
    this.page = 1;
    this.pageSize = event.value;
    this.getUsers();
  }

  getCommaSeperatedCountries(countries) {
    let x = countries.map((c) => c.name).join(", ");
    console.log(x);
    return x;
  }

  getUsers(): void {
    this.loading = true;
    this.adminService
      .GetUserList(
        (this.page - 1) * this.pageSize,
        this.pageSize,
        this.searchKey
      )
      .subscribe((data: any) => {
        this.userList.users = data.users.map((o) => {
          o.country = o.countries.map((c) => c.country);
          o.countryId = o.countries.map((c) => c.countryId);
          return new User(o);
        });
        this.collectionSize = data.count;
        this.loading = false;
        (error: string) => {
          this.error = error;
          const modalRef = this.ngbModal.open(FailModuleComponent);
          this.error = error;
          modalRef.componentInstance.errorMessage = error;
          this.loading = false;
        };
      });
  }

  addSearchKey(searchKey) {
    this.searchKey = searchKey.value;
    this.page = 1;
    this.getUsers();
  }

  onAddEditUser(userd: number = null) {
    if (!userd) {
      this.router.navigate(["/add-user"]);
    } else {
      this.router.navigate(["/add-user/" + userd]);
    }
  }

  rowSelected(user: User) {
    this.router.navigate(["/user-detail-view/" + user.id]);
  }

  closeMatMenuAndOpenDeleteModal(item, event) {
    this.trigger.closeMenu();
    setTimeout(() => {
      this.openDeleteModal(item, event);
    }, 100);
  }

  openDeleteModal(item, event) {
    event.stopPropagation();
    const modalRef = this.ngbModal.open(DeleteDialogComponent);
    modalRef.componentInstance.title =
      "Do you want to remove this user from the list? ";
    modalRef.componentInstance.deleteItem = item;
    modalRef.componentInstance.message =
      "All the dependencies will be removed along with this user";
    this.trigger.closeMenu();
    modalRef.componentInstance.deletePopupEmit.subscribe((deleteItem) => {
      if (deleteItem != null) {
        let user = new User(item);
        this.loading = true;
        user.modifiedUserId = this.cryptographyService.decryptData(
          this.storage.get("userId")
        );
        this.adminService.DeleteUser(user).subscribe((data: any) => {
          this.loading = false;
          this.page = 1;
          this.getUsers();
        });
      }
      this.ngbModal.dismissAll();
    });
  }
}
