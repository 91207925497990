import { Standard } from "./standard";
import { Country } from "./country";

export class NrbeStandard {
  id: number;
  countryId: Array<number>;
  standardId: number;
  country: Array<Country> = [];
  standard: Standard = new Standard();
  isFirst: boolean;
  isLast: boolean;
  isInNrbes: boolean;
  availabileAddendumId?: number;
  addendumStatusId?: number;

  constructor(obj?: NrbeStandard) {
    if (obj) {
      this.id = obj.id;
      this.countryId = obj.countryId;
      this.standardId = obj.standardId;
      this.country = obj.country ? obj.country.map((c) => new Country(c)) : [];
      this.standard = new Standard(obj.standard);
      this.isFirst = obj.isFirst;
      this.isLast = obj.isLast;
      this.isInNrbes = obj.isInNrbes;
      this.availabileAddendumId = obj.availabileAddendumId;
      this.addendumStatusId = obj.addendumStatusId;
    }
  }
}
