import { Component, OnInit, Inject } from "@angular/core";
import { Country } from "../models/country";
import { UserRoleEnum, Constant } from "../core/constant";
import { AdminService } from "../services/admin.service";
import { SupplierList } from "../models/supplier-list";
import { MarketSupplier } from "../models/market-supplier";
import { CommandResult } from "../models/command-result";
import { CryptographyService } from "../services/cryptography.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { GlobalMarketSupplier } from "../models/global-market-supplier";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { WarningDialogComponent } from "../warning-dialog/warning-dialog.component";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";

@Component({
  selector: "app-manage-suppliers",
  templateUrl: "./manage-suppliers.component.html",
  styleUrls: ["./manage-suppliers.component.scss"],
})
export class ManageSuppliersComponent implements OnInit {
  loading: boolean = false;
  userRole: number;
  userId: number;
  countryId: Array<number> = null;
  countries: Country[] = [];
  pageSizes: any[] = Constant.PageSizes;
  searchKey: string;

  page: number = 1;
  pageSize: number = 5;
  collectionSize: number = 0;

  supplierList: SupplierList = new SupplierList();
  error: string = "";
  modalOption: NgbModalOptions = {};

  get userRoleEnum() {
    return UserRoleEnum;
  }

  constructor(
    private adminService: AdminService,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private ngbModal: NgbModal
  ) {}

  ngOnInit() {
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    this.userId = this.cryptographyService.decryptData(
      this.storage.get("userId")
    );
    if (
      this.userRole == this.userRoleEnum.GlobalAdmin ||
      this.userRole == this.userRoleEnum.AOWAdmin
    ) {
      this.getMasterData();
    } else {
      this.countryId = this.cryptographyService.decryptData(
        this.storage.get("countryId")
      );
      this.getSuppliers();
    }
  }

  selectedItemsPerPageChange(event) {
    this.page = 1;
    this.pageSize = event.value;
    this.getSuppliers();
  }

  getSuppliers(event?): void {
    this.countryId = event ? [event.value] : this.countryId;
    this.loading = true;
    this.adminService
      .GetGobalSupplierListWithSelectedMarketStatus(
        (this.page - 1) * this.pageSize,
        this.pageSize,
        this.countryId,
        this.searchKey
      )
      .subscribe((data: SupplierList) => {
        this.supplierList.globalMarketSuppliers =
          data.globalMarketSuppliers.map((o) => new GlobalMarketSupplier(o));
        this.collectionSize = data.count;
        this.loading = false;
        (error: string) => {
          this.error = error;
          const modalRef = this.ngbModal.open(FailModuleComponent);
          this.error = error;
          modalRef.componentInstance.errorMessage = error;
          this.loading = false;
        };
      });
  }

  getMasterData() {
    this.loading = true;
    this.adminService
      .getMasterDataForManageSuppliers(
        this.userRole == this.userRoleEnum.AOWAdmin ? this.userId : null
      )
      .subscribe((data: Country[]) => {
        this.countries = data.map((obj) => new Country(obj));
        this.loading = false;
        (error: string) => {
          this.error = error;
          const modalRef = this.ngbModal.open(FailModuleComponent);
          this.error = error;
          modalRef.componentInstance.errorMessage = error;
          this.loading = false;
        };
      });
  }

  onUpdateMarketSupplier(item: GlobalMarketSupplier, isAdded: boolean) {
    //debugger;
    const marketSupplier = new MarketSupplier();
    marketSupplier.supplierId = item.supplier.id;
    marketSupplier.isAdded = isAdded;
    marketSupplier.countryId = this.countryId;
    if (item.hasStandard && isAdded == false) {
      const modalRef = this.ngbModal.open(
        WarningDialogComponent,
        this.modalOption
      );
      modalRef.componentInstance.title = "This supplier mapped with standards!";
      modalRef.componentInstance.message = "Do you want to remove this?";
      modalRef.componentInstance.warningItem = marketSupplier;
      modalRef.componentInstance.warningPopupEmit.subscribe((deleteItemId) => {
        if (deleteItemId != null) {
          this.updateMarketSupplier(marketSupplier);
        }
        this.ngbModal.dismissAll();
      });
    } else {
      this.updateMarketSupplier(marketSupplier);
    }
  }

  updateMarketSupplier(marketSupplier: MarketSupplier) {
    this.loading = true;
    this.adminService
      .UpdateMarketSupplierList(marketSupplier)
      .subscribe((data: CommandResult) => {
        this.loading = false;
        const modalRef = this.ngbModal.open(SuccessDialogComponent);
        modalRef.componentInstance.message =
          "You have successfully updated the system.";
        modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
          this.ngbModal.dismissAll();
          this.supplierList.globalMarketSuppliers.find(
            (x) => x.supplier.id == marketSupplier.supplierId
          ).isAdded = marketSupplier.isAdded;
        });
        (error: string) => {
          const modalRef = this.ngbModal.open(FailModuleComponent);
          this.error = data.message;
          modalRef.componentInstance.errorMessage = data.message;
          this.loading = false;
        };
      });
  }

  addSearchKey(searchKey) {
    this.searchKey = searchKey.value;
    this.page = 1;
    this.getSuppliers();
  }
}
