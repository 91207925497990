import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { StandardService } from "../services/standard.service";
import { Standard } from "../models/standard";
import { Router, ActivatedRoute } from "@angular/router";
import {
  Constant,
  StandardTypeEnum,
  StandardListType,
  UserRoleEnum,
  FilteringEnum,
} from "../core/constant";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DeleteDialogComponent } from "../delete-dialog/delete-dialog.component";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { FilteringGuide } from "../models/filteringGuide";
import { CryptographyService } from "../services/cryptography.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { StandardList } from "../models/standard-list";
import { SelectedStandard } from "../models/selected-standard";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { parse } from "path";
import { Sort } from "@angular/material/sort";
declare var $: any;

import { DatePipe } from "@angular/common";

@Component({
  selector: "app-view-all-operations-standards-list",
  templateUrl: "./view-all-operations-standards-list.component.html",
  styleUrls: ["./view-all-operations-standards-list.component.scss"],
  providers: [DatePipe],
})
export class ViewOperationsStandardListComponent implements OnInit {
  loading: boolean = false;
  selected = "option2";
  grses: Standard[] = [];
  error: string = "";
  pageSizes: any[] = Constant.PageSizes;
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;

  page: number = 1;
  pageSize: number = 10;
  collectionSize: number = 0;
  userRole: number;

  filterList: FilteringGuide[] = [];

  isPageEnabled: boolean = true;
  isFromLocation: boolean = false;
  searchKey: string = "";
  isDownloading: any = false;
  get userRoleEnum() {
    return UserRoleEnum;
  }
  constructor(
    private standardService: StandardService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngbModal: NgbModal,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private cryptographyService: CryptographyService
  ) {
    //debugger;
    standardService.locationId =
      standardService.locationId == 0
        ? parseInt(this.activatedRoute.snapshot.paramMap.get("locationId"))
        : standardService.locationId;
  }

  ngOnInit() {
    this.filterList =
      this.standardService.operationsStandardFiltration.filterSet;
    if (!Number.isNaN(this.standardService.locationId)) {
      //debugger;
      this.setDefaultFiltration();
    }
    this.searchKey =
      this.standardService.operationsStandardFiltration.searchKey;
    this.pageSize = this.standardService.operationsStandardFiltration.pageSize;
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    this.getStandards();
  }

  selectedItemsPerPageChange(event) {
    this.page = 1;
    this.pageSize = event.value;
    this.standardService.operationsStandardFiltration.pageSize = this.pageSize;
    this.getStandards();
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction === "" || !(this.grses.length > 0)) {
      return;
    }
    console.log("GRSES-dort", this.grses);

    const isAsc = sort.direction === "asc";
    this.grses.sort((a, b) => {
      switch (sort.active) {
        case "title":
          return this.compare(a.title, b.title, isAsc);
        case "category":
          return this.compare(a.category.name, b.category.name, isAsc);
        case "desc":
          return this.compare(a.shortDescription, b.shortDescription, isAsc);
      }
    });
  }
  getStandards(): void {
    this.loading = true;
    this.standardService
      .getAllStandards(
        (this.page - 1) * this.pageSize,
        this.pageSize,
        StandardListType.OperationsStandard,
        this.filterList,
        null,
        this.searchKey
      )
      .subscribe((data: StandardList) => {
        this.grses = data.standardDetailList.map((o) => new Standard(o));
        this.collectionSize = data.count;
        this.loading = false;
        (error: string) => {
          this.error = error;
          this.loading = false;
        };
      });
  }

  setDefaultFiltration() {
    this.standardService.operationsStandardFiltration.filterSet = null;
    let locationFilteringGuide = new FilteringGuide();
    let filteringGuides = Array<FilteringGuide>();
    locationFilteringGuide.type = FilteringEnum.FilterByLocationType;
    locationFilteringGuide.ids.push(this.standardService.locationId);
    filteringGuides.push(locationFilteringGuide);
    this.filterList = filteringGuides;
    this.standardService.operationsStandardFiltration.filterSet =
      filteringGuides;
  }
  rowSelected(item: Standard): void {
    this.standardService.fromLocationGRS = false;

    if (item.standardTypeId == StandardTypeEnum.GlobalEquipmentStandard) {
      this.router.navigate(["/equipment-detail-view/" + item.id]);
    } else {
      this.router.navigate(["/operations-standards-detail-view/" + item.id]);
    }
  }

  openDeleteModal(item, event) {
    event.stopPropagation();
    const modalRef = this.ngbModal.open(DeleteDialogComponent);
    modalRef.componentInstance.title =
      "Do you want to remove this standard from the list? ";
    if (item.standardTypeId == StandardTypeEnum.OperationsStandards) {
      modalRef.componentInstance.message =
        "All the dependencies will be removed along with this standard";
    }
    modalRef.componentInstance.deleteItem = item.id;
    this.trigger.closeMenu();
    modalRef.componentInstance.deletePopupEmit.subscribe((deleteItemId) => {
      if (deleteItemId != null) {
        let grs = new SelectedStandard();
        grs.standard.id = deleteItemId;
        grs.standard.isDeleted = true;
        this.loading = true;
        this.standardService
          .removeGrbes(grs.standard)
          .subscribe((data: any) => {
            if (!data.status) {
              const modalRefd = this.ngbModal.open(FailModuleComponent);
              modalRefd.componentInstance.title =
                "You can't delete this standard!";
              modalRefd.componentInstance.errorMessage = data.message;
            }
            this.loading = false;
            this.page = 1;
            this.getStandards();
          });
      }
      this.ngbModal.dismissAll();
    });
  }

  actionMenuOpen(event) {
    event.stopPropagation();
  }

  receivedFilteration($event) {
    this.isPageEnabled = false;
    this.filterList = $event;
    this.standardService.operationsStandardFiltration.filterSet =
      this.filterList;
    this.pageSize = 10;
    this.page = 1;
    this.getStandards();
  }

  closeMatMenuAndOpenDeleteModal(item, event) {
    this.trigger.closeMenu();
    setTimeout(() => {
      this.openDeleteModal(item, event);
    }, 100);
  }

  addSearchKey(searchKey) {
    this.searchKey = searchKey.value;
    this.page = 1;
    this.standardService.operationsStandardFiltration.searchKey =
      this.searchKey;
    this.getStandards();
  }

  onEdit(id) {
    this.standardService.standardEditPreviousRoute =
      this.activatedRoute.snapshot.routeConfig.path;
    this.router.navigate(["/add-grs", id]);
  }

  downloadExcel() {
    this.isDownloading = true;
    this.standardService
      .downloadExcel(
        0,
        this.collectionSize,
        StandardListType.OperationsStandard,
        this.filterList,
        null,
        this.searchKey
      )
      .subscribe(
        (response: any) => {
          this.isDownloading = false;
          let filename =
            "Operations_Standard_List " + new Date().toLocaleString();
          const blob = new Blob([response], {
            type: response.type,
          });
          if (
            (window.navigator as any) &&
            (window.navigator as any).msSaveOrOpenBlob
          ) {
            (window.navigator as any).msSaveOrOpenBlob(blob, filename);
            return;
          }
          var a = document.createElement("a");
          document.body.appendChild(a);

          let url = window.URL.createObjectURL(blob);

          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);

          //window.open(url);
        },
        (error: any) => {
          const modalRef = this.ngbModal.open(FailModuleComponent);
          modalRef.componentInstance.errorMessage = error.error
            ? error.error.Message
            : error.message;
        }
      );
  }
  getLocationNames(item): any {
    if (item.standardLocation && item.standardLocation.length > 0) {
      return item.standardLocation
        .map((loc) => loc.locationType.name)
        .join(", ");
    } else {
      return item.locationType.name;
    }
  }
}
