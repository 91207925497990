<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title"
      >Upcoming Standard List</mat-card-title
    >
  </mat-card-header>
  <mat-card-content class="mcd-card-content">
    <div class="row filter-panel-row">
      <div class="col filter-panel">
        <app-filter-tags
          [isNrbes]="false"
          (messageEvent)="receivedFilteration($event)"
        ></app-filter-tags>
      </div>
      <div class="collpsible-panel">
        <button class="btn-collapse" mat-icon-button aria-label="Collapse">
          <i class="material-icons"> expand_less </i>
        </button>
        <button class="btn-expand" mat-icon-button aria-label="Expand">
          <mat-icon>expand_more</mat-icon>
        </button>
      </div>
    </div>

    <div class="table-wrapper" *ngIf="grses.length > 0">
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-select
              [value]="pageSize"
              name="nOfRows"
              #items
              (selectionChange)="selectedItemsPerPageChange(items)"
            >
              <mat-option [value]="item.value" *ngFor="let item of pageSizes">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-6 float-right">
          <a
            class="float-right"
            href="javascript:void(0);"
            (click)="downloadExcel()"
            style="color: #0b59ca; text-decoration: none; cursor: pointer"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="margin-bottom: 2px; margin-right: 2px"
            >
              <path
                d="M8.5 5V1H5.5V5H3.5L7 9L10.5 5H8.5Z"
                stroke="#3C74E0"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.5 9V11.5C12.5 11.8978 12.342 12.2794 12.0607 12.5607C11.7794 12.842 11.3978 13 11 13H3C2.60218 13 2.22064 12.842 1.93934 12.5607C1.65804 12.2794 1.5 11.8978 1.5 11.5V9"
                stroke="#3C74E0"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Download All
          </a>
        </div>
      </div>
      <table
        class="table nrbes-grid"
        matSort
        (matSortChange)="sortData($event)"
      >
        <thead class="thead-light">
          <tr>
            <th mat-sort-header="title" width="140" scope="col">Title</th>
            <th scope="col" width="110">Location</th>
            <th mat-sort-header="desc" scope="col-4" width="30%">
              Description
            </th>
            <th mat-sort-header="category" scope="col" width="170">Category</th>
            <th scope="col" width="170">Type</th>
            <th scope="col" width="160">Existing Restaurant Effective From</th>
            <th scope="col" width="170">New Restaurant Effective From</th>

            <th scope="col-2" width="140" *ngIf="userRole == 1">Exception</th>
            <th scope="col-2" width="140" *ngIf="userRole == 1">
              Exception Applied on Countries
            </th>
            <th scope="col" width="80" *ngIf="userRole == 1">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of grses" (click)="rowSelected(item)">
            <td class="title-cell">{{ item.title }}</td>
            <td class="location-cell">{{ getLocationNames(item) }}</td>
            <td class="description">
              <span title="{{ item.shortDescription }}" class="description">
                {{ item.description }}
              </span>
            </td>
            <td class="category-cell">{{ item.category.name }}</td>
            <td class="hide-sm">{{ item.standardType.name }}</td>
            <td *ngIf="item.effectiveFrom" class="hide-sm">
              {{ item.effectiveFrom | date : "MM.dd.yyyy" }}
            </td>
            <td *ngIf="!item.effectiveFrom" class="hide-sm">N/A</td>
            <td *ngIf="item.newRestaurantsEffectiveFrom" class="hide-sm">
              {{ item.newRestaurantsEffectiveFrom | date : "MM.dd.yyyy" }}
            </td>
            <td *ngIf="!item.newRestaurantsEffectiveFrom" class="hide-sm">
              N/A
            </td>

            <td *ngIf="userRole == 1">
              <a
                href="javascript:void(0);"
                *ngIf="!item.availabileAddendumId && userRole != 3"
                (click)="addAddendum(item.id, $event)"
                >Add Exception</a
              >
              <!-- <a
                href="javascript:void(0);"
                (click)="addendumService.isCommingFromNrbes = true"
                *ngIf="item.availabileAddendumId && userRole != 3"
                (click)="goToAddendum(item)"
                >Go to Exception</a
              >
              <a
                href="javascript:void(0);"
                (click)="addendumService.isCommingFromNrbes = true"
                *ngIf="
                  item.availabileAddendumId &&
                  userRole == 3 &&
                  item.addendumStatusId == addendumStatusEnum.Approved
                "
                (click)="goToAddendum(item)"
                >Go to Exception</a
              > -->
            </td>
            <td style="text-align: center" *ngIf="userRole == 1">
              {{ item.addendum.length }}
            </td>
            <td *ngIf="userRole == 1">
              <div class="row grid-actions hide-sm">
                <div class="col-6">
                  <a
                    class="action-controls"
                    (click)="openDeleteModal(item, $event)"
                    routerLinkActive="active"
                    title="clear"
                    ><i class="material-icons"> clear </i></a
                  >
                </div>
                <div class="col-6">
                  <a
                    class="action-controls"
                    (click)="onEdit(item)"
                    routerLinkActive="active"
                    title="Edit"
                    href="javascript:void(0);"
                    ><i class="material-icons"> create </i></a
                  >
                </div>
              </div>

              <a
                (click)="actionMenuOpen($event)"
                class="mcd-more-btn show-sm"
                [matMenuTriggerFor]="menu"
                href="javascript:void(0);"
              >
                <i class="material-icons">more_vert</i>
              </a>
              <mat-menu #menu="matMenu" overlapTrigger="false">
                <span>
                  <button
                    mat-menu-item
                    *ngIf="canEditEquipmentStandard"
                    (click)="onEdit(item)"
                  >
                    Edit
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="canDeleteEquipmentStandard"
                    (click)="closeMatMenuAndOpenDeleteModal(item, $event)"
                  >
                    Remove
                  </button>
                </span>
              </mat-menu>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col">
          <ngb-pagination
            class="float-right"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="4"
            [rotate]="true"
            [ellipses]="true"
            [collectionSize]="collectionSize"
            (pageChange)="getStandards()"
          ></ngb-pagination>
        </div>
      </div>
    </div>
    <div *ngIf="grses.length == 0">
      Sorry you do not have any upcomming standard.
    </div>
  </mat-card-content>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
