<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">View User Details</mat-card-title>
  </mat-card-header>
  <div class="mat-card-wrapper">
    <mat-card-content class="mcd-card-content nrbes-view">
      <!-- Breadcrumb -->
      <div class="breadcrumbs clearfix">
        <div class="breadcrumbs-inner">
          <ul>
            <li>
              <a routerLink="/global-admin-panel" routerLinkActive="active"
                >Global Admin Panel</a
              >
            </li>
            <li class="separator">></li>
            <li>
              <a routerLink="/view-all-user-list" routerLinkActive="active"
                >Manage Users</a
              >
            </li>
            <li class="separator">></li>
            <li class="active">{{ user.name }}</li>
          </ul>
        </div>
      </div>
      <!-- End Breadcrumb -->
      <div class="nrbes-detail-view">
        <div class="nrbes-detail-item">
          <div class="row">
            <div class="col-4">
              <div class="nrbes-description">
                <h2>Name</h2>
                <div>{{ user.name }}</div>
              </div>
            </div>
            <div class="col-4 offset-md-2">
              <div class="nrbes-description">
                <h2>Email</h2>
                <div>{{ user.email }}</div>
              </div>
            </div>
            <div class="col-2"></div>
            <div class="col-4">
              <div class="nrbes-description">
                <h2>Countries</h2>
                <div>{{ getCommaSeperatedCountries(user.country) }}</div>
              </div>
            </div>
            <div class="col-4 offset-md-2">
              <div class="nrbes-description">
                <h2>User Role</h2>
                <div>{{ user.userRole.name }}</div>
              </div>
            </div>
            <div class="col-2"></div>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-footer class="mcd-card-footer"> </mat-card-footer>
  </div>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
