<div class="row">
  <div class="col-9" style="padding-left: 30px !important">
    <app-standard-search-filter></app-standard-search-filter>
    <mat-card class="mcd-statement">
      <mat-card-content>
        <div class="mcd-statement-header">
          Welcome to Global Restaurant Standards
        </div>
        <div
          class="ql-editor mcd-statement-title"
          [innerHTML]="this.statement"
        ></div>
      </mat-card-content>
    </mat-card>
  </div>
  <!-- <div class="col-3"></div> -->
  <div class="col-3 app-modified-container">
    <app-latest-modified-standards></app-latest-modified-standards>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <mat-card class="mcd-card">
      <mat-card-header class="mcd-card-header">
        <mat-card-title class="mcd-card-title"
          >View Standards by Locations</mat-card-title
        >
      </mat-card-header>
      <mat-card-title class="mcd-card-title">
        <div class="right-menu clearfix">
          <ul>
            <li>
              <a
                href="#"
                (click)="standardService.isGrs = true"
                [routerLink]="['/location-grs-list', locationTypeEnum.BackRoom]"
                >Back Room</a
              >
            </li>
            <li>
              <a
                href="#"
                (click)="standardService.isGrs = true"
                [routerLink]="['/location-grs-list', locationTypeEnum.Beverage]"
                >Beverage Cell/BDAP</a
              >
            </li>
            <li>
              <a
                href="#"
                (click)="standardService.isGrs = true"
                [routerLink]="[
                  '/location-grs-list',
                  locationTypeEnum.BuildingExterior
                ]"
                >Building Exterior</a
              >
            </li>
            <li>
              <a
                href="#"
                (click)="standardService.isGrs = true"
                [routerLink]="[
                  '/location-grs-list',
                  locationTypeEnum.CrewSpace
                ]"
                >Crew Room</a
              >
            </li>
            <li>
              <a
                href="#"
                (click)="standardService.isGrs = true"
                [routerLink]="['/location-grs-list', locationTypeEnum.Curbside]"
                >Curbside</a
              >
            </li>
            <li>
              <a
                href="#"
                (click)="standardService.isGrs = true"
                [routerLink]="['/location-grs-list', locationTypeEnum.Delivery]"
                >Delivery</a
              >
            </li>
            <li>
              <a
                href="#"
                (click)="standardService.isGrs = true"
                [routerLink]="[
                  '/location-grs-list',
                  locationTypeEnum.DriveThru
                ]"
                >Drive Thru</a
              >
            </li>
            <li>
              <a
                href="#"
                (click)="standardService.isGrs = true"
                [routerLink]="[
                  '/location-grs-list',
                  locationTypeEnum.EntireBuilding
                ]"
                >Entire Building</a
              >
            </li>
            <li>
              <a
                href="#"
                (click)="standardService.isGrs = true"
                [routerLink]="['/location-grs-list', locationTypeEnum.Kitchen]"
                >Kitchen</a
              >
            </li>
            <li>
              <a
                href="#"
                (click)="standardService.isGrs = true"
                [routerLink]="['/location-grs-list', locationTypeEnum.Lobby]"
                >Lobby / Service Area</a
              >
            </li>
            <li>
              <a
                href="#"
                (click)="standardService.isGrs = true"
                [routerLink]="[
                  '/location-grs-list',
                  locationTypeEnum.ManagerSpace
                ]"
                >Manager Office</a
              >
            </li>
            <li>
              <a
                href="#"
                (click)="standardService.isGrs = true"
                [routerLink]="['/location-grs-list', locationTypeEnum.McCafe]"
                >McCafé</a
              >
            </li>
            <li>
              <a
                href="#"
                (click)="standardService.isGrs = true"
                [routerLink]="[
                  '/location-grs-list',
                  locationTypeEnum.ParkingLot
                ]"
                >Parking Lot</a
              >
            </li>
            <li>
              <a
                href="#"
                (click)="standardService.isGrs = true"
                [routerLink]="[
                  '/location-grs-list',
                  locationTypeEnum.PlayPlace
                ]"
                >Play Area</a
              >
            </li>
            <li>
              <a
                href="#"
                (click)="standardService.isGrs = true"
                [routerLink]="[
                  '/location-grs-list',
                  locationTypeEnum.RestRooms
                ]"
                >Rest Rooms</a
              >
            </li>
            <li>
              <a
                href="#"
                (click)="standardService.isGrs = true"
                [routerLink]="['/location-grs-list', locationTypeEnum.Storage]"
                >Storage</a
              >
            </li>

            <!--<li><a href="#" (click)="standardService.isGrs=true" [routerLink]="['/location-grs-list', locationTypeEnum.FrontCounter]">Front Counter</a></li>-->
          </ul>
        </div>
      </mat-card-title>
      <mat-card-content class="mcd-card-content">
        <div class="mcd-viewport-wrapper">
          <div class="mcd-view-port" style="position: relative">
            <img
              class="main-image"
              src="/assets/images/locations/pngs/guide-image.png"
              usemap="#image-map"
            />
            <div class="tooltip-text"><span></span></div>
            <div id="Storage" class="chunk storage d-none">
              <img src="/assets/images/locations/pngs/storage.png" />
            </div>
            <div id="backroom" class="chunk backroom d-none">
              <img src="/assets/images/locations/pngs/backroom.png" />
            </div>
            <div id="kitchen" class="chunk kitchen d-none">
              <img src="/assets/images/locations/pngs/kitchen.png" />
            </div>
            <div id="bathroom" class="chunk bathroom d-none">
              <img src="/assets/images/locations/pngs/rest-room.png" />
            </div>
            <div id="CrewSpace" class="chunk crew d-none">
              <img src="/assets/images/locations/pngs/crew.png" />
            </div>
            <div id="cafe" class="chunk cashier d-none">
              <img src="/assets/images/locations/pngs/counter.png" />
            </div>
            <div id="mcCafe" class="chunk mc-cafe d-none">
              <img src="/assets/images/locations/pngs/mc-cafe.png" />
            </div>
            <div id="lobby" class="chunk front-counter d-none">
              <img src="/assets/images/locations/pngs/lobby.png" />
            </div>
            <div id="play-area" class="chunk play-area d-none">
              <img src="/assets/images/locations/pngs/play-area.png" />
            </div>
            <div id="beverage" class="chunk beverage d-none">
              <img src="/assets/images/locations/pngs/beverage.png" />
            </div>
            <div id="delivery" class="chunk delivery d-none">
              <img src="/assets/images/locations/pngs/delivery.png" />
            </div>
            <div id="manager" class="chunk manager d-none">
              <img src="/assets/images/locations/pngs/manager.png" />
            </div>
            <div id="drivethru" class="chunk drivethru d-none">
              <img src="/assets/images/locations/pngs/drive-thru.png" />
            </div>
            <div id="parkingLot" class="chunk parkinglot d-none">
              <img src="/assets/images/locations/pngs/parking-lot.png" />
            </div>
            <div id="curbside" class="chunk curbside active">
              <img
                src="/assets/images/locations/pngs/cs7.png"
                class="curbside-img"
                style="height: 170px; width: 170px"
              />
            </div>
            <div id="sidePart" class="chunk side-part-no-hover d-none">
              <img src="/assets/images/locations/pngs/dt-road.png" />
            </div>
            <div id="entireBuildingA" class="chunk entire-building-a d-none">
              <img src="/assets/images/locations/pngs/entire-building-a.png" />
            </div>
            <!--<div id="entireBuildingB" class="chunk   entire-building-b d-none">
        <img src="/assets/images/locations/pngs/entire-building-b.png">
      </div>-->
            <div id="dtBooth" class="chunk dt-booth d-none">
              <img src="/assets/images/locations/pngs/dt-booth.png" />
            </div>
            <div id="dtCar" class="chunk dt-cars d-none">
              <img src="/assets/images/locations/pngs/dt-cars.png" />
            </div>
            <!--<div id="dtSide" class="chunk dt-side d-none">
        <img src="/assets/images/locations/pngs/dt-side.png">
      </div>-->
            <div id="signage" class="chunk signage d-none">
              <img src="/assets/images/locations/pngs/signage.png" />
            </div>
            <div id="path" class="chunk path d-none">
              <img src="/assets/images/locations/pngs/path2.png" />
            </div>

            <map
              name="image-map"
              class="image-map"
              name="image-map"
              style="z-index: 10000"
            >
              <area
                target="#backroom"
                alt="Back Room"
                (click)="standardService.isGrs = true"
                [routerLink]="['/location-grs-list', locationTypeEnum.BackRoom]"
                title=""
                coords="374,211,336,231,419,296,454,275"
                shape="poly"
              />
              <area
                target="#bathroom"
                alt="Rest Rooms"
                (click)="standardService.isGrs = true"
                [routerLink]="[
                  '/location-grs-list',
                  locationTypeEnum.RestRooms
                ]"
                coords="494,310,521,333,489,350,417,294,454,277,471,298,482,310"
                shape="poly"
              />
              <area
                target="#lobby"
                (click)="standardService.isGrs = true"
                [routerLink]="['/location-grs-list', locationTypeEnum.Lobby]"
                alt="Lobby / Service Area"
                coords="537,258,468,296,484,311,490,309,521,333,492,348,522,376,527,366,533,376,557,362,632,421,664,400,672,407,698,395,618,330,616,302,598,309"
                shape="poly"
              />
              <area
                target="#play-area"
                (click)="standardService.isGrs = true"
                [routerLink]="[
                  '/location-grs-list',
                  locationTypeEnum.PlayPlace
                ]"
                alt="Play Area"
                title=""
                coords="557,362,534,374,608,432,630,419"
                shape="poly"
              />
              <area
                target="#Storage"
                alt="Storage"
                (click)="standardService.isGrs = true"
                [routerLink]="['/location-grs-list', locationTypeEnum.Storage]"
                coords="505,137,375,210,446,267,446,254,502,223,508,224,508,199,544,180,531,169,535,163"
                shape="poly"
              />
              <area
                target="#CrewSpace"
                alt="Crew Room"
                (click)="standardService.isGrs = true"
                [routerLink]="[
                  '/location-grs-list',
                  locationTypeEnum.CrewSpace
                ]"
                coords="472,293,516,270,478,235,450,252,450,269,452,277"
                shape="poly"
              />
              <!--<area target="#cafe" alt="Front Counter" title="" (click)="standardService.isGrs=true" [routerLink]="['/location-grs-list', locationTypeEnum.FrontCounter]" coords="642,287,614,301,616,326,665,368,687,351,688,323" shape="poly">-->
              <area
                target="#mcCafe"
                alt="McCafé"
                title=""
                (click)="standardService.isGrs = true"
                [routerLink]="['/location-grs-list', locationTypeEnum.McCafe]"
                coords="690,323,688,353,665,370,697,390,724,377,731,353"
                shape="poly"
              />
              <area
                target="#beverage"
                alt="Beverage Cell/BDAP"
                (click)="standardService.isGrs = true"
                [routerLink]="['/location-grs-list', locationTypeEnum.Beverage]"
                coords="670,249,642,256,653,270,645,281,708,335,735,346,754,362,747,361,763,354,760,320"
                shape="poly"
              />
              <area
                target="#delivery"
                alt="Delivery"
                [routerLink]="['/location-grs-list', locationTypeEnum.Delivery]"
                coords="794,344,761,323,753,346,756,362"
                shape="poly"
              />

              <area
                target="#manager"
                alt="Manager Office"
                (click)="standardService.isGrs = true"
                [routerLink]="[
                  '/location-grs-list',
                  locationTypeEnum.ManagerSpace
                ]"
                coords="601,193,578,205,645,258,668,245"
                shape="poly"
              />
              <area
                target="#kitchen"
                alt="Kitchen"
                (click)="standardService.isGrs = true"
                [routerLink]="['/location-grs-list', locationTypeEnum.Kitchen]"
                coords="579,208,598,193,584,183,565,193,546,179,508,197,506,223,479,234,515,266,534,258,598,305,613,299,618,317,626,310,640,315,642,285,662,275"
                shape="poly"
              />
              <area
                target="#parkingLot"
                alt="Parking Lot"
                (click)="standardService.isGrs = true"
                [routerLink]="[
                  '/location-grs-list',
                  locationTypeEnum.ParkingLot
                ]"
                coords="309,318,168,392,394,577,535,505"
                shape="poly"
              />
              <area
                target="#curbside"
                alt="Curbside"
                (click)="standardService.isGrs = true"
                [routerLink]="['/location-grs-list', locationTypeEnum.Curbside]"
                coords="534,504,395,576,446,619,588,544"
                shape="poly"
              />
              <!--<area target="#parkingLot" alt="Curbside" (click)="standardService.isGrs=true" [routerLink]="['/location-grs-list', locationTypeEnum.ParkingLot]" coords="200,218,445,619,519,578,588,544" shape="poly">-->
              <!--<area target="#drivethru" alt="Drive Thru" (click)="standardService.isGrs=true" [routerLink]="['/location-grs-list', locationTypeEnum.DriveThru]" coords="551,153,537,160,530,167,562,194,584,182" shape="poly">-->
              <!--<area target="#entireBuildingB"   coords="305,207,306,281,608,519,820,404,823,330,607,433" shape="poly">-->
              <area
                target="#entireBuildingA"
                alt="Building Exterior"
                (click)="standardService.isGrs = true"
                [routerLink]="[
                  '/location-grs-list',
                  locationTypeEnum.BuildingExterior
                ]"
                coords="306,207,503,100,536,123,551,116,821,330,792,341,551,153,535,159,500,137,334,231"
                shape="poly"
              />
              <area
                target="#dtBooth"
                alt="Drive Thru"
                (click)="standardService.isGrs = true"
                [routerLink]="[
                  '/location-grs-list',
                  locationTypeEnum.DriveThru
                ]"
                title=""
                coords="283,214,279,180,258,163,262,149,263,126,245,108,277,86,306,106,307,141,318,159,322,194"
                shape="poly"
              />
              <!-- <area target="#dtSide" alt="" title=""  coords="746,260,585,134,584,99,589,95,642,137,644,165,686,198,688,175,743,218,745,244,751,251,752,259" shape="poly"> -->
              <area
                target="#signage"
                alt="Signage"
                (click)="standardService.isGrs = true"
                [routerLink]="[
                  '/location-grs-list',
                  locationTypeEnum.BuildingExterior
                ]"
                title=""
                coords="538,689,534,615,512,614,521,581,526,563,555,551,565,578,566,585,558,594,544,604,546,688"
                shape="poly"
              />
            </map>
          </div>
        </div>
      </mat-card-content>
      <div class="mobile-view-wrapper">
        <div class="row">
          <div class="col-sm-4 col-4 text-center">
            <a
              href="javascript:void(0)"
              (click)="standardService.isGrs = true"
              [routerLink]="['/location-grs-list', locationTypeEnum.BackRoom]"
              class="location-link"
            >
              <span class="location-link-img-wrapper">
                <img
                  src="/assets/images/locations/mobile/back-room.svg"
                  alt="picture"
                  class="img-fluid"
                />
              </span>
              <span class="location-link-summary">Back Room</span>
            </a>
          </div>

          <div class="col-sm-4 col-4 text-center">
            <a
              href="javascript:void(0)"
              (click)="standardService.isGrs = true"
              [routerLink]="['/location-grs-list', locationTypeEnum.Beverage]"
              class="location-link"
            >
              <span class="location-link-img-wrapper">
                <img
                  src="/assets/images/locations/mobile/beverage.svg"
                  alt="picture"
                  class="img-fluid"
                />
              </span>
              <span class="location-link-summary">Beverage Cell/ BDAP</span>
            </a>
          </div>

          <div class="col-sm-4 col-4 text-center">
            <a
              href="javascript:void(0)"
              (click)="standardService.isGrs = true"
              [routerLink]="[
                '/location-grs-list',
                locationTypeEnum.BuildingExterior
              ]"
              class="location-link"
            >
              <span class="location-link-img-wrapper">
                <img
                  src="/assets/images/locations/mobile/building-ext.svg"
                  alt="picture"
                  class="img-fluid"
                />
              </span>
              <span class="location-link-summary">Building Exterior </span>
            </a>
          </div>

          <div class="col-sm-4 col-4 text-center">
            <a
              href="javascript:void(0)"
              (click)="standardService.isGrs = true"
              [routerLink]="['/location-grs-list', locationTypeEnum.CrewSpace]"
              class="location-link"
            >
              <span class="location-link-img-wrapper">
                <img
                  src="/assets/images/locations/mobile/crew-space.svg"
                  alt="picture"
                  class="img-fluid"
                />
              </span>
              <span class="location-link-summary">Crew Room</span>
            </a>
          </div>

          <div class="col-sm-4 col-4 text-center">
            <a
              href="javascript:void(0)"
              (click)="standardService.isGrs = true"
              [routerLink]="['/location-grs-list', locationTypeEnum.Curbside]"
              class="location-link"
            >
              <span class="location-link-img-wrapper">
                <img
                  src="/assets/images/locations/mobile/parking.svg"
                  alt="picture"
                  class="img-fluid"
                />
              </span>
              <span class="location-link-summary">Curbside</span>
            </a>
          </div>

          <div class="col-sm-4 col-4 text-center">
            <a
              href="javascript:void(0)"
              (click)="standardService.isGrs = true"
              [routerLink]="['/location-grs-list', locationTypeEnum.Delivery]"
              class="location-link"
            >
              <span class="location-link-img-wrapper">
                <img
                  src="/assets/images/locations/mobile/DeliverySpace.svg"
                  alt="picture"
                  class="img-fluid"
                />
              </span>
              <span class="location-link-summary">Delivery</span>
            </a>
          </div>

          <div class="col-sm-4 col-4 text-center">
            <a
              href="javascript:void(0)"
              (click)="standardService.isGrs = true"
              [routerLink]="['/location-grs-list', locationTypeEnum.DriveThru]"
              class="location-link"
            >
              <span class="location-link-img-wrapper">
                <img
                  src="/assets/images/locations/mobile/drive-thru.svg"
                  alt="picture"
                  class="img-fluid"
                />
              </span>
              <span class="location-link-summary">Drive Thru</span>
            </a>
          </div>

          <div class="col-sm-4 col-4 text-center">
            <a
              href="javascript:void(0)"
              (click)="standardService.isGrs = true"
              [routerLink]="[
                '/location-grs-list',
                locationTypeEnum.EntireBuilding
              ]"
              class="location-link"
            >
              <span class="location-link-img-wrapper">
                <img
                  src="/assets/images/locations/mobile/all.svg"
                  alt="picture"
                  class="img-fluid"
                />
              </span>
              <span class="location-link-summary">Entire Building</span>
            </a>
          </div>

          <div class="col-sm-4 col-4 text-center">
            <a
              href="javascript:void(0)"
              (click)="standardService.isGrs = true"
              [routerLink]="['/location-grs-list', locationTypeEnum.Kitchen]"
              class="location-link"
            >
              <span class="location-link-img-wrapper">
                <img
                  src="/assets/images/locations/mobile/kitchen.svg"
                  alt="picture"
                  class="img-fluid"
                />
              </span>
              <span class="location-link-summary">Kitchen</span>
            </a>
          </div>

          <div class="col-sm-4 col-4 text-center">
            <a
              href="javascript:void(0)"
              (click)="standardService.isGrs = true"
              [routerLink]="['/location-grs-list', locationTypeEnum.Lobby]"
              class="location-link"
            >
              <span class="location-link-img-wrapper">
                <img
                  src="/assets/images/locations/mobile/lobby.svg"
                  alt="picture"
                  class="img-fluid"
                />
              </span>
              <span class="location-link-summary">Lobby / Service Area</span>
            </a>
          </div>

          <div class="col-sm-4 col-4 text-center">
            <a
              href="javascript:void(0)"
              (click)="standardService.isGrs = true"
              [routerLink]="[
                '/location-grs-list',
                locationTypeEnum.ManagerSpace
              ]"
              class="location-link"
            >
              <span class="location-link-img-wrapper">
                <img
                  src="/assets/images/locations/mobile/manager-space.svg"
                  alt="picture"
                  class="img-fluid"
                />
              </span>
              <span class="location-link-summary">Manager Office</span>
            </a>
          </div>

          <div class="col-sm-4 col-4 text-center">
            <a
              href="javascript:void(0)"
              (click)="standardService.isGrs = true"
              [routerLink]="['/location-grs-list', locationTypeEnum.McCafe]"
              class="location-link"
            >
              <span class="location-link-img-wrapper">
                <img
                  src="/assets/images/locations/mobile/McCafe.svg"
                  alt="picture"
                  class="img-fluid"
                />
              </span>
              <span class="location-link-summary">McCafé</span>
            </a>
          </div>

          <div class="col-sm-4 col-4 text-center">
            <a
              href="javascript:void(0)"
              (click)="standardService.isGrs = true"
              [routerLink]="['/location-grs-list', locationTypeEnum.ParkingLot]"
              class="location-link"
            >
              <span class="location-link-img-wrapper">
                <img
                  src="/assets/images/locations/mobile/parking.svg"
                  alt="picture"
                  class="img-fluid"
                />
              </span>
              <span class="location-link-summary">Parking Lot</span>
            </a>
          </div>

          <div class="col-sm-4 col-4 text-center">
            <a
              href="javascript:void(0)"
              (click)="standardService.isGrs = true"
              [routerLink]="['/location-grs-list', locationTypeEnum.Curbside]"
              class="location-link"
            >
              <span class="location-link-img-wrapper">
                <img
                  src="/assets/images/locations/mobile/parking.svg"
                  alt="picture"
                  class="img-fluid"
                />
              </span>
              <span class="location-link-summary">Curbside</span>
            </a>
          </div>

          <div class="col-sm-4 col-4 text-center">
            <a
              href="javascript:void(0)"
              (click)="standardService.isGrs = true"
              [routerLink]="['/location-grs-list', locationTypeEnum.PlayPlace]"
              class="location-link"
            >
              <span class="location-link-img-wrapper">
                <img
                  src="/assets/images/locations/mobile/play-place.svg"
                  alt="picture"
                  class="img-fluid"
                />
              </span>
              <span class="location-link-summary">Play Area</span>
            </a>
          </div>

          <div class="col-sm-4 col-4 text-center">
            <a
              href="javascript:void(0)"
              (click)="standardService.isGrs = true"
              [routerLink]="['/location-grs-list', locationTypeEnum.RestRooms]"
              class="location-link"
            >
              <span class="location-link-img-wrapper">
                <img
                  src="/assets/images/locations/mobile/rest-room.svg"
                  alt="picture"
                  class="img-fluid"
                />
              </span>
              <span class="location-link-summary">Rest Rooms</span>
            </a>
          </div>

          <div class="col-sm-4 col-4 text-center">
            <a
              href="javascript:void(0)"
              (click)="standardService.isGrs = true"
              [routerLink]="['/location-grs-list', locationTypeEnum.Storage]"
              class="location-link"
            >
              <span class="location-link-img-wrapper">
                <img
                  src="/assets/images/locations/mobile/storage.svg"
                  alt="picture"
                  class="img-fluid"
                />
              </span>
              <span class="location-link-summary">Storage</span>
            </a>
          </div>

          <!--<div class="col-sm-4 col-4 text-center">
        <a href="javascript:void(0)" (click)="standardService.isGrs=true" [routerLink]="['/location-grs-list', locationTypeEnum.FrontCounter]"
          class="location-link">
            <span class="location-link-img-wrapper">
              <img src="/assets/images/locations/mobile/front-counter.svg" alt="picture" class="img-fluid" />
          </span>
          <span class="location-link-summary">Front Counter</span>
        </a>
      </div>-->
        </div>
      </div>
    </mat-card>
  </div>
</div>
