import { Component, OnInit, Inject } from "@angular/core";
import { AddendumService } from "../services/addendum.service";
import { Constant, UserRoleEnum, AddendumStatusEnum } from "../core/constant";
import { Addendum } from "../models/addendum";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { Router } from "@angular/router";
import { AddendumList } from "../models/addendum-list";
import { Country } from "../models/country";
import { Observable } from "rxjs";
import { CryptographyService } from "../services/cryptography.service";
import { AddendumStatus } from "../models/addendumStatus";
import { StandardService } from "../services/standard.service";

declare var $: any;
@Component({
  selector: "app-addendum-list",
  templateUrl: "./addendum-list.component.html",
  styleUrls: ["./addendum-list.component.scss"],
})
export class AddendumListComponent implements OnInit {
  userRole: number = 2; // 1 -> Global Admin , 2 -> Market Admin
  page: number = 1;
  pageSize: number = 5;
  collectionSize: number = 0;
  pageSizes: any[] = Constant.PageSizes;

  addendums: Addendum[] = [];
  countries: Country[] = [];
  addendumStatuses: AddendumStatus[] = [];
  error: string = "";
  selectedCountryId: number = 0;
  countryId: Array<number> = null;
  loading: boolean = false;
  filterList: number[] = [];
  get userRoleEnum() {
    return UserRoleEnum;
  }

  constructor(
    private addendumService: AddendumService,
    private cryptographyService: CryptographyService,
    private router: Router,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private standardService: StandardService
  ) {}

  ngOnInit() {
    this.filterList = this.standardService.addendumFiltration.filter;
    this.pageSize = this.standardService.addendumFiltration.pageSize;
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    this.getMasterDataForAddendum().subscribe(() => {
      this.countryId =
        this.userRole == this.userRoleEnum.GlobalAdmin
          ? [0]
          : this.cryptographyService.decryptData(this.storage.get("countryId"));
      // this.countryId = (this.userRole != this.userRoleEnum. GlobalAdmin) ?
      //   this.cryptographyService.decryptData(this.storage.get('countryId')) : (this.standardService.addendumFiltration.countryId ?
      //     this.standardService.addendumFiltration.countryId : this.cryptographyService.decryptData(this.storage.get('countryId')));
      this.getAddendums();
    });
    // this.getAllAddendumStatuses();

    $(window).scroll(function (event) {
      let st = $(this).scrollTop();
      if (st > 199) {
        $(".filter-panel-row").addClass("freeze");
      } else {
        $(".filter-panel-row").removeClass("freeze");
        $(".filter-panel-row").removeClass("freeze");
      }
    });
  }

  // getAllAddendumStatuses(): void {
  //   this.addendumService.getAllAddendumStatuses(this.userRole)
  //     .subscribe(
  //       (data: AddendumStatus[]) => {
  //         this.addendumStatuses = data;
  //       });
  // }
  /**
   * Selected Items Per Page Change
   * @return void
   */
  selectedItemsPerPageChange(event) {
    this.page = 1;
    this.pageSize = event.value;
    this.standardService.addendumFiltration.pageSize = event.value;
    this.getAddendums();
  }

  /**
   * Get Addendums
   * @return void
   */
  getAddendums(): void {
    this.loading = true;
    let isRemoveDraftBeforeRequest =
      this.userRole == UserRoleEnum.GlobalAdmin ? true : false;
    this.addendumService
      .getAllAddendumsByCountryId(
        this.countryId,
        isRemoveDraftBeforeRequest,
        (this.page - 1) * this.pageSize,
        this.pageSize,
        this.filterList,
        this.userRole,
        false
      )
      .subscribe((data: AddendumList) => {
        console.log("DATA", data);
        try {
          debugger;
          this.addendums = data.addendums.map((obj: any) => {
            let c = [obj.countryId];
            let d = [obj.country];
            obj.countryId = c;
            obj.country = d;
            return new Addendum(obj, this.userRole);
          });
        } catch (e) {
          console.error("ERRROR", e);
        }
        this.collectionSize = data.count;
        this.loading = false;
        (error) => {
          this.error = error;
          this.loading = false;
        };
      });
  }

  /**
   * Row selected
   * @return void
   */
  rowSelected(addendum: Addendum): void {
    this.standardService.fromLocationGRS = false;
    if (addendum.addendumStatus.id == AddendumStatusEnum.DraftBeforeRequest) {
      this.router.navigate(["/add-addendum/" + addendum.standard.id]);
    } else {
      this.router.navigate(["/addendum/" + addendum.id]);
    }
  }

  /**
   * Get All Countries
   * @return void
   */
  // getAllCountries(): Observable<void> {
  //   this.loading = true;
  //   return new Observable<void>(observer => {
  //     this.addendumService.getAllCountries()
  //       .subscribe(
  //         (data: Country[]) => {
  //           this.countries = data.map(obj => new Country(obj));
  //           this.loading = false;
  //           observer.next();
  //         });
  //   })
  // };

  selectCountry(event) {
    this.page = 1;
    this.countryId = [event.value];
    this.selectedCountryId = event.value;
    this.standardService.addendumFiltration.countryId = event.value;
    this.getAddendums();
  }

  addOrRemoveFilter($event, id) {
    this.pageSize = 5;
    this.page = 1;

    if ($event.checked) {
      if (!this.filterList.includes(id)) {
        this.filterList.push(id);

        this.getAddendums();
      }
    } else {
      this.filterList.splice(this.filterList.indexOf(id), 1);
      this.getAddendums();
    }
    this.standardService.addendumFiltration.filter = this.filterList;
  }

  setCheckedStateForCheckBoxes(id) {
    return this.filterList.includes(id);
  }

  getMasterDataForAddendum(): Observable<any> {
    this.loading = true;
    return new Observable<any>((observer) => {
      this.addendumService
        .getMasterDataForAddendum(
          this.cryptographyService.decryptData(this.storage.get("userRole")),
          this.cryptographyService.decryptData(this.storage.get("userId"))
        )
        .subscribe((data: any) => {
          this.countries = data.countries;
          this.addendumStatuses = data.addendumStatuses;
          this.loading = false;
          observer.next();
        });
    });
  }
}
