import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Inject,
  Input,
} from "@angular/core";
import { FilteringGuide } from "../models/filteringGuide";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { StandardService } from "../services/standard.service";
import { ActivatedRoute } from "@angular/router";
import { LocationType } from "../models/location-type";
import { StandardListType, FilteringEnum } from "../core/constant";
import { EquipmentType } from "../models/equipment-type";
import { SubLocationType } from "../models/sub-location-type";
import { FilterList } from "../models/filterList";

@Component({
  selector: "app-location-type-filter",
  templateUrl: "./location-type-filter.component.html",
  styleUrls: ["./location-type-filter.component.scss"],
})
export class LocationTypeFilterComponent implements OnInit {
  locationId: number = 0;
  panelOpenState: boolean = false;
  locationTypes: LocationType[] = [];
  subLcationTypes: SubLocationType[] = [];
  subLcationTypesAll: SubLocationType[] = [];
  equipmentTypes: EquipmentType[] = [];
  equipmentTypesAll: EquipmentType[] = [];
  filterList: FilteringGuide[] = [];
  filteringForCheckBox: FilteringGuide[] = [];

  @Input() standardFiltration: FilterList = new FilterList();
  @Output() messageEvent = new EventEmitter<Array<FilteringGuide>>();
  @Input() standardListType: number;
  constructor(
    private activeRoute: ActivatedRoute,
    @Inject(LOCAL_STORAGE)
    public storage: WebStorageService,
    private standardService: StandardService
  ) {}

  ngOnInit() {
    this.getAllStandardTypes();
  }

  getAllStandardTypes(): void {
    this.locationId = parseInt(
      this.activeRoute.snapshot.paramMap.get("locationId")
    );
    this.standardService
      .getEquipmentStandardChipset(this.standardFiltration)
      .subscribe((data: any) => {
        console.log("data for locations", data);
        this.locationTypes = data.locationTypes.map((o) => new LocationType(o));
        this.equipmentTypes = data.equipmentTypes.map(
          (o) => new EquipmentType(o)
        );
      });
  }

  addFilter($event, id, filterParam) {
    //debugger;
    if (this.getFilter(this.standardListType) != null) {
      this.filterList = this.getFilter(this.standardListType);
    }
    if ($event && FilteringEnum.FilterByLocationType === filterParam) {
      //this.setEquipmentTypes(id, $event.checked);
      //this.setSubLocationTypes(id, $event.checked);
    }

    if ($event.checked) {
      let filterGuide = this.filterList.find((i) => i.type === filterParam);
      if (filterGuide != undefined) {
        if (!filterGuide.ids.includes(id)) {
          filterGuide.ids.push(id);
        }
      } else {
        let guide = new FilteringGuide();
        guide.type = filterParam;
        guide.ids.push(id);
        this.filterList.push(guide);
      }
    } else {
      let selectedFilter = this.filterList.find((i) => i.type === filterParam);
      if (selectedFilter != undefined && selectedFilter.ids.includes(id)) {
        let index = selectedFilter.ids.indexOf(id);
        selectedFilter.ids.splice(index, 1);
      }
      if (selectedFilter.ids.length == 0) {
        let i = this.filterList.findIndex((x) => x.type === filterParam);
        this.filterList.splice(i, 1);
      }
    }
    this.setFilter(this.standardListType, this.filterList);
    this.messageEvent.emit(this.filterList);
  }

  setEquipmentTypes(locationId, isAdd) {
    let toAdd = this.locationTypes.filter((c) => c.id === locationId)[0]
      .equipmentTypes;
    if (toAdd) {
      if (isAdd) {
        if (this.equipmentTypes.length > 0) {
          this.equipmentTypesAll = this.equipmentTypesAll.concat(toAdd);
          this.equipmentTypes = Array.from(
            new Set(this.equipmentTypesAll.map((s) => s.id))
          ).map((id) => {
            return {
              id: id,
              name: this.equipmentTypesAll.find((s) => s.id === id).name,
              description: "",
              equipmentTypeLocationMappings: [],
            };
          });
        } else {
          this.equipmentTypes = toAdd.map((o) => new EquipmentType(o));
          this.equipmentTypesAll = toAdd.map((o) => new EquipmentType(o));
        }
      } else {
        toAdd.forEach((c) => {
          let toBeRemovedIndex = this.equipmentTypesAll.findIndex(
            (x) => x.id == c.id
          );
          let itemCount = this.equipmentTypesAll.filter(
            (x) => x.id == c.id
          ).length;
          if (itemCount == 1) {
            let selectedFilter = this.filterList.find(
              (i) => i.type === FilteringEnum.FilterByEquipmentType
            );
            if (
              selectedFilter != undefined &&
              selectedFilter.ids.includes(c.id)
            ) {
              let index = selectedFilter.ids.indexOf(c.id);
              if (index > -1) {
                selectedFilter.ids.splice(index, 1);
              }
            }
            if (selectedFilter && selectedFilter.ids.length == 0) {
              let i = this.filterList.findIndex(
                (x) => x.type === FilteringEnum.FilterByEquipmentType
              );
              this.filterList.splice(i, 1);
            }
          }
          this.equipmentTypesAll.splice(toBeRemovedIndex, 1);
        });

        this.equipmentTypes = Array.from(
          new Set(this.equipmentTypesAll.map((s) => s.id))
        ).map((id) => {
          return {
            id: id,
            name: this.equipmentTypesAll.find((s) => s.id === id).name,
            description: "",
            equipmentTypeLocationMappings: [],
          };
        });
      }
    }
  }

  setSubLocationTypes(locationId, isAdd) {
    let toAdd = this.locationTypes.filter((c) => c.id === locationId)[0]
      .subLocationTypes;
    if (toAdd) {
      if (isAdd) {
        if (this.subLcationTypes.length > 0) {
          this.subLcationTypesAll = this.subLcationTypesAll.concat(toAdd);
          this.subLcationTypes = Array.from(
            new Set(this.subLcationTypesAll.map((s) => s.id))
          ).map((id) => {
            return {
              id: id,
              name: this.subLcationTypesAll.find((s) => s.id === id).name,
              locationTypeId: this.subLcationTypesAll.find((s) => s.id === id)
                .locationTypeId,
            };
          });
        } else {
          this.subLcationTypes = toAdd.map((o) => new SubLocationType(o));
          this.subLcationTypesAll = toAdd.map((o) => new SubLocationType(o));
        }
      } else {
        toAdd.forEach((c) => {
          let toBeRemovedIndex = this.subLcationTypesAll.findIndex(
            (x) => x.id == c.id
          );
          let itemCount = this.subLcationTypesAll.filter(
            (x) => x.id == c.id
          ).length;
          if (itemCount == 1) {
            let selectedFilter = this.filterList.find(
              (i) => i.type === FilteringEnum.FilterBySubLocationType
            );
            if (
              selectedFilter != undefined &&
              selectedFilter.ids.includes(c.id)
            ) {
              let index = selectedFilter.ids.indexOf(c.id);
              if (index > -1) {
                selectedFilter.ids.splice(index, 1);
              }
            }
            if (selectedFilter && selectedFilter.ids.length == 0) {
              let i = this.filterList.findIndex(
                (x) => x.type === FilteringEnum.FilterBySubLocationType
              );
              this.filterList.splice(i, 1);
            }
          }
          this.subLcationTypesAll.splice(toBeRemovedIndex, 1);
        });
        this.subLcationTypes = Array.from(
          new Set(this.subLcationTypesAll.map((s) => s.id))
        ).map((id) => {
          return {
            id: id,
            name: this.subLcationTypesAll.find((s) => s.id === id).name,
            locationTypeId: this.subLcationTypesAll.find((s) => s.id === id)
              .locationTypeId,
          };
        });
      }
    }
  }

  setCheckedStateForCheckBoxes(type, id) {
    this.filteringForCheckBox = this.getFilter(this.standardListType);
    if (this.filteringForCheckBox != null) {
      let t = this.filteringForCheckBox.find((i) => i.type === type);
      if (t != undefined && t.ids.includes(id)) {
        return true;
      }
    }
  }

  getFilter(standardListType: number) {
    switch (standardListType) {
      case StandardListType.GlobalEquipment: {
        return this.standardService.equipmentStandardFiltration.filterSet;
      }
      case StandardListType.NationalEquipment: {
        return this.standardService.nationalEquipmentStandardFiltration
          .filterSet;
      }
      case StandardListType.Obsolete: {
        return this.standardService.obsoleteStandardFiltration.filterSet;
      }
      default: {
        break;
      }
    }
  }

  setFilter(standardListType: number, filterList: FilteringGuide[]) {
    switch (standardListType) {
      case StandardListType.GlobalEquipment: {
        return (this.standardService.equipmentStandardFiltration.filterSet =
          filterList);
      }
      case StandardListType.NationalEquipment: {
        return (this.standardService.nationalEquipmentStandardFiltration.filterSet =
          filterList);
      }
      case StandardListType.Obsolete: {
        return (this.standardService.obsoleteStandardFiltration.filterSet =
          filterList);
      }
      default: {
        break;
      }
    }
  }
}
