<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title"
      >{{ user.id > 0 ? "Edit" : "Add New" }} Useful Link</mat-card-title
    >
  </mat-card-header>
  <div class="breadcrumbs clearfix">
    <div class="breadcrumbs-inner">
      <ul>
        <li>
          <a routerLink="/global-admin-panel" routerLinkActive="active"
            >Global Admin Panel</a
          >
        </li>
        <li class="separator">></li>
        <li>
          <a routerLink="/view-all-useful-links" routerLinkActive="active"
            >Manage Useful Links</a
          >
        </li>
        <li class="separator">></li>
        <li class="active">{{ user.id > 0 ? "Edit" : "Add New" }} User</li>
      </ul>
    </div>
  </div>
  <div class="mat-card-wrapper">
    <mat-card-content class="mcd-card-content">
      <form [formGroup]="addEditUserForm">
        <div class="mcd-edit-form mcd-grbes-form">
          <div class="row">
            <div class="col-5">
              <mat-form-field>
                <input
                  matInput
                  placeholder="First Name"
                  name="Name"
                  formControlName="name"
                  required
                />
                <mat-error
                  *ngIf="
                    addEditUserForm.get('name').touched &&
                    addEditUserForm.get('name').hasError('required')
                  "
                >
                  First Name is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-5 offset-md-2">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Last Name"
                  name="LastName"
                  formControlName="lastName"
                  required
                />
                <mat-error
                  *ngIf="
                    addEditUserForm.get('lastName').touched &&
                    addEditUserForm.get('lastName').hasError('required')
                  "
                >
                  Last Name is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-5">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Email"
                  name="Email"
                  formControlName="email"
                  required
                />
                <mat-error *ngIf="addEditUserForm.invalid">{{
                  getEmailValidationErrorMessage()
                }}</mat-error>
                <mat-error *ngIf="!isEmailAllowed"
                  >This email is taken,try another</mat-error
                >
                <!--<div class="mat-error ng-star-inserted" *ngIf="!isEmailAllowed">That email is taken,try another</div>-->
              </mat-form-field>
            </div>
            <div
              class="col-5 offset-md-2"
              *ngIf="
                addEditUserForm.controls.userRoleId.value == 2 ||
                addEditUserForm.controls.userRoleId.value == 3
              "
            >
              <mat-form-field>
                <mat-label>Country</mat-label>
                <mat-select
                  name="dropLocation"
                  [value]="addEditUserForm['controls'].countryId.value"
                  #country
                  multiple
                  (selectionChange)="
                    addEditUserForm['controls'].countryId.setValue($event.value)
                  "
                  required
                >
                  <mat-option [value]="item.id" *ngFor="let item of countries">
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div
              class="col-5 offset-md-2"
              *ngIf="
                addEditUserForm.controls.userRoleId.value == 1 ||
                addEditUserForm.controls.userRoleId.value == 4
              "
            >
              <mat-form-field>
                <mat-label>Country</mat-label>
                <mat-select
                  name="dropLocation"
                  [value]="addEditUserForm['controls'].countryId.value"
                  #country
                  (selectionChange)="
                    addEditUserForm['controls'].countryId.setValue($event.value)
                  "
                  required
                >
                  <mat-option [value]="item.id" *ngFor="let item of countries">
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-5">
              <mat-form-field>
                <mat-label>User Role</mat-label>
                <mat-select
                  name="dropLocation"
                  [value]="addEditUserForm['controls'].userRoleId.value"
                  #userRole
                  (selectionChange)="
                    addEditUserForm['controls'].userRoleId.setValue(
                      $event.value
                    );
                    onRoleChange($event.value)
                  "
                  required
                >
                  <mat-option [value]="item.id" *ngFor="let item of userRoles">
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-5 offset-md-2 setmargin">
              <mat-checkbox
                formControlName="isNotificationEnable"
                [checked]="this.user.isNotificationEnable"
                >Allow Email Notifications</mat-checkbox
              >
            </div>
            <div class="col-5">
              <div
                *ngIf="
                  addEditUserForm['controls'].userRoleId.value ==
                  userRoleEnum.AOWAdmin
                "
              >
                <mat-form-field>
                  <mat-label>AOWGroup</mat-label>
                  <mat-select
                    name="dropLocation"
                    [value]="addEditUserForm['controls'].aowGroupId.value"
                    #aowGroup
                    (selectionChange)="
                      addEditUserForm['controls'].aowGroupId.setValue(
                        $event.value
                      )
                    "
                    required
                  >
                    <mat-option
                      [value]="item.id"
                      *ngFor="let item of aowGroups"
                    >
                      {{ item.groupName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
    <mat-card-footer class="mcd-card-footer">
      <button
        class="mcd-btn mcd-btn-primary"
        *ngIf="!user.id"
        mat-raised-button
        (click)="onReset()"
      >
        Reset
      </button>
      <button
        class="mcd-btn mcd-btn-primary"
        *ngIf="user.id"
        mat-raised-button
        (click)="onCancelClick()"
      >
        Cancel
      </button>
      <button
        class="mcd-btn mcd-btn-secondary"
        mat-raised-button
        [disabled]="addEditUserForm.invalid"
        (click)="onSaveUser()"
      >
        {{ user.id > 0 ? "Update" : "Add" }}
      </button>
    </mat-card-footer>
  </div>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
