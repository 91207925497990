import { Component, Input, OnInit } from "@angular/core";
import { StandardService } from "../services/standard.service";
@Component({
  selector: "app-sideView-GloabalApprovedSolutions",
  templateUrl: "./sideView-GloabalApprovedSolutions.html",
  styleUrls: ["./sideView-GloabalApprovedSolutions.scss"],
})
export class sideViewGloabalApprovedSolutions implements OnInit {
  redirectGlobalApproved: any = "/global-recommendations-detail-view/";

  latestModification: any;
  dependentStandards: any;
  @Input() inputData: any;
  constructor(public standardService: StandardService) {}
  ngOnInit() {
    // this.standardService.getLatestModifiedStandards().subscribe((response) => {
    console.log("inputtt", this.inputData);

    this.dependentStandards = this.inputData.dependentStandards;
    console.log("depeneddddd", this.dependentStandards);
    this.latestModification = [
      {
        title: "Retrofit Cost",
        category: "Safety and Security",
        description:
          "Before Retrofitting a spyhole, check with the local construction team that the price of a retrofit is more cost effective than replacing the door",
        location: "Back room",
      },
      {
        title: "Retrofit Cost",
        category: "Safety and Security",
        description:
          "Before Retrofitting a spyhole, check with the local construction team that the price of a retrofit is more cost effective than replacing the door",
        location: "Back room",
      },
    ];
    // });
  }
}
