import { Component, OnInit, Inject } from "@angular/core";
import { SelectedStandard } from "../models/selected-standard";
import { Router, ActivatedRoute } from "@angular/router";
import {
  UserRoleEnum,
  StandardTypeEnum,
  StandardListType,
} from "../core/constant";
import { StandardService } from "../services/standard.service";
import { CryptographyService } from "../services/cryptography.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { Attachment } from "../models/attachment";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import linkifyStr from "linkifyjs/string";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-view-all-obsolete-standard-list-detail-view",
  templateUrl: "./view-all-obsolete-standard-detail-view.component.html",
  styleUrls: ["./view-all-obsolete-standard-detail-view.component.scss"],
  providers: [DatePipe],
})
export class ViewAllObsoleteStandardEquipmentDetailViewComponent
  implements OnInit
{
  grsId: number;
  loading: boolean = false;
  grsDetail: SelectedStandard = new SelectedStandard();
  userRole: number;

  get standardTypeEnum() {
    return StandardTypeEnum;
  }
  get userRoleEnum() {
    return UserRoleEnum;
  }

  constructor(
    private router: Router,
    private standardService: StandardService,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private activeRoute: ActivatedRoute,
    private ngbModal: NgbModal,
    private datePipe: DatePipe
  ) {
    this.grsId = parseInt(this.activeRoute.snapshot.paramMap.get("grsId"));
  }

  ngOnInit() {
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    this.getDetailByGrbesId(this.grsId);
  }

  getDetailByGrbesId(grsId: number) {
    this.loading = true;
    this.standardService
      .getGrbesDetailInfomationByStandardId(
        grsId,
        this.cryptographyService.decryptData(this.storage.get("countryId")),
        this.standardService.obsoleteStandardFiltration.filterSet,
        StandardListType.Obsolete,
        null
      )
      .subscribe((data: SelectedStandard) => {
        this.grsDetail = new SelectedStandard(data);

        var options = {
          /* … */
        };
        this.grsDetail.standard.note = linkifyStr(
          this.grsDetail.standard.note,
          options
        );
        this.loading = false;
        () => {
          this.loading = false;
        };
      });
  }

  navigateAccToType(type, id) {
    if (type == 4) {
      this.router.navigate(["/minimum-standard-detail-view/" + id]);
      return;
    }
    if (type == 8) {
      this.router.navigate(["/operations-standards-detail-view/" + id]);
      return;
    }
    if (type == 0) {
      this.router.navigate(["/view-equipments-standard-detail/" + id]);
      return;
    }
    if (type == 1 || type == 2) {
      this.router.navigate(["/global-recommendations-detail-view/" + id]);
      return;
    }
  }
  slide(grsID: number, isForward: boolean) {
    this.loading = true;
    if (this.userRole != this.userRoleEnum.MarketAdmin) {
      this.standardService
        .getNextPreviousGrsInfomationByCurrentStandardId(
          grsID,
          isForward,
          this.cryptographyService.decryptData(this.storage.get("countryId")),
          this.standardService.obsoleteStandardFiltration.filterSet,
          StandardListType.Obsolete
        )
        .subscribe((data: SelectedStandard) => {
          if (
            data.standard.standardTypeId !=
            this.standardTypeEnum.GlobalEquipmentStandard
          ) {
            this.router.navigate(["/grs-detail-view/" + data.standard.id]);
          }
          this.router.navigate([
            "view-obsolete-equipments-standard-detail",
            data.standard.id,
          ]);
          this.grsDetail = new SelectedStandard(data);
          var options = {
            /* … */
          };
          this.grsDetail.standard.note = linkifyStr(
            this.grsDetail.standard.note,
            options
          );
          this.loading = false;
          () => {
            this.loading = false;
          };
        });
    } else {
      this.standardService
        .getNextPreviousGrsInfomationByCurrentStandardId(
          grsID,
          isForward,
          this.cryptographyService.decryptData(this.storage.get("countryId")),
          this.standardService.obsoleteStandardFiltration.filterSet,
          StandardListType.Obsolete
        )
        .subscribe((data: SelectedStandard) => {
          if (
            data.standard.standardTypeId !=
            this.standardTypeEnum.GlobalEquipmentStandard
          ) {
            this.router.navigate(["/grs-detail-view/" + data.standard.id]);
          }
          this.router.navigate([
            "view-obsolete-equipments-standard-detail",
            data.standard.id,
          ]);
          this.grsDetail = new SelectedStandard(data);
          var options = {
            /* … */
          };
          this.grsDetail.standard.note = linkifyStr(
            this.grsDetail.standard.note,
            options
          );
          this.loading = false;
          () => {
            this.loading = false;
          };
        });
    }
  }

  onDownloadSelectedEquipmentAttachment(e) {
    return this.standardService.getAttachmentUrl(e.id, false).subscribe(
      (response: any) => {
        const blob = new Blob([response], {
          type: response.type,
        });
        if (
          (window.navigator as any) &&
          (window.navigator as any).msSaveOrOpenBlob
        ) {
          (window.navigator as any).msSaveOrOpenBlob(blob, e.fileName);
          return;
        }
        var a = document.createElement("a");
        document.body.appendChild(a);

        let url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = e.fileName;
        a.click();
        window.URL.revokeObjectURL(url);

        //window.open(url);
      },
      (error: any) => {
        const modalRef = this.ngbModal.open(FailModuleComponent);
        modalRef.componentInstance.errorMessage = error.error
          ? error.error.Message
          : error.message;
      }
    );
  }
  downloadExcel() {
    this.standardService.downloadSingleStandardExcel(
      this.grsDetail,
      "obsolete"
    );
    return;
  }
  getLocationNames(item): any {
    if (
      item.standard.standardLocation &&
      item.standard.standardLocation.length > 0
    ) {
      return item.standard.standardLocation
        .map((loc) => loc.locationType.name)
        .join(", ");
    } else {
      return item.standard.locationType.name;
    }
  }
}
