<div class="row">
  <div class="col-9">
    <mat-card class="mcd-card">
      <mat-card-header class="mcd-card-header-dateModified">
        <mat-card-title
          class="mcd-card-title"
          style="font-weight: bold; margin-top: 10px"
          >View Upcoming Standard</mat-card-title
        >
        <mat-card-title class="mcd-card-title-dateModified"
          ><span style="font-size: 12px; margin-right: 5px">Last Modified</span>
          <span style="font-weight: bold; font-size: 12px; margin-right: 5px">{{
            grsDetail.standard.lastModifiedDate | date : "MMM dd,yyyy"
          }}</span>
          <!-- <span style="font-size: 12px; margin-right: 5px">by</span>
      <span style="font-weight: bold; font-size: 12px">{{
        name
      }}</span> -->
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="mcd-card-content nrbes-view">
        <!-- Breadcrumb -->
        <div class="breadcrumbs clearfix">
          <div class="breadcrumbs-inner">
            <ul>
              <li>
                <a
                  routerLink="/view-all-upcoming-standards-list"
                  routerLinkActive="active"
                  >All</a
                >
              </li>
              <li class="separator">/</li>
              <li class="active">{{ grsDetail.standard.title }}</li>
              <li style="float: right">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style="margin-bottom: 2px; margin-right: 2px"
                >
                  <path
                    d="M8.5 5V1H5.5V5H3.5L7 9L10.5 5H8.5Z"
                    stroke="#3C74E0"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.5 9V11.5C12.5 11.8978 12.342 12.2794 12.0607 12.5607C11.7794 12.842 11.3978 13 11 13H3C2.60218 13 2.22064 12.842 1.93934 12.5607C1.65804 12.2794 1.5 11.8978 1.5 11.5V9"
                    stroke="#3C74E0"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <a
                  style="color: #0b59ca; text-decoration: none; cursor: pointer"
                  (click)="downloadExcel()"
                  >Download
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- End Breadcrumb -->

        <div *ngIf="!loading" class="nrbes-detail-view">
          <!-- Dynamic Wrapper -->
          <div class="hash-tag">
            {{ grsDetail.standard.title }}
          </div>
          <div class="nrbes-detail-item">
            <h1 style="color: #b79a81; font-weight: bold; font-size: 16px">
              <svg
                width="15"
                height="15"
                viewBox="0 0 14 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="margin-bottom: 4px"
              >
                <path
                  d="M5.5 1H1.5V5H5.5V1Z"
                  stroke="#B79A81"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.5 1H8.5V5H12.5V1Z"
                  stroke="#B79A81"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.5 8H1.5V12H5.5V8Z"
                  stroke="#B79A81"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.5 8H8.5V12H12.5V8Z"
                  stroke="#B79A81"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {{ grsDetail.standard.category?.name }}
            </h1>
          </div>
          <div class="nrbes-detail-item">
            <!--<div *ngIf="grsDetail.standard.imageAttachment" class="mcd-operations-image">
      <img [src]="grsDetail?.standard?.imageAttachment?.image">
    </div>-->

            <div
              style="
                /* border: 2px solid red; */
                display: flex;

                justify-content: space-between;
              "
            >
              <div
                class="row"
                style="
                  display: flex;
                  justify-content: space-between;
                  width: 100vw;
                "
              >
                <div class="col-6">
                  <div class="dateCard">
                    <div class="nrbes-description">
                      <h2 style="font-size: 14px">
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style="margin-bottom: 5px"
                        >
                          <path
                            d="M12.7667 8.93335V15H3.23337V8.93335"
                            stroke="#F28779"
                            stroke-width="1.4"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6.7001 15.0001V11.5334H9.3001V15.0001"
                            stroke="#F28779"
                            stroke-width="1.4"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.5 7.2H1.5L3.23333 4.16667V2H12.7667V4.16667L14.5 7.2Z"
                            stroke="#F28779"
                            stroke-width="1.4"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        New Restaurant Upcoming Standard
                      </h2>
                    </div>
                    <div
                      *ngIf="grsDetail.standard.newRestaurantsEffectiveFrom"
                      class="mcd-date-box-wrapper"
                    >
                      <label>Effective Date</label>
                      <div class="mcd-date-box">
                        <div class="mcd-date-box-header">
                          {{
                            grsDetail.standard.newRestaurantsEffectiveFrom
                              | date : "MMM | yyyy"
                          }}
                        </div>
                        <div class="mcd-date-box-day">
                          {{
                            grsDetail.standard.newRestaurantsEffectiveFrom
                              | date : "dd"
                          }}
                        </div>
                      </div>
                    </div>

                    <div
                      *ngIf="!grsDetail.standard.newRestaurantsEffectiveFrom"
                      class="mcd-date-box-wrapper"
                    >
                      <label>Effective Date - N/A </label>
                    </div>
                    <div class="nrbes-description">
                      <p>
                        {{ grsDetail.standard.newRestaurantDescription }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="dateCard">
                    <div class="nrbes-description">
                      <h2 style="font-size: 14px">
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style="margin-bottom: 5px"
                        >
                          <path
                            d="M13.2667 8.93335V15H3.73336V8.93335"
                            stroke="#F28779"
                            stroke-width="1.4"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.20009 15.0001V11.5334H9.80009V15.0001"
                            stroke="#F28779"
                            stroke-width="1.4"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M15 7.2H2L3.73333 4.16667V2H13.2667V4.16667L15 7.2Z"
                            stroke="#F28779"
                            stroke-width="1.4"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Existing Restaurant Upcoming Standard
                      </h2>
                    </div>
                    <div
                      *ngIf="grsDetail.standard.effectiveFrom"
                      class="mcd-date-box-wrapper"
                    >
                      <label>Effective Date</label>
                      <div class="mcd-date-box">
                        <div class="mcd-date-box-header">
                          {{
                            grsDetail.standard.effectiveFrom
                              | date : "MMM | yyyy"
                          }}
                        </div>
                        <div class="mcd-date-box-day">
                          {{ grsDetail.standard.effectiveFrom | date : "dd" }}
                        </div>
                      </div>
                    </div>

                    <div
                      *ngIf="!grsDetail.standard.effectiveFrom"
                      class="mcd-date-box-wrapper"
                    >
                      <label>Effective Date - N/A </label>
                    </div>
                    <div class="nrbes-description">
                      <p>
                        {{ grsDetail.standard.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- </div> -->
            </div>
            <!-- </div> -->
            <br />
            <br />

            <div
              class="nrbes-detail-item"
              style="
                border: 1px solid #d9d9d9;
                padding: 10px;
                border-radius: 10px;
              "
            >
              <div class="nrbes-Benefit">
                <h2 style="font-size: 14px">Upcoming Standard Details</h2>

                <div class="ql-container ql-snow" style="border-width: 0">
                  <div
                    class="ql-editor nrbes-description"
                    [innerHTML]="this.notes"
                  ></div>
                </div>
              </div>
            </div>

            <br />
            <br />

            <div
              class="nrbes-detail-item"
              style="
                border: 1px solid #d9d9d9;
                padding: 10px;
                border-radius: 10px;
              "
            >
              <div class="nrbes-Benefit">
                <h2 style="font-size: 14px">Additional References</h2>

                <div class="ql-container ql-snow" style="border-width: 0">
                  <div
                    class="ql-editor nrbes-description"
                    [innerHTML]="this.additionalInformation"
                  ></div>
                </div>
              </div>
            </div>
            <br />
          </div>

          <!-- End Dynamic Wrapper -->
          <!-- Next Previous Buttons-->
          <div class="row">
            <div class="col"></div>
            <div class="col text-right">
              <div class="btn-wrapper">
                <div class="prev-button" *ngIf="!grsDetail.isFirst">
                  <a
                    href="javascript:void(0)"
                    (click)="slide(grsDetail.standard.id, false)"
                  >
                    <img src="/assets/images/back.svg" />
                  </a>
                </div>
                <div class="next-button" *ngIf="!grsDetail.isLast">
                  <a
                    href="javascript:void(0)"
                    (click)="slide(grsDetail.standard.id, true)"
                  >
                    <img src="/assets/images/next.svg" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <app-mcd-loader *ngIf="loading"></app-mcd-loader>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-3">
    <app-sideView-GloabalApprovedSolutions
      *ngIf="!gapLoading"
      [inputData]="this.grsDetail2"
    ></app-sideView-GloabalApprovedSolutions>
    <app-sideView-UpcomingExceptionCountries
      *ngIf="!gapLoading"
      [inputData]="this.grsDetail"
    ></app-sideView-UpcomingExceptionCountries>
  </div>
</div>
