import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { Category } from "../models/category";
import { LocationType } from "../models/location-type";
import { StandardService } from "../services/standard.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CreateNewNrbes } from "../models/createNewNrbes";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";
import { Router } from "@angular/router";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { CryptographyService } from "../services/cryptography.service";
import { StandardTypeEnum, UserRoleEnum } from "../core/constant";
import { Country } from "../models/country";

@Component({
  selector: "app-add-nrbes",
  templateUrl: "./add-nrbes.component.html",
  styleUrls: ["./add-nrbes.component.scss"],
})
export class AddNrbesComponent implements OnInit {
  error: boolean = false;
  errorMessage: string = "";
  startTime: Date | null = null;
  endTime: Date | null = null;
  newCreatedStandard: any = [];
  loading: boolean = false;
  allStandardsAdded: boolean = false;
  userRole: number;
  addNrbesForm: FormGroup;
  selected = "option2";
  categories: Category[] = [];
  locationTypes: LocationType[] = [];
  countries: Country[] = [];
  createNewNrbes: CreateNewNrbes;

  @Output() nrbesEditPopupEmit: EventEmitter<any> = new EventEmitter();
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private standardService: StandardService,
    private ngbModal: NgbModal,
    private router: Router,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm(): void {
    //debugger;
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    let locationTypesArray = [];
    let categoriesArray = [];
    if (this.userRole == UserRoleEnum.AOWAdmin) {
      this.addNrbesForm = this.fb.group({
        title: ["", Validators.required],
        note: [""],
        category: categoriesArray,
        locationTypes: locationTypesArray,
        categoryId: ["", Validators.required],
        locationTypeId: ["", Validators.required],
        standardDescription: ["", Validators.required],
        newrestaurantdescription: ["", Validators.required],
        additionalInformation: [""],
        effectiveFrom: [""],
        newRestaurantEffectiveFrom: [""],

        countryId: ["", Validators.required],
      });
    } else {
      this.addNrbesForm = this.fb.group({
        title: ["", Validators.required],
        note: [""],
        category: categoriesArray,
        locationTypes: locationTypesArray,
        categoryId: ["", Validators.required],
        locationTypeId: ["", Validators.required],
        standardDescription: ["", Validators.required],
        newrestaurantdescription: ["", Validators.required],
        additionalInformation: [""],
        countryId: [""],
        effectiveFrom: [""],
        newRestaurantEffectiveFrom: [""],
      });
    }

    this.loading = true;
    this.standardService
      .GetMasterDataForAddNrbes(
        this.cryptographyService.decryptData(this.storage.get("userRole")),
        this.cryptographyService.decryptData(this.storage.get("userId"))
      )
      .subscribe((data: any) => {
        this.countries = data.countries;
        this.categories = data.categories;
        this.locationTypes = data.locationTypes;

        if (this.locationTypes != null) {
          this.locationTypes
            .sort((firstItem, nextItem) =>
              firstItem.name.localeCompare(nextItem.name)
            )
            .forEach((element) => {
              locationTypesArray.push(
                this.fb.group({
                  id: new FormControl(element.id),
                  name: new FormControl(element.name),
                })
              );
            });
        }

        if (this.categories != null) {
          this.categories
            .sort((firstItem, nextItem) =>
              firstItem.name.localeCompare(nextItem.name)
            )
            .forEach((element) => {
              categoriesArray.push(
                this.fb.group({
                  id: new FormControl(element.id),
                  name: new FormControl(element.name),
                })
              );
            });
        }
        this.loading = false;
      });
  }

  reset() {
    this.addNrbesForm.controls.title.reset();
    this.addNrbesForm.controls.note.reset();
    this.addNrbesForm.controls.additionalInformation.reset();
    this.addNrbesForm.controls.standardDescription.reset();
    this.addNrbesForm.controls.newrestaurantdescription.reset();
    this.addNrbesForm.controls.locationTypeId.reset();
    this.addNrbesForm.controls.effectiveFrom.reset();
    this.addNrbesForm.controls.newRestaurantEffectiveFrom.reset();
    this.addNrbesForm.controls.categoryId.setValue(null);
    this.addNrbesForm.controls.locationTypeId.setValue(null);
  }

  setSelectedLocationType(locationType) {
    this.addNrbesForm.controls.locationTypeId.setValue(locationType.value);
  }

  setSelectedCategory(category) {
    this.addNrbesForm.controls.categoryId.setValue(parseInt(category.value));
  }

  onBuildDateString(date: string) {
    let partsArray = date.split("/");
    let buildedDate = "";
    partsArray.forEach((x) => {
      if (x.length == 1 || x.length == 2) {
        if (x.length == 1) {
          buildedDate += ("0" + x).slice(-2) + "/";
        } else {
          buildedDate += x + "/";
        }
      } else {
        buildedDate += x;
      }
    });
    return buildedDate;
  }

  close() {
    this.reset();
    this.nrbesEditPopupEmit.emit();

    this.activeModal.dismiss();
  }

  startTimer() {
    this.startTime = new Date();
    // alert("timer started");
  }
  endTimer() {
    if (!this.startTime) {
      console.error("Start time is not set.");
      return;
    }
    this.endTime = new Date();
    const timeDiff = this.endTime.getTime() - this.startTime.getTime();
    const seconds = timeDiff / 1000;
    // alert("timer stopped");

    return seconds;
  }

  submit() {
    this.startTimer();
    this.loading = true;
    this.newCreatedStandard = [];

    var nrbes = new CreateNewNrbes();
    nrbes.title = this.addNrbesForm.controls.title.value;
    nrbes.note = this.addNrbesForm.controls.note.value;
    nrbes.locationTypeId = this.addNrbesForm.controls.locationTypeId.value;
    nrbes.categoryId = parseInt(this.addNrbesForm.controls.categoryId.value);
    nrbes.description = this.addNrbesForm.controls.standardDescription.value;
    nrbes.additionalInformation =
      this.addNrbesForm.controls.additionalInformation.value;
    nrbes.newrestaurantDescription =
      this.addNrbesForm.controls.newrestaurantdescription.value;
    nrbes.effectiveFrom =
      this.addNrbesForm.controls.effectiveFrom.value != null
        ? this.onBuildDateString(
            new Date(this.addNrbesForm.controls.effectiveFrom.value)
              .toLocaleDateString("en-US")
              .replace(/\u200E/g, "")
          )
        : null;
    nrbes.newRestaurantEffectiveFrom =
      this.addNrbesForm.controls.newRestaurantEffectiveFrom.value != null
        ? this.onBuildDateString(
            new Date(
              this.addNrbesForm.controls.newRestaurantEffectiveFrom.value
            )
              .toLocaleDateString("en-US")
              .replace(/\u200E/g, "")
          )
        : null;

    nrbes.standardStatusId = 6;
    nrbes.standardTypeId = StandardTypeEnum.LocalStandard;
    nrbes.createdUserId = this.cryptographyService.decryptData(
      this.storage.get("userId")
    );
    nrbes.countryId = this.cryptographyService.decryptData(
      this.storage.get("countryId")
    );

    if (this.addNrbesForm.controls.countryId.value == "") {
      nrbes.countryId = this.cryptographyService.decryptData(
        this.storage.get("countryId")
      );
      this.standardService.createNewNrbes(nrbes).subscribe(
        (data: any) => {
          console.log("dataaa matrket", data);

          const modalRef = this.ngbModal.open(SuccessDialogComponent);
          if (data) {
            modalRef.componentInstance.message =
              "You have successfully added the standard.";
            this.newCreatedStandard.push({
              name: nrbes.title,
              totalTime: this.endTimer(),
              eventType: "NewStandardsPROD",
              status: "Created",
              standardType: "MarketStandard",
              session: JSON.parse(localStorage.getItem("NRBA_SESSION")).value,
              error: this.error,
              errorMessage: "No error",
            });
            console.log("Newlycretaedstandrd", this.newCreatedStandard);
            fetch(
              "https://insights-collector.newrelic.com/v1/accounts/4619405/events",
              {
                method: "POST",
                headers: {
                  "Api-Key": "58be58f2497b3d9d4ed19a4bd40bd275FFFFNRAL",
                },
                body: JSON.stringify(this.newCreatedStandard),
              }
            );
          }
          this.loading = false;
          modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(
            (receivedEntry) => {
              this.ngbModal.dismissAll();
              this.router.navigate(["/nrbeslist"]);
            }
          );
          this.reset();
        },
        (error) => {
          this.loading = false;

          console.log("Error occurred:", error);

          const modalRef = this.ngbModal.open(SuccessDialogComponent);
          modalRef.componentInstance.title = "Error";
          modalRef.componentInstance.message =
            "Some error occurred. Please try again after some time";
          modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
            this.ngbModal.dismissAll();
          });
          this.newCreatedStandard.push({
            name: nrbes.title,
            totalTime: this.endTimer(),
            eventType: "NewStandardsPROD",
            status: "Failed",
            standardType: "MarketStandard",
            session: JSON.parse(localStorage.getItem("NRBA_SESSION")).value,
            error: true,
            errorMessage: error.message,
          });
          console.log("Newlycretaedstandrd", this.newCreatedStandard);
          fetch(
            "https://insights-collector.newrelic.com/v1/accounts/4619405/events",
            {
              method: "POST",
              headers: {
                "Api-Key": "58be58f2497b3d9d4ed19a4bd40bd275FFFFNRAL",
              },
              body: JSON.stringify(this.newCreatedStandard),
            }
          );
        }
      );
    }

    //else if (this.addNrbesForm.controls.countryId.value == 0 ) {

    //  for (let country of this.countries) {
    //    if (country.id != 0) {

    //      nrbes.countryId = country.id;
    //      this.standardService.createNewNrbes(nrbes).subscribe((data: any) => {

    //      });
    //    }
    //  }

    //  const modalRef = this.ngbModal.open(SuccessDialogComponent);
    //  modalRef.componentInstance.message = 'You have successfully added the standard.'
    //  this.loading = false;
    //  modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe((receivedEntry) => {
    //    this.ngbModal.dismissAll();
    //    this.router.navigate(['/nrbeslist']);
    //  });
    //  this.reset();
    //}
    else {
      nrbes.countryId = this.addNrbesForm.controls.countryId.value;
      this.standardService.createNewNrbes(nrbes).subscribe((data: any) => {
        const modalRef = this.ngbModal.open(SuccessDialogComponent);
        this.newCreatedStandard.push({
          name: nrbes.title,
          totalTime: this.endTimer(),
          eventType: "NewStandardsPROD",
          status: "Created",
          standardType: "MarketStandard",
          session: JSON.parse(localStorage.getItem("NRBA_SESSION")).value,
          error: true,
          errorMessage: "Error is there",
        });
        console.log("Newlycretaedstandrd", this.newCreatedStandard);
        fetch(
          "https://insights-collector.newrelic.com/v1/accounts/4619405/events",
          {
            method: "POST",
            headers: {
              "Api-Key": "58be58f2497b3d9d4ed19a4bd40bd275FFFFNRAL",
            },
            body: JSON.stringify(this.newCreatedStandard),
          }
        );
        modalRef.componentInstance.message =
          "You have successfully added the .";
        this.loading = false;
        modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(
          (receivedEntry) => {
            this.ngbModal.dismissAll();
            this.router.navigate(["/nrbeslist"]);
          }
        );
        this.reset();
      });
    }
  }
}
