import { Component, OnInit } from "@angular/core";
import { StandardService } from "../services/standard.service";
@Component({
  selector: "app-latest-modified-standards",
  templateUrl: "./latest-modified-standards.component.html",
  styleUrls: ["./latest-modified-standards.component.scss"],
})
export class LatestModifiedStandardsComponent implements OnInit {
  latestModification: any;
  loading: boolean = false;
  standardRedirectMap: any = {
    1: "/global-recommendations-detail-view/",
    2: "/operations-standards-detail-view/",
    3: "/view-upcoming-standard-detail/",
    4: "/minimum-standard-detail-view/",
    5: "/view-equipments-standard-detail/",
    6: "/view-equipments-standard-detail/",
    7: "/operations-standards-detail-view/",
    8: "/operations-standards-detail-view/",
  };
  constructor(public standardService: StandardService) {}
  ngOnInit() {
    console.log(this.standardRedirectMap[1] + 1);
    this.loading = false;
    this.standardService
      .getLatestModifiedStandards()
      .subscribe((response: any) => {
        this.latestModification = [];
        console.log(response);
        let count = 0;
        for (let standard of response.standardDetailList) {
          if (count == 3) {
            break;
          }
          count += 1;
          this.latestModification.push({
            date:
              `${standard.modifiedUser ? "Modified on:" : "Created on:"} ` +
              standard.lastModifiedDate.substring(0, 10),
            description:
              standard.description.length <= 100
                ? standard.description
                : standard.description.substring(0, 100) + "...",
            title: standard.title,
            creator:
              standard.createdUser.name + " " + standard.createdUser.lastName,
            role: standard.createdUser.userRole.name,
            standardId: standard.id,
            standardTypeId: standard.standardTypeId,
            eventType: "GRSRecentUpdatesPROD",
            session: JSON.parse(localStorage.getItem("NRBA_SESSION")).value,
          });
        }
        console.log("LM", this.latestModification);
        fetch(
          "https://insights-collector.newrelic.com/v1/accounts/4619405/events",
          {
            method: "POST",
            headers: { "Api-Key": "58be58f2497b3d9d4ed19a4bd40bd275FFFFNRAL" },
            body: JSON.stringify(this.latestModification),
          }
        );
        // 58be58f2497b3d9d4ed19a4bd40bd275FFFFNRAL
        // 4619405
        this.loading = false;
      });
  }
}
