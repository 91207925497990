import { Component, OnInit, Inject } from "@angular/core";
import { Addendum } from "../models/addendum";
import { AddendumService } from "../services/addendum.service";
import { ActivatedRoute, Router } from "@angular/router";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import {
  UserRoleEnum,
  AddendumStatusEnum,
  Constant,
  StandardTypeEnum,
} from "../core/constant";
import { Standard } from "../models/standard";
import { StandardService } from "../services/standard.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { AddendumAttachment } from "../models/addendum-attachment";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CryptographyService } from "../services/cryptography.service";
import { SelectedStandard } from "../models/selected-standard";
import { FailModuleComponent } from "../fail-module/fail-module.component";

@Component({
  selector: "app-add-addendum",
  templateUrl: "./add-addendum.component.html",
  styleUrls: ["./add-addendum.component.scss"],
})
export class AddAddendumComponent implements OnInit {
  loading: boolean = false;
  addendumForm: FormGroup;
  userRole: number = 2;
  addendum: Addendum = new Addendum();
  error: string = "";
  invalidFile: boolean = false;
  invalidFileMessage: string = "";
  today = new Date();
  get addendumStatusEnum() {
    return AddendumStatusEnum;
  }
  get userRoleEnum() {
    return UserRoleEnum;
  }
  countryId: Array<number> = null;

  constructor(
    private addendumService: AddendumService,
    private standardService: StandardService,
    private cryptographyService: CryptographyService,
    private router: Router,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private ngbModal: NgbModal
  ) {
    this.addendum.standardId = parseInt(
      this.activeRoute.snapshot.paramMap.get("standardId")
    );
  }

  ngOnInit() {
    this.loading = true;
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    this.addendum.createdUserId = this.cryptographyService.decryptData(
      this.storage.get("userId")
    );
    this.createForm();
    if (
      this.cryptographyService.decryptData(
        this.storage.get("selectedCountryId")
      ) == 0
    ) {
      //when select all countires in country dorp down
      this.countryId = [
        this.cryptographyService.decryptData(this.storage.get("adCountryId")),
      ];
    } else {
      this.countryId = [
        this.cryptographyService.decryptData(
          this.storage.get("selectedCountryId")
        ),
      ];
    }

    this.getDraftAddendumIfExist(
      this.addendum.standardId,
      this.countryId
    ).subscribe((addendumObj) => {
      if (addendumObj.id) {
        this.addendum = addendumObj;
        this.addendumForm.controls.description.setValue(
          this.addendum.description
        );
        this.addendumForm.controls.note.setValue(this.addendum.note);
        this.addendumForm.controls.exceptionRenewalDate.setValue(
          this.addendum.exceptionRenewalDate
        );
      } else {
        this.getNewAddendumDetails(this.addendum.standardId);
      }
      this.loading = false;
    });
  }

  dateFilter = (d: Date | null): boolean => {
    const date = d || new Date();
    return date > this.today;
  };
  /**
   * Create form
   * @return void
   */
  createForm(): void {
    this.addendumForm = this.fb.group({
      description: ["", Validators.required],
      note: ["", Validators.required],
      exceptionRenewalDate: ["", Validators.required],
    });
  }

  getDraftAddendumIfExist(
    standardId: number,
    countryId: Array<number>
  ): Observable<Addendum> {
    return new Observable<Addendum>((observer) => {
      this.addendumService
        .getDraftBeforeRequestAddendumInformationByStandardIdAndCountryId(
          standardId,
          countryId
        )
        .subscribe((data: Addendum) => {
          observer.next(new Addendum(data));
        });
    });
  }

  /**
   * Get a new Addendum details
   * @return void
   */
  getNewAddendumDetails(standardId: number): void {
    this.loading = true;
    this.standardService
      .getStandardInfomationByStandardId(standardId)
      .subscribe((data: SelectedStandard) => {
        this.addendum = new Addendum();
        this.addendum.standard = new Standard(data.standard);
        this.addendum.standardId = this.addendum.standard.id;
        (error: string) => {
          this.loading = false;
          this.error = error;
        };
      });
  }

  submitTheAddendum() {
    if (this.addendumForm.valid) {
      this.addendum.description = this.addendumForm.controls.description.value;
      this.addendum.note = this.addendumForm.controls.note.value;
      this.addendum.exceptionRenewalDate =
        this.addendumForm.controls.exceptionRenewalDate.value;
      this.addendum.countryId = this.countryId;
      this.addendum.addendumStatusId = this.addendumStatusEnum.Requested;
      this.addendum.createdUserId = this.cryptographyService.decryptData(
        this.storage.get("userId")
      );
      let addendumCopy: any;
      addendumCopy = this.addendum;
      addendumCopy.countryIds = this.countryId;
      delete addendumCopy.countryId;
      this.saveAddendum(addendumCopy);
    }
  }

  saveAsADraft() {
    if (this.addendumForm.valid) {
      this.addendum.description = this.addendumForm.controls.description.value;
      this.addendum.note = this.addendumForm.controls.note.value;
      this.addendum.countryId = this.countryId;
      this.addendum.addendumStatusId =
        this.addendumStatusEnum.DraftBeforeRequest;
      this.addendum.createdUserId = this.cryptographyService.decryptData(
        this.storage.get("userId")
      );
      this.saveAddendum(this.addendum);
    }
  }

  onSelectAAddendumDocument(event) {
    this.invalidFileMessage = "";
    let selectedFile = event.target.files[0];
    this.invalidFile =
      this.validateFileType(selectedFile.name) ||
      this.validateFileSize(selectedFile);
    this.convertToBaseString(selectedFile).subscribe((result) => {
      this.addendum.addendumAttachment = [];
      this.addendum.addendumAttachment.push(new AddendumAttachment());
      this.addendum.addendumAttachment[0].fileName = selectedFile.name;
      this.addendum.addendumAttachment[0].addendumId = this.addendum.id;
      this.addendum.addendumAttachment[0].isRequestedFile = true;
      this.addendum.addendumAttachment[0].attachmentContentAsBase64 = result;
      event.target.value = null;
    });
  }

  convertToBaseString(selectedFile): Observable<any> {
    return new Observable<any>((observer) => {
      var fileReader = new FileReader();
      if (fileReader && selectedFile) {
        fileReader.readAsDataURL(selectedFile);
        fileReader.onload = function () {
          observer.next(fileReader.result.toString());
        };
      }
    });
  }

  onRemoveAddendumDocument() {
    this.addendum.addendumAttachment = [];
    this.invalidFile = false;
  }

  private saveAddendum(addendum: any) {
    this.loading = true;
    if (addendum.id) {
      this.addendum.modifiedUserId = this.cryptographyService.decryptData(
        this.storage.get("userId")
      );
      this.addendumService.updateAddendum(addendum).subscribe((result) => {
        this.loading = false;
        if (
          addendum.standard.standardTypeId ==
          StandardTypeEnum.GlobalEquipmentStandard
        ) {
          if (this.addendumService.isCommingFromNrbes) {
            this.router.navigate([
              "/view-all-national-equipment-standard-list",
            ]);
          } else {
            this.router.navigate(["/equipment-addendum-list"]);
          }
        } else {
          if (this.addendumService.isCommingFromNrbes) {
            this.router.navigate(["/nrbeslist"]);
          } else {
            this.router.navigate(["/addendum-list"]);
          }
        }
        (error: string) => {
          this.loading = false;
          this.error = error;
        };
      });
    } else {
      this.addendumService.addAddendum(addendum).subscribe((result) => {
        if (result == false) {
          this.loading = false;
          const modalRef = this.ngbModal.open(SuccessDialogComponent);
          modalRef.componentInstance.title = "Error";
          modalRef.componentInstance.message =
            "Exception already added for the standard.";

          modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
            this.ngbModal.dismissAll();
            if (
              addendum.standard.standardTypeId ==
              StandardTypeEnum.GlobalEquipmentStandard
            ) {
              this.router.navigate([
                "/view-all-national-equipment-standard-list",
              ]);
            } else {
              this.router.navigate(["/nrbeslist"]);
            }
          });
        } else {
          this.loading = false;
          if (
            addendum.standard.standardTypeId ==
            StandardTypeEnum.GlobalEquipmentStandard
          ) {
            this.router.navigate([
              "/view-all-national-equipment-standard-list",
            ]);
          } else {
            this.router.navigate(["/nrbeslist"]);
          }
          (error: string) => {
            this.loading = false;
            this.error = error;
          };
        }
      });
    }
  }

  onDownloadSelectedAddendumDocument(id) {
    if (id) {
      return this.standardService.getAttachmentUrl(id, true).subscribe(
        (response: any) => {
          const blob = new Blob([response], {
            type: response.type,
          });
          if (
            (window.navigator as any) &&
            (window.navigator as any).msSaveOrOpenBlob
          ) {
            (window.navigator as any).msSaveOrOpenBlob(blob);
            return;
          }
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        (error: any) => {
          const modalRef = this.ngbModal.open(FailModuleComponent);
          modalRef.componentInstance.errorMessage = error.error
            ? error.error.Message
            : error.message;
        }
      );
    } else {
      fetch(this.addendum.addendumAttachment[0].attachmentContentAsBase64)
        .then((res) => res.blob())
        .then((blob) => {
          if ((window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveBlob(
              blob,
              this.addendum.addendumAttachment[0].fileName
            );
          } else {
            var a = document.createElement("a");
            a.href =
              this.addendum.addendumAttachment[0].attachmentContentAsBase64;
            a.setAttribute(
              "download",
              this.addendum.addendumAttachment[0].fileName
            );
            a.click();
          }
        });
    }
  }

  validateFileType(fileName: string) {
    let fileTypeArr = ["docx", "doc"];
    if (fileName != null) {
      let fileNameArr = [];
      let splitedFileName = fileName.toLowerCase().split(".");
      splitedFileName.forEach(function (obj) {
        fileNameArr.push(obj);
      });
      fileNameArr = fileNameArr.reverse();
      if (!fileTypeArr.includes(fileNameArr[0])) {
        this.invalidFileMessage = "Invalid file type.";
        return true;
      }
    }
  }

  validateFileSize(file: any) {
    if (file.size > 10485760) {
      this.invalidFileMessage = "File size exceeds maximum limit 10 MB.";
      return true;
    }
  }
}
