<div class="lm-container" *ngIf="true">
  <mat-card class="mcd-latest-modification">
    <div class="latest-modification">Recent Updates</div>
    <mat-card-content
      *ngFor="let record of latestModification"
      style="cursor: pointer"
      routerLink="{{
        standardRedirectMap[record.standardTypeId] + record.standardId
      }}"
    >
      <div
        class="mcd-latest-modification-title"
        style="display: flex; flex-wrap: wrap"
      >
        {{ record.title }}
      </div>
      <div class="mcd-latest-modification-date">{{ record.date }}</div>
      <div
        class="mcd-latest-modification-description"
        style="margin-bottom: 3px"
      >
        {{ record.description }}
      </div>
      <!-- <div class="mcd-latest-modification-creator">{{ record.creator }}</div> -->
      <!-- <div class="mcd-latest-modification-role">{{ record.role }}</div> -->
      <hr
        *ngIf="record !== latestModification[latestModification.length - 1]"
      />
    </mat-card-content>
  </mat-card>
</div>
<app-mcd-loader *ngIf="loading"></app-mcd-loader>
