<mat-card class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title"
      >Obsolete Standard List</mat-card-title
    >
  </mat-card-header>
  <mat-card-content class="mcd-card-content">
    <div class="row filter-panel-row">
      <div class="col filter-panel">
        <app-location-type-filter
          [standardFiltration]="standardFiltrationForChipSet"
          [standardListType]="standardListType.Obsolete"
          (messageEvent)="receivedFilteration($event)"
        ></app-location-type-filter>
      </div>
      <div class="collpsible-panel">
        <button class="btn-collapse" mat-icon-button aria-label="Collapse">
          <i class="material-icons"> expand_less </i>
        </button>
        <button class="btn-expand" mat-icon-button aria-label="Expand">
          <mat-icon>expand_more</mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf="!loading">
      <div class="row">
        <div *ngIf="standards.length > 0" class="col-md-12">
          <div class="row">
            <div class="col-6">
              <mat-form-field>
                <mat-select
                  [value]="pageSize"
                  name="nOfRows"
                  #items
                  (selectionChange)="selectedItemsPerPageChange(items)"
                >
                  <mat-option
                    [value]="item.value"
                    *ngFor="let item of pageSizes"
                  >
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-6 float-right">
              <a
                class="float-right"
                href="javascript:void(0);"
                (click)="downloadExcel()"
                style="color: #0b59ca; text-decoration: none; cursor: pointer"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style="margin-bottom: 2px; margin-right: 2px"
                >
                  <path
                    d="M8.5 5V1H5.5V5H3.5L7 9L10.5 5H8.5Z"
                    stroke="#3C74E0"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.5 9V11.5C12.5 11.8978 12.342 12.2794 12.0607 12.5607C11.7794 12.842 11.3978 13 11 13H3C2.60218 13 2.22064 12.842 1.93934 12.5607C1.65804 12.2794 1.5 11.8978 1.5 11.5V9"
                    stroke="#3C74E0"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Download All
              </a>
            </div>
          </div>
          <table
            class="table nrbes-grid"
            matSort
            (matSortChange)="sortData($event)"
          >
            <thead class="thead-light">
              <tr>
                <th width="180" scope="col" mat-sort-header="title">Title</th>
                <th scope="col" width="110" mat-sort-header="model">Model</th>
                <th width="140" scope="col-2" mat-sort-header="equipment">
                  Equipment Type
                </th>
                <th scope="col-4" width="30%">Location Type</th>
                <th scope="col-4" width="20%">Effective Date</th>
                <th scope="col-4" width="20%">Obsolete Date</th>
                <th scope="col" width="80" *ngIf="canEditEquipmentStandard">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of standards" (click)="rowSelected(item)">
                <td class="title-cell">{{ item.title }}</td>
                <td class="location-cell">
                  {{ item.equipmentStandard.model }}
                </td>
                <td class="category-cell">
                  {{ item.equipmentStandard.equipmentType.name }}
                </td>
                <td class="category-cell">{{ getLocationNames(item) }}</td>
                <td class="category-cell">
                  Effective Date -
                  {{ item.effectiveFrom | date : "MM.dd.yyyy" }}
                </td>
                <td class="category-cell">
                  Obsolete Date -
                  {{
                    item.equipmentStandard.obsoleteDate | date : "MM.dd.yyyy"
                  }}
                </td>
                <td *ngIf="canEditEquipmentStandard">
                  <div class="row grid-actions hide-sm">
                    <div class="col-6">
                      <a
                        class="action-controls"
                        (click)="openDeletePopup(item, $event)"
                        routerLinkActive="active"
                        title="clear"
                        ><i class="material-icons"> clear </i></a
                      >
                    </div>
                    <div class="col-6">
                      <a
                        class="action-controls"
                        (click)="onEdit(item.id)"
                        routerLinkActive="active"
                        title="Edit"
                        href="javascript:void(0);"
                        ><i class="material-icons"> create </i></a
                      >
                    </div>
                  </div>

                  <a
                    (click)="actionMenuOpen($event)"
                    class="mcd-more-btn show-sm"
                    [matMenuTriggerFor]="menu"
                    href="javascript:void(0);"
                  >
                    <i class="material-icons">more_vert</i>
                  </a>
                  <mat-menu #menu="matMenu" overlapTrigger="false">
                    <span>
                      <button mat-menu-item (click)="onEdit(item.id)">
                        Edit
                      </button>
                      <button
                        mat-menu-item
                        (click)="closeMatMenuAndOpenDeleteModal(item, $event)"
                      >
                        Remove
                      </button>
                    </span>
                  </mat-menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" *ngIf="standards.length > 0">
        <div class="col">
          <ngb-pagination
            class="float-right"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="4"
            [rotate]="true"
            [ellipses]="true"
            [collectionSize]="collectionSize"
            (pageChange)="getAllEquipmentStandards()"
          >
          </ngb-pagination>
        </div>
      </div>
      <div class="row">
        <div *ngIf="standards.length == 0" class="col-md-9">
          Sorry you do not have any obsolete standard.
        </div>
      </div>
    </div>
    <app-mcd-loader *ngIf="loading"></app-mcd-loader>
  </mat-card-content>
</mat-card>
