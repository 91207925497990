<mat-card class="mcd-card">
  <mat-card-header class="mcd-card-header-dateModified">
    <mat-card-title
      class="mcd-card-title"
      style="font-weight: bold; margin-top: 10px"
      >View Obsolete Standard</mat-card-title
    >
    <mat-card-title class="mcd-card-title-dateModified"
      ><span style="font-size: 12px; margin-right: 5px">Last Modified</span>
      <span style="font-weight: bold; font-size: 12px; margin-right: 5px">{{
        grsDetail.standard.lastModifiedDate | date : "MMM dd,yyyy"
      }}</span>
      <!-- <span style="font-size: 12px; margin-right: 5px">by</span>
      <span style="font-weight: bold; font-size: 12px">{{
        name
      }}</span> -->
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="mcd-card-content grbes-view">
    <!-- Breadcrumb -->
    <div class="breadcrumbs clearfix">
      <div class="breadcrumbs-inner">
        <ul>
          <li>
            <a
              routerLink="/view-all-obsolete-standards-list"
              routerLinkActive="active"
              >All</a
            >
          </li>
          <li class="separator">/</li>
          <li class="active">
            {{ grsDetail.standard.equipmentStandard.model }}
          </li>
          <li style="float: right">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="margin-bottom: 2px; margin-right: 2px"
            >
              <path
                d="M8.5 5V1H5.5V5H3.5L7 9L10.5 5H8.5Z"
                stroke="#3C74E0"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.5 9V11.5C12.5 11.8978 12.342 12.2794 12.0607 12.5607C11.7794 12.842 11.3978 13 11 13H3C2.60218 13 2.22064 12.842 1.93934 12.5607C1.65804 12.2794 1.5 11.8978 1.5 11.5V9"
                stroke="#3C74E0"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <a
              style="color: #0b59ca; text-decoration: none; cursor: pointer"
              (click)="downloadExcel()"
              >Download
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- End Breadcrumb -->

    <div class="grbes-detail-view row" *ngIf="!loading">
      <div class="col-4 image-wrapper">
        <h1 class="d-md-none d-none">{{ grsDetail.standard.title }}</h1>
        <h2 class="d-md-none sub-title">
          {{ grsDetail.standard.equipmentStandard.equipmentClassTypeName }}
        </h2>
        <h3>{{ grsDetail.standard.equipmentStandard.model }}</h3>
        <div class="mcd-supplier-logo">
          <img
            [src]="
              grsDetail?.standard?.equipmentStandard?.supplier
                ?.supplierLogoAttachment?.image
            "
            class="img-fluid"
          />
        </div>
        <div class="mcd-equipment-image">
          <img
            [src]="
              grsDetail?.standard?.equipmentStandard?.imageAttachment?.image
            "
          />
        </div>
        <div class="mcd-eq-attachement">
          <a
            href="javascript:void(0);"
            *ngIf="
              grsDetail?.standard?.equipmentStandard?.equipmentAttachment.id !=
              null
            "
            (click)="
              onDownloadSelectedEquipmentAttachment(
                grsDetail.standard.equipmentStandard.equipmentAttachment
              )
            "
          >
            <img src="/assets/images/attachement.svg" /> Attachment</a
          >
        </div>
      </div>
      <div class="col-8 detail-wrapper">
        <div>
          <h1>{{ grsDetail.standard.title }}</h1>
          <h2 class="sub-title">
            {{ grsDetail.standard.equipmentStandard.equipmentType.name }}
          </h2>
          <div class="grbes-description">
            <h2>Description</h2>
            <p>{{ grsDetail.standard.description }}</p>
            <h2>Note</h2>
            <span [innerHTML]="grsDetail.standard.note"></span>
          </div>
          <div>
            <p>
              Obsolete Date -
              {{
                grsDetail.standard.equipmentStandard.obsoleteDate
                  | date : "MM.dd.yyyy"
              }}
            </p>
          </div>
          <div>
            <p>
              Effective Date -
              {{ grsDetail.standard.effectiveFrom | date : "MM.dd.yyyy" }}
            </p>
          </div>
          <div class="row">
            <div class="col">
              <div class="mcd-spec">
                <span class="icon float-left">
                  <img alt="location" src="/assets/images/location-gray.svg" />
                </span>
                <span class="label float-left">{{
                  getLocationNames(grsDetail)
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- End Dynamic Wrapper -->
        <!-- Next Previous Buttons-->
        <div class="row">
          <div class="col text-right">
            <div class="btn-wrapper">
              <div class="prev-button" *ngIf="!grsDetail.isFirst">
                <a
                  href="javascript:void(0)"
                  (click)="slide(grsDetail.standard.id, false)"
                >
                  <img src="/assets/images/back.svg"
                /></a>
              </div>
              <div class="next-button" *ngIf="!grsDetail.isLast">
                <a
                  href="javascript:void(0)"
                  href="javascript:void(0)"
                  (click)="slide(grsDetail.standard.id, true)"
                >
                  <img src="/assets/images/next.svg"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-mcd-loader *ngIf="loading"></app-mcd-loader>
  </mat-card-content>
</mat-card>
