<mat-card class="mcd-card">
  <mat-card-header class="mcd-card-header-dateModified">
    <mat-card-title
      class="mcd-card-title"
      style="font-weight: bold; margin-top: 10px"
      >View Equipment Standard</mat-card-title
    >
    <mat-card-title class="mcd-card-title-dateModified"
      ><span style="font-size: 12px; margin-right: 5px">Last Modified</span>
      <span style="font-weight: bold; font-size: 12px; margin-right: 5px">{{
        nrbesDetail.standard.lastModifiedDate | date : "MMM dd,yyyy"
      }}</span>
      <!-- <span style="font-size: 12px; margin-right: 5px">by</span>
      <span style="font-weight: bold; font-size: 12px">{{
        name
      }}</span> -->
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="mcd-card-content grbes-view">
    <!-- Breadcrumb -->
    <div class="breadcrumbs clearfix">
      <div class="breadcrumbs-inner">
        <ul>
          <li>
            <a
              routerLink="/view-all-national-equipment-standard-list"
              routerLinkActive="active"
              >All</a
            >
          </li>
          <li class="separator">/</li>
          <li class="active">
            {{ nrbesDetail.standard.equipmentStandard.model }}
          </li>
        </ul>
      </div>
    </div>
    <!-- End Breadcrumb -->

    <div class="grbes-detail-view row" *ngIf="!loading">
      <div class="col-4 image-wrapper">
        <h1 class="d-md-none d-none">{{ nrbesDetail.standard.title }}</h1>
        <h2 class="d-md-none sub-title">
          {{ nrbesDetail.standard.equipmentStandard.equipmentClassTypeName }}
        </h2>
        <div class="mcd-supplier-logo">
          <img
            [src]="
              nrbesDetail?.standard?.equipmentStandard?.supplier
                ?.supplierLogoAttachment?.image
            "
            class="img-fluid"
          />
        </div>
        <div class="mcd-equipment-image">
          <img
            [src]="
              nrbesDetail?.standard?.equipmentStandard?.imageAttachment?.image
            "
          />
        </div>
        <div class="mcd-eq-attachement">
          <a
            href="javascript:void(0);"
            *ngIf="
              nrbesDetail?.standard?.equipmentStandard?.equipmentAttachment
                .id != null
            "
            (click)="
              onDownloadSelectedEquipmentAttachment(
                nrbesDetail.standard.equipmentStandard.equipmentAttachment
              )
            "
          >
            <img src="/assets/images/attachement.svg" />
            {{
              nrbesDetail?.standard?.equipmentStandard?.equipmentAttachment
                .fileName
            }}</a
          >
        </div>
      </div>
      <div class="col-8 detail-wrapper">
        <div>
          <h1>{{ nrbesDetail.standard.title }}</h1>
          <h2 class="sub-title">
            {{ nrbesDetail.standard.equipmentStandard.equipmentType.name }}
          </h2>
          <h3>{{ nrbesDetail.standard.equipmentStandard.model }}</h3>
          <div class="grbes-description">
            <h2>Country</h2>
            <p>{{ nrbesDetail?.country?.name }}</p>
            <h2>Description</h2>
            <p>{{ nrbesDetail.standard.description }}</p>
            <h2>Note</h2>
            <div class="ql-container ql-snow" style="border-width: 0">
              <div
                class="ql-editor nrbes-description"
                [innerHTML]="nrbesDetail.standard.note"
              ></div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="mcd-spec">
                <span class="icon float-left">
                  <img alt="location" src="/assets/images/location-gray.svg" />
                </span>
                <span class="label float-left">{{
                  getLocationNames(nrbesDetail)
                }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              &nbsp;
              <button
                class="mcd-btn mcd-btn-primary"
                *ngIf="
                  nrbesDetail?.standard?.standardTypeId ==
                    standardTypeEnum.GlobalEquipmentStandard &&
                  !nrbesDetail.availabileAddendumId &&
                  userRole != 3
                "
                (click)="addAddendum(nrbesDetail.standard.id, $event)"
                (click)="addendumService.isCommingFromNrbes = true"
                mat-raised-button
                color="accent"
              >
                Add Addendum
              </button>

              <button
                class="mcd-btn mcd-btn-primary"
                (click)="addendumService.isCommingFromNrbes = true"
                *ngIf="
                  nrbesDetail?.standard?.standardTypeId ==
                    standardTypeEnum.GlobalEquipmentStandard &&
                  nrbesDetail.availabileAddendumId &&
                  userRole != 3
                "
                (click)="goToAddendum(nrbesDetail)"
                mat-raised-button
                color="accent"
              >
                Go to Addendum
              </button>

              <button
                class="mcd-btn mcd-btn-primary"
                (click)="addendumService.isCommingFromNrbes = true"
                *ngIf="
                  nrbesDetail?.standard?.standardTypeId ==
                    standardTypeEnum.GlobalEquipmentStandard &&
                  nrbesDetail.availabileAddendumId &&
                  userRole == 3 &&
                  nrbesDetail.addendumStatusId == addendumStatusEnum.Approved
                "
                (click)="goToAddendum(nrbesDetail)"
                mat-raised-button
                color="accent"
              >
                Go to Addendum
              </button>
              &nbsp;
            </div>
          </div>
        </div>
        <!-- End Dynamic Wrapper -->
        <!-- Next Previous Buttons-->
        <div class="row">
          <div class="col text-right">
            <div class="btn-wrapper">
              <div class="prev-button" *ngIf="!nrbesDetail.isFirst">
                <a
                  href="javascript:void(0)"
                  (click)="slide(nrbesDetail.id, false)"
                >
                  <img src="/assets/images/back.svg"
                /></a>
              </div>
              <div class="next-button" *ngIf="!nrbesDetail.isLast">
                <a
                  href="javascript:void(0)"
                  href="javascript:void(0)"
                  (click)="slide(nrbesDetail.id, true)"
                >
                  <img src="/assets/images/next.svg"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-mcd-loader *ngIf="loading"></app-mcd-loader>
  </mat-card-content>
</mat-card>
