import { Directive, ElementRef, Renderer2, AfterViewInit } from "@angular/core";
import { MatSelect } from "@angular/material/select";

@Directive({
  selector: "[appOpenSelect]",
})
export class OpenSelectDirective implements AfterViewInit {
  constructor(
    private matSelect: MatSelect,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    // Open the dropdown after view initialization
    setTimeout(() => {
      this.matSelect.open();
    }, 0);
  }
}
