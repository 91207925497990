import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { Country } from "../models/country";
import {
  UserRoleEnum,
  Constant,
  UserRoleUserOperation,
} from "../core/constant";
import { AdminService } from "../services/admin.service";
import { SupplierList } from "../models/supplier-list";
import { Supplier } from "../models/supplier";
import { CryptographyService } from "../services/cryptography.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { GlobalMarketSupplier } from "../models/global-market-supplier";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { Router } from "@angular/router";
import { MatMenuTrigger } from "@angular/material/menu";
import { DeleteDialogComponent } from "../delete-dialog/delete-dialog.component";
import { Observable } from "rxjs";
import { StandardService } from "../services/standard.service";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-view-all-national-supplier-list",
  templateUrl: "./view-all-national-supplier-list.component.html",
  styleUrls: ["./view-all-national-supplier-list.component.scss"],
})
export class ViewAllNationalSupplierListComponent implements OnInit {
  canEditNationalSupplier: boolean;
  canDeleteNationalSupplier: boolean;
  canAddNationalSupplier: boolean;

  loading: boolean = false;
  userRole: number;
  userId: number;
  selectedCountryId: Array<number> = [0];
  countries: Country[] = [];
  pageSizes: any[] = Constant.PageSizes;
  searchKey: string;

  page: number = 1;
  pageSize: number = 10;
  collectionSize: number = 0;

  supplierList: SupplierList = new SupplierList();
  error: string = "";
  modalOption: NgbModalOptions = {};

  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;

  get userRoleEnum() {
    return UserRoleEnum;
  }

  constructor(
    private adminService: AdminService,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private ngbModal: NgbModal,
    private router: Router,
    private standardService: StandardService
  ) {}

  ngOnInit() {
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    this.userId = this.cryptographyService.decryptData(
      this.storage.get("userId")
    );
    this.setUserPrivilages();
    this.selectedCountryId =
      this.userRole == this.userRoleEnum.MarketAdmin
        ? this.cryptographyService.decryptData(this.storage.get("countryId"))
        : null;
    this.getMasterData().subscribe(() => {
      if (this.selectedCountryId) {
        this.getSuppliers();
      }
    });
  }

  selectedItemsPerPageChange(event) {
    this.page = 1;
    this.pageSize = event.value;
    this.getSuppliers();
  }

  getSuppliers(): void {
    console.log("SELECTEED COUNTRY", this.selectedCountryId);
    this.loading = true;
    this.adminService
      .GetMarketSupplierList(
        (this.page - 1) * this.pageSize,
        this.pageSize,
        this.selectedCountryId,
        this.searchKey
      )
      .subscribe((data: SupplierList) => {
        this.supplierList.globalMarketSuppliers =
          data.globalMarketSuppliers.map((o) => new GlobalMarketSupplier(o));
        this.collectionSize = data.count;
        this.loading = false;
        (error: string) => {
          this.error = error;
          const modalRef = this.ngbModal.open(FailModuleComponent);
          this.error = error;
          modalRef.componentInstance.errorMessage = error;
          this.loading = false;
        };
      });
  }

  onAddEditNationalSupplier(supplierId: number = null) {
    if (!supplierId) {
      this.router.navigate(["/add-national-supplier"]);
    } else {
      this.router.navigate(["/add-national-supplier/" + supplierId]);
    }
  }

  closeMatMenuAndOpenDeleteModal(item, event) {
    this.trigger.closeMenu();
    setTimeout(() => {
      this.openDeleteModal(item, event);
    }, 100);
  }

  openDeleteModal(item, event) {
    event.stopPropagation();
    const modalRef = this.ngbModal.open(DeleteDialogComponent);
    modalRef.componentInstance.title =
      "Do you want to remove this supplier from the list? ";
    modalRef.componentInstance.deleteItem = item;
    modalRef.componentInstance.message =
      "All the dependencies will be removed along with this Supplier";
    this.trigger.closeMenu();
    modalRef.componentInstance.deletePopupEmit.subscribe((deleteItem) => {
      if (deleteItem != null) {
        let supplier = new Supplier(item.supplier);
        this.loading = true;
        this.adminService.DeleteSupplier(supplier).subscribe((data: any) => {
          this.loading = false;
          this.page = 1;
          this.getSuppliers();
        });
      }
      this.ngbModal.dismissAll();
    });
  }

  addSearchKey(searchKey) {
    this.searchKey = searchKey.value;
    this.page = 1;
    this.getSuppliers();
  }

  rowSelected(item) {
    this.standardService.fromLocationGRS = false;

    this.router.navigate([
      "/national-supplier-detail-view/" + item.supplier.id,
    ]);
  }

  selectCountry(event) {
    this.page = 1;
    this.selectedCountryId = event.value;
    this.storage.set(
      "selectedCountryId",
      this.cryptographyService.encryptData(this.selectedCountryId)
    );
    this.getSuppliers();
  }

  getMasterData(): Observable<any> {
    this.loading = true;
    return new Observable<any>((observer) => {
      this.standardService
        .GetMasterDataForAddNrbes(
          this.cryptographyService.decryptData(this.storage.get("userRole")),
          this.cryptographyService.decryptData(this.storage.get("userId"))
        )
        .subscribe((data: any) => {
          this.countries = data.countries;
          this.loading = false;
          observer.next();
        });
    });
  }

  setUserPrivilages() {
    if (!environment.skipAuthentication) {
      let userRoleUserOperation = this.storage.get("userRoleUserOperation")
        ? this.cryptographyService.decryptData(
            this.storage.get("userRoleUserOperation")
          )
        : this.storage.get("userRoleUserOperation");
      if (userRoleUserOperation) {
        this.canAddNationalSupplier =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.CanAddNationalSupplier
          ) !== -1;
        this.canEditNationalSupplier =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.CanEditNationalSupplier
          ) !== -1;
        this.canDeleteNationalSupplier =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.CanDeleteNationalSupplier
          ) !== -1;
      }
    } else {
      this.canAddNationalSupplier = true;
      this.canEditNationalSupplier = true;
      this.canDeleteNationalSupplier = true;
    }
  }
}
