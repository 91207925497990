<!--<div class="row">
  <div class="col-md-12">
    <div class="mcd-filter-tag-wrapper">
      <div class="mcd-tag-title">Filter By Location Type</div>
      <mat-checkbox *ngFor="let item of locationTypes" [checked]="setCheckedStateForCheckBoxes(1, item.id)"
        (change)="addFilter($event, item.id, 1)">{{item.name}}</mat-checkbox>
    </div>
  </div>
</div>-->
<div class="row">
  <div class="-md-12">
    <!--<mat-accordion>
      <mat-expansion-panel>-->

    <!--<mat-expansion-panel-header>
          <mat-panel-title>
            Filter By Location Type
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
        </mat-expansion-panel-header>-->

    <div class="mcd-filter-tag-wrapper">
      <div class="mcd-tag-title">Locations</div>
      <mat-checkbox
        *ngFor="let item of locationTypes"
        [checked]="setCheckedStateForCheckBoxes(1, item.id)"
        (change)="addFilter($event, item.id, 1)"
        >{{ item.name }}</mat-checkbox
      >
    </div>
    <!--</mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false"
                           [disabled]="subLcationTypes.length == 0">
        <mat-expansion-panel-header >
          <mat-panel-title>
            Filter By Sub Location Type
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
        </mat-expansion-panel-header>-->

    <!--<div class="mcd-filter-tag-wrapper last-filter-wrapper">  <div class="mcd-tag-title">Sub Locaitons</div>
          <mat-checkbox *ngFor="let item of subLcationTypes" [checked]="setCheckedStateForCheckBoxes(5, item.id)"
                        (change)="addFilter($event, item.id, 5)">{{item.name}}</mat-checkbox>
        </div>-->

    <!--</mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false"
                           [disabled]="equipmentTypes.length == 0">
        <mat-expansion-panel-header >
          <mat-panel-title>
            Filter By Equipment Type
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
        </mat-expansion-panel-header>-->
    <div class="row"></div>

    <div class="mcd-filter-tag-wrapper">
      <div class="mcd-tag-title">Equipment Types</div>
      <mat-checkbox
        *ngFor="let item of equipmentTypes"
        [checked]="setCheckedStateForCheckBoxes(4, item.id)"
        (change)="addFilter($event, item.id, 4)"
        >{{ item.name }}</mat-checkbox
      >
    </div>

    <!--</mat-expansion-panel>
  </mat-accordion>-->
  </div>
</div>

<!--<div class="row">
  <div *ngIf="!standardService.isGrs" class="col-md-6">
    <div class="mcd-filter-tag-wrapper">
      <div class="mcd-tag-title">Location</div>
      <mat-checkbox *ngFor="let item of locationTypes" [checked]="setCheckedStateForCheckBoxes(1, item.id)" (change)="addFilter($event, item.id, 1)">{{item.name}}</mat-checkbox>
    </div>
  </div>

  <div *ngIf="!standardService.isGrs" class="col-md-6 last-filter-wrapper">
    <div class="mcd-filter-tag-wrapper">
      <div class="mcd-tag-title">Sub Locaiton</div>
      <mat-checkbox *ngFor="let item of subLcationTypes" [checked]="setCheckedStateForCheckBoxes(5, item.id)" (change)="addFilter($event, item.id, 5)">{{item.name}}</mat-checkbox>
    </div>
  </div>

  <div *ngIf="!standardService.isGrs" class="col-md-12">
    <div class="mcd-filter-tag-wrapper">
      <div class="mcd-tag-title">Equipment Types</div>
      <mat-checkbox *ngFor="let item of equipmentTypes" [checked]="setCheckedStateForCheckBoxes(4, item.id)" (change)="addFilter($event, item.id, 4)">{{item.name}}</mat-checkbox>
    </div>
  </div>
</div>-->
