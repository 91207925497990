import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  Input,
} from "@angular/core";
import { LocationType } from "../models/location-type";
import { StandardService } from "../services/standard.service";
import { Category } from "../models/category";
import { StandardType } from "../models/standardType";
import { FilteringGuide } from "../models/filteringGuide";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { CryptographyService } from "../services/cryptography.service";

@Component({
  selector: "app-locations-filter",
  templateUrl: "./locations-filter.component.html",
  styleUrls: ["./locations-filter.component.scss"],
})
export class LocationsFilterComponent implements OnInit {
  locationTypes: LocationType[] = [];
  categories: Category[] = [];
  standardTypes: StandardType[] = [];
  filterList: FilteringGuide[] = [];
  filteringForCheckBox: FilteringGuide[] = [];

  @Output() messageEvent = new EventEmitter<Array<FilteringGuide>>();
  @Input() isNrbes: boolean;
  constructor(
    private standardService: StandardService,
    @Inject(LOCAL_STORAGE)
    public storage: WebStorageService,
    private cryptographyService: CryptographyService
  ) {}

  ngOnInit() {
    this.getMasterDataForFilteration();
    // this.getAllStandardTypes();
  }

  getMasterDataForFilteration() {
    this.standardService
      .GetMasterDataForAddNrbes(
        this.cryptographyService.decryptData(this.storage.get("userRole")),
        this.cryptographyService.decryptData(this.storage.get("userId"))
      )
      .subscribe((data: any) => {
        this.categories = data.categories;
        this.locationTypes = data.locationTypes;
      });
  }

  getAllStandardTypes(): void {
    this.standardService
      .getAllStandardTypes(!this.isNrbes, this.isNrbes)
      .subscribe((data: StandardType[]) => {
        this.standardTypes = data;
      });
  }

  addFilter($event, id, filterParam) {
    console.log("eventtt", $event, id);
    if (this.getFilter(this.isNrbes) != null) {
      this.filterList = this.getFilter(this.isNrbes);
    }
    if ($event.checked) {
      let filterGuide = this.filterList.find((i) => i.type === filterParam);
      if (filterGuide != undefined) {
        if (!filterGuide.ids.includes(id)) {
          filterGuide.ids.push(id);
        }
      } else {
        let guide = new FilteringGuide();
        guide.type = filterParam;
        guide.ids.push(id);
        this.filterList.push(guide);
      }
    } else {
      let selectedFilter = this.filterList.find((i) => i.type === filterParam);
      if (selectedFilter != undefined && selectedFilter.ids.includes(id)) {
        let index = selectedFilter.ids.indexOf(id);
        selectedFilter.ids.splice(index, 1);
      }
      if (selectedFilter.ids.length == 0) {
        let i = this.filterList.findIndex((x) => x.type === filterParam);
        this.filterList.splice(i, 1);
      }
    }
    this.setFilter(this.isNrbes, this.filterList);
    this.messageEvent.emit(this.filterList);
  }

  setCheckedStateForCheckBoxes(type, id) {
    this.filteringForCheckBox = this.getFilter(this.isNrbes);
    if (this.filteringForCheckBox != null) {
      let t = this.filteringForCheckBox.find((i) => i.type === type);
      if (t != undefined && t.ids.includes(id)) {
        return true;
      }
    }
  }

  getFilter(isNrbes: boolean) {
    switch (isNrbes) {
      case true: {
        return this.standardService.nrbesFiltrations.filterSet;
      }
      case false: {
        return this.standardService.allStandardsFiltration.filterSet;
      }
      default: {
        break;
      }
    }
  }

  setFilter(isNrbes: boolean, filterList: FilteringGuide[]) {
    switch (isNrbes) {
      case true: {
        return (this.standardService.nrbesFiltrations.filterSet = filterList);
      }
      case false: {
        return (this.standardService.allStandardsFiltration.filterSet =
          filterList);
      }
      default: {
        break;
      }
    }
  }
}
