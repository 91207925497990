<div class="row">
  <!-- <div class="col-6"> -->
  <div class="mcd-filter-tag-wrapper">
    <div class="mcd-tag-title">Location</div>
    <mat-checkbox
      *ngFor="let item of locationTypes"
      [checked]="setCheckedStateForCheckBoxes(1, item.id)"
      (change)="addFilter($event, item.id, 1)"
      >{{ item.name }}</mat-checkbox
    >
  </div>
  <!-- </div> -->
</div>
