<div class="outer-container" style="margin-top: 32px">
  <div class="" style="background-color: white !important">
    <div class="" style="display: flex">
      <mat-form-field class="search-container" [color]="searchColor">
        <mat-label>
          <div style="display: flex; justify-content: space-between">
            <span>Search for Global Standards</span>
          </div>
        </mat-label>

        <input
          matInput
          [(ngModel)]="searchQuery"
          (keyup.enter)="apply()"
          (keydown.enter)="apply()"
          class="search-input"
        />
      </mat-form-field>
      <svg
        style="height: 22px; width: 22px; color: #5e5e5e; margin-top: 18px"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100"
        height="100"
        viewBox="0 0 50 50"
      >
        <path
          d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"
          fill="#919191"
        ></path>
      </svg>
      <button
        mat-button
        [matMenuTriggerFor]="menu"
        class="filter-button"
        style="
          margin-top: 7px;
          height: 38px;
          margin-left: 9px;
          border-radius: 4px;
          border: 1px solid #d9d9d9;
        "
      >
        Filters
        <img id="sort-img" src="../../assets/images/sort-tool 1.png" />
      </button>
    </div>
  </div>
  <mat-menu #menu="matMenu" panelClass="search-filter-menu">
    <button
      mat-menu-item
      *ngFor="let option of filterOptions"
      [class.selected]="option.id === selectedOption.id"
      (click)="selectOption(option, $event)"
      style="display: flex; justify-content: space-between"
    >
      <div>
        {{ option.option }}
      </div>

      <div *ngIf="option.id === selectedOption.id">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M3.5 8.5L6 11L12.5 4.5"
            stroke="#FFBC0F"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </button>
    <button mat-menu-item (click)="apply()">Apply</button>
  </mat-menu>
  <mat-error *ngIf="invalidQuery" style="width: 100%; background: white"
    ><p style="width: 100%; text-align: center">
      Please enter a non-empty value.
    </p>
  </mat-error>
</div>
