import { Standard } from "./standard";

export class SelectedStandard {
  standard: any = new Standard();
  isFirst: boolean;
  isLast: boolean;
  isInNrbes: boolean;

  constructor(obj?: SelectedStandard) {
    if (obj) {
      this.standard = new Standard(obj.standard);
      this.isFirst = obj.isFirst;
      this.isLast = obj.isLast;
      this.isInNrbes = obj.isInNrbes;
    }
  }
}
