<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header
    class="mcd-card-header"
    style="display: flex; justify-content: space-between; padding-right: 12px"
  >
    <mat-card-title class="mcd-card-title">All Standards</mat-card-title>
    <a
      *ngIf="grses.length > 0 && isTabWiseDownloading"
      class="float-right"
      disabled
      style="color: #0b59ca; text-decoration: none; cursor: pointer"
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="margin-bottom: 2px; margin-right: 2px"
      >
        <path
          d="M8.5 5V1H5.5V5H3.5L7 9L10.5 5H8.5Z"
          stroke="#3C74E0"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.5 9V11.5C12.5 11.8978 12.342 12.2794 12.0607 12.5607C11.7794 12.842 11.3978 13 11 13H3C2.60218 13 2.22064 12.842 1.93934 12.5607C1.65804 12.2794 1.5 11.8978 1.5 11.5V9"
          stroke="#3C74E0"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      Downloading, Please wait...
    </a>
    <a
      *ngIf="grses.length > 0 && !isTabWiseDownloading"
      class="float-right"
      href="javascript:void(0);"
      (click)="downloadTabWiseExcel()"
      style="
        margin-top: 10px;
        color: #0b59ca;
        text-decoration: none;
        cursor: pointer;
      "
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="margin-bottom: 2px; margin-right: 2px"
      >
        <path
          d="M8.5 5V1H5.5V5H3.5L7 9L10.5 5H8.5Z"
          stroke="#3C74E0"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.5 9V11.5C12.5 11.8978 12.342 12.2794 12.0607 12.5607C11.7794 12.842 11.3978 13 11 13H3C2.60218 13 2.22064 12.842 1.93934 12.5607C1.65804 12.2794 1.5 11.8978 1.5 11.5V9"
          stroke="#3C74E0"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      Download All
    </a>
  </mat-card-header>
  <mat-card-content class="mcd-card-content">
    <div class="row filter-panel-row">
      <div class="col filter-panel">
        <app-view-all-standards-filter
          [isFromLocation]="isFromLocation"
          (messageEvent)="receivedFilteration($event)"
        ></app-view-all-standards-filter>
      </div>
      <div class="collpsible-panel">
        <button class="btn-collapse" mat-icon-button aria-label="Collapse">
          <i class="material-icons"> expand_less </i>
        </button>
        <button class="btn-expand" mat-icon-button aria-label="Expand">
          <mat-icon>expand_more</mat-icon>
        </button>
      </div>
    </div>

    <div class="table-wrapper">
      <div class="row">
        <div class="col-12 text-left" width="140">
          <mat-form-field>
            <input
              matInput
              placeholder="Search by Title or Description"
              value="{{ searchKey }}"
              (keyup.enter)="addSearchKey(key)"
              name="search"
              #key
            />
            <button
              mat-button
              *ngIf="searchKey"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="searchKey = ''; addSearchKey(searchKey)"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div *ngIf="grses.length > 0">
            <mat-form-field>
              <mat-select
                [value]="pageSize"
                name="nOfRows"
                #items
                (selectionChange)="selectedItemsPerPageChange(items)"
              >
                <mat-option [value]="item.value" *ngFor="let item of pageSizes">
                  {{ item.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-6 float-right">
          <!-- <button disabled> -->
          <a
            *ngIf="grses.length > 0 && isDownloading"
            class="float-right"
            disabled
            style="color: #0b59ca; text-decoration: none; cursor: pointer"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="margin-bottom: 2px; margin-right: 2px"
            >
              <path
                d="M8.5 5V1H5.5V5H3.5L7 9L10.5 5H8.5Z"
                stroke="#3C74E0"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.5 9V11.5C12.5 11.8978 12.342 12.2794 12.0607 12.5607C11.7794 12.842 11.3978 13 11 13H3C2.60218 13 2.22064 12.842 1.93934 12.5607C1.65804 12.2794 1.5 11.8978 1.5 11.5V9"
                stroke="#3C74E0"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Downloading, Please wait...
          </a>
          <a
            *ngIf="grses.length > 0 && !isDownloading"
            class="float-right"
            href="javascript:void(0);"
            (click)="downloadExcel()"
            style="
              margin-top: 10px;
              color: #0b59ca;
              text-decoration: none;
              cursor: pointer;
            "
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="margin-bottom: 2px; margin-right: 2px"
            >
              <path
                d="M8.5 5V1H5.5V5H3.5L7 9L10.5 5H8.5Z"
                stroke="#3C74E0"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.5 9V11.5C12.5 11.8978 12.342 12.2794 12.0607 12.5607C11.7794 12.842 11.3978 13 11 13H3C2.60218 13 2.22064 12.842 1.93934 12.5607C1.65804 12.2794 1.5 11.8978 1.5 11.5V9"
                stroke="#3C74E0"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Download
          </a>
          <!-- </button> -->
        </div>
      </div>
      <table
        matSort
        (matSortChange)="sortData($event)"
        *ngIf="grses.length > 0 && !getAppliedFilters().includes(4)"
        class="table nrbes-grid"
      >
        <thead class="thead-light">
          <tr>
            <th mat-sort-header="title" width="120" scope="col">Title</th>
            <th scope="col" width="110">Location</th>
            <th mat-sort-header="category" width="120" scope="col-2">
              Category
            </th>
            <th scope="col-2" width="40%" mat-sort-header="desc">
              Description
            </th>
            <!--<th scope="col" width="170">Type</th>-->
            <th
              scope="col"
              width="80"
              *ngIf="userRole == userRoleEnum.GlobalAdmin"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of grses" (click)="rowSelected(item)">
            <td class="title-cell">{{ item.title }}</td>
            <td class="location-cell">{{ getLocationNames(item) }}</td>
            <td class="category-cell">{{ item.category.name }}</td>
            <td class="description">
              <span
                title="{{ item.newRestaurantDescription }}"
                class="description"
              >
                {{ item.newRestaurantDescription }}
              </span>
            </td>

            <!--<td class="hide-sm"> {{item.standardType.name}}</td>-->
            <td *ngIf="userRole == userRoleEnum.GlobalAdmin">
              <div class="row grid-actions hide-sm">
                <div class="col-6">
                  <a
                    class="action-controls"
                    (click)="openDeleteModal(item, $event)"
                    routerLinkActive="active"
                    title="clear"
                    ><i class="material-icons"> clear </i></a
                  >
                </div>
                <div class="col-6">
                  <a
                    class="action-controls"
                    (click)="onEdit(item.id)"
                    routerLinkActive="active"
                    title="Edit"
                    href="javascript:void(0);"
                    ><i class="material-icons"> create </i></a
                  >
                </div>
              </div>

              <a
                (click)="actionMenuOpen($event)"
                class="mcd-more-btn show-sm"
                [matMenuTriggerFor]="menu"
                href="javascript:void(0);"
              >
                <i class="material-icons">more_vert</i>
              </a>
              <mat-menu #menu="matMenu" overlapTrigger="false">
                <span>
                  <button mat-menu-item (click)="onEdit(item.id)">Edit</button>
                  <button
                    mat-menu-item
                    (click)="closeMatMenuAndOpenDeleteModal(item, $event)"
                  >
                    Remove
                  </button>
                </span>
              </mat-menu>
            </td>
          </tr>
        </tbody>
      </table>
      <table
        matSort
        (matSortChange)="sortEquipment($event)"
        class="table nrbes-grid"
        *ngIf="getAppliedFilters().includes(4)"
      >
        <thead class="thead-light">
          <tr>
            <th mat-sort-header="equipmentType" width="110" scope="col-2">
              Equipment Type
            </th>
            <th mat-sort-header="supplier" width="110" scope="col-2">
              Supplier
            </th>
            <th mat-sort-header="title" scope="col-4" width="20%">Title</th>
            <th mat-sort-header="model" scope="col" width="110">Model</th>
            <th mat-sort-header="equipmentClass" scope="col" width="110">
              Equipment Class
            </th>
            <th scope="col-4" width="10%">Location Type</th>
            <th scope="col-4" width="15%">Obsolete Date</th>
            <th
              scope="col"
              width="80"
              *ngIf="userRole == userRoleEnum.GlobalAdmin"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of grses" (click)="rowSelected(item)">
            <td class="description">
              {{ item.equipmentStandard.equipmentType.name }}
            </td>
            <td class="description">
              {{ item.equipmentStandard.supplier.name }}
            </td>
            <td class="title-cell">{{ item.title }}</td>
            <td class="description">{{ item.equipmentStandard.model }}</td>
            <td class="description">
              {{ item.equipmentStandard.equipmentClassType.name }}
            </td>
            <td class="location-cell">{{ getLocationNames(item) }}</td>
            <td
              class="category-cell"
              *ngIf="
                item?.equipmentStandard?.obsoleteDate != null;
                else elseBlock
              "
            >
              Obsolete Date -
              {{ item.equipmentStandard.obsoleteDate | date : "MM.dd.yyyy" }}
            </td>
            <ng-template #elseBlock>
              <td class="category-cell">Obsolete Date Not Set</td></ng-template
            >

            <td *ngIf="userRole == userRoleEnum.GlobalAdmin">
              <div class="row grid-actions hide-sm">
                <div class="col-6">
                  <a
                    class="action-controls"
                    (click)="openDeletePopup(item, $event)"
                    routerLinkActive="active"
                    title="clear"
                    ><i class="material-icons"> clear </i></a
                  >
                </div>
                <div class="col-6">
                  <a
                    class="action-controls"
                    (click)="onEditEquipment(item.id)"
                    routerLinkActive="active"
                    title="Edit"
                    href="javascript:void(0);"
                    ><i class="material-icons"> create </i></a
                  >
                </div>
              </div>

              <a
                (click)="actionMenuOpen($event)"
                class="mcd-more-btn show-sm"
                [matMenuTriggerFor]="menu"
                href="javascript:void(0);"
              >
                <i class="material-icons">more_vert</i>
              </a>
              <mat-menu #menu="matMenu" overlapTrigger="false">
                <span>
                  <button mat-menu-item (click)="onEditEquipment(item.id)">
                    Edit
                  </button>
                  <button
                    mat-menu-item
                    (click)="closeMatMenuAndOpenDeleteModal(item, $event)"
                  >
                    Remove
                  </button>
                </span>
              </mat-menu>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="grses.length > 0" class="row">
        <div class="col">
          <ngb-pagination
            class="float-right"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="4"
            [rotate]="true"
            [ellipses]="true"
            [collectionSize]="collectionSize"
            (pageChange)="getStandards()"
          ></ngb-pagination>
        </div>
      </div>
    </div>

    <div *ngIf="grses.length == 0 && !searchKey">
      Sorry you do not have any minimum standard.
    </div>
    <div *ngIf="grses.length == 0 && searchKey">
      Your search -"{{ searchKey }}" - did not match any standard.&nbsp;&nbsp;<a
        href="javascript:void(0);"
        (click)="addSearchKey(''); getStandards()"
        ><span> Go Back</span></a
      >
    </div>
  </mat-card-content>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
