import { Category } from "./category";
import { StandardType } from "./standardType";
import { LocationType } from "../models/location-type";
import { SubLocationType } from "../models/sub-location-type";
import { EquipmentStandard } from "./equipment-standard";
import { DependentStandard } from "./dependent-standard";
import { Attachment } from "./attachment";

export class Standard {
  id: number = 0;
  standardTypeId: number;
  categoryId: number;
  locationTypeId: number;
  subLocationTypeId: number;
  standardLocation: [];
  title: string;
  description: string;
  newRestaurantDescription: string;
  note: string;
  additionalInformation: string;
  isDeleted: boolean;

  imageFileName: string;
  imageMime: string;
  imageAttachmentContent: any;
  imageAttachmentContentAsBase64: string;

  category: Category;
  standardType: StandardType;
  locationType: LocationType;
  subLocationType: SubLocationType;
  userId: number;
  equipmentStandard: EquipmentStandard = new EquipmentStandard();
  effectiveFrom: string;
  newRestaurantsEffectiveFrom: string;
  dependentStandard: DependentStandard = new DependentStandard();
  dependentStandards: DependentStandard[] = [];
  imageAttachment: Attachment = new Attachment();
  lastModifiedDate: any;
  get shortDescription() {
    return this.description.length > 60
      ? this.description.substring(0, 60) + "..."
      : this.description;
  }

  constructor(obj?: Standard) {
    if (obj) {
      this.id = obj.id;
      this.lastModifiedDate = obj.lastModifiedDate;
      this.standardTypeId = obj.standardTypeId;
      this.categoryId = obj.categoryId;
      this.locationTypeId = obj.locationTypeId;
      this.title = obj.title;
      this.standardLocation = obj.standardLocation;
      this.description = obj.description;
      this.newRestaurantDescription = obj.newRestaurantDescription;
      this.note = obj.note;
      this.additionalInformation = obj.additionalInformation;
      this.isDeleted = obj.isDeleted;
      this.category = new Category(obj.category);
      this.standardType = new StandardType(obj.standardType);
      this.locationType = new LocationType(obj.locationType);
      this.subLocationType = obj.subLocationType
        ? new SubLocationType(obj.subLocationType)
        : new SubLocationType();
      this.userId = obj.userId;
      this.equipmentStandard = obj.equipmentStandard
        ? new EquipmentStandard(obj.equipmentStandard)
        : new EquipmentStandard();
      this.effectiveFrom =
        obj.effectiveFrom == "0001-01-01T00:00:00" ? null : obj.effectiveFrom;
      this.newRestaurantsEffectiveFrom =
        obj.newRestaurantsEffectiveFrom == "0001-01-01T00:00:00"
          ? null
          : obj.newRestaurantsEffectiveFrom;
      this.locationType = new LocationType(obj.locationType);
      this.dependentStandard = new DependentStandard(obj.dependentStandard);
      this.dependentStandards = obj.dependentStandards
        ? obj.dependentStandards.map((o) => new DependentStandard(o))
        : null;
      this.subLocationTypeId = obj.subLocationTypeId;

      this.imageFileName = obj.imageFileName;
      this.imageMime = obj.imageMime;
      this.imageAttachmentContent = obj.imageAttachmentContent;
      this.imageAttachmentContentAsBase64 = obj.imageAttachmentContentAsBase64;
      this.imageAttachment = new Attachment(obj.imageAttachment);
    }
  }
}
