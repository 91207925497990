import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { Router, ActivatedRoute } from "@angular/router";
import { StandardService } from "../services/standard.service";
import { Standard } from "../models/standard";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import {
  StandardTypeEnum,
  StandardListType,
  UserRoleEnum,
  FilteringEnum,
  AllStandardsTabs,
} from "../core/constant";
import { CryptographyService } from "../services/cryptography.service";
import { LocationService } from "../services/location.service";
import { SelectedStandard } from "../models/selected-standard";
import { SelectedStandardDetail } from "../models/selected-standard-detail";
import { MatMenuTrigger } from "@angular/material/menu";
import { DeleteDialogComponent } from "../delete-dialog/delete-dialog.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { DatePipe } from "@angular/common";

declare var $: any;

@Component({
  selector: "app-view-all-global-recomendations-detail-view",
  templateUrl: "./view-all-global-recomendations-detail-view.component.html",
  styleUrls: ["./view-all-global-recomendations-detail-view.component.scss"],
  providers: [DatePipe],
})
export class GlobalRecomendationsDetailViewComponent implements OnInit {
  loading: boolean = false;
  gapLoading: boolean = true;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<img src="/assets/images/back.svg">',
      '<img src="/assets/images/next.svg">',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: true,
  };
  grsId: number = 0;
  notes: SafeHtml = "";
  additionalInformation: SafeHtml = "";
  grsDetail: SelectedStandardDetail = new SelectedStandardDetail();
  error: string = "";
  userRole: number = 2;
  get standardTypeEnum() {
    return StandardTypeEnum;
  }
  get userRoleEnum() {
    return UserRoleEnum;
  }
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;
  locationId: number = 0;
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    public standardService: StandardService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private ngbModal: NgbModal,
    private cryptographyService: CryptographyService,
    public locationService: LocationService,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe
  ) {
    this.grsId = parseInt(this.activeRoute.snapshot.paramMap.get("grsId"));
    this.locationId = parseInt(
      this.activeRoute.snapshot.paramMap.get("locationId")
    );
  }

  ngOnInit() {
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    this.getDetailByGrbesId(this.grsId);
    $(document).ready(() => {
      let objId;
      $(".image-map area")
        .mouseenter(function (e) {
          objId = $(this).attr("target");
          $(".main-image").css("opacity", "0");
          $(".main-image").css("z-index", "9999");
          $(objId).find("img").css("z-index", "2");
          $(objId).removeClass("d-none");
          $(objId).addClass("active");
          $(".chunk").removeClass("d-none");
          $(".tooltip-text span").text($(this).attr("alt"));
          $(".tooltip-text").css("left", e.pageX + 2);
          $(".tooltip-text").css("top", e.pageY + 2);
          $(".tooltip-text").fadeIn("slow");

          //   $('.main-image').fadeOut('slow');
        })
        .mouseleave(function (e) {
          $(".main-image").css("z-index", "1");
          $(".chunk").addClass("d-none");
          $(".chunk").removeClass("active");
          $(".main-image").css("opacity", "1");
          // $('.main-image').fadeTo('1');
          // $('.tooltip-text').hide();
        });
    });
  }
  
  getDetailByGrbesId(grsId: number) {
    //debugger;
    // this.loading = true;
    this.standardService
      .getGrbesDetailInfomationByStandardId(
        grsId,
        this.cryptographyService.decryptData(this.storage.get("countryId")),
        this.standardService.sharedLearingsFiltration.filterSet,
        StandardListType.AllStandards,
        this.standardService.sharedLearingsFiltration.searchKey,
        this.getAppliedFilters()
      )
      .subscribe((data: SelectedStandardDetail) => {
        var options = {
          /* … */
        };

        this.grsDetail = new SelectedStandardDetail(data);
        console.log("GAP", this.grsDetail);


        this.notes = this.sanitizer.bypassSecurityTrustHtml(
          this.grsDetail.standard.note
        );
        this.additionalInformation = this.sanitizer.bypassSecurityTrustHtml(
          this.grsDetail.standard.additionalInformation
        );

        this.loading = false;
        this.gapLoading = false;
        (error: string) => {
          this.error = error;
          this.loading = false;
        };
      });
  }
  navigateAccToType(type, id) {
    if (type == 4) {
      this.router.navigate(["/minimum-standard-detail-view/" + id]);
      return;
    }
    if (type == 8) {
      this.router.navigate(["/operations-standards-detail-view/" + id]);
      return;
    }
    if (type == 0) {
      this.router.navigate(["/view-equipments-standard-detail/" + id]);
      return;
    }
    if (type == 1 || type == 2) {
      this.router.navigate(["/global-recommendations-detail-view/" + id]);
      return;
    }
  }

  getAppliedFilters() {
    let idx = this.standardService.sharedLearingsFiltration.filterSet.findIndex(
      (i) => i.type == FilteringEnum.FilterByStandardType
    );
    if (idx == -1) return [];
    return this.standardService.sharedLearingsFiltration.filterSet[idx].ids;
  }

  slide(grsID: number, isForward: boolean) {
    this.loading = true;
    if (this.userRole != 2) {
      this.standardService
        .getNextPreviousGrsInfomationByCurrentStandardId(
          grsID,
          isForward,
          this.cryptographyService.decryptData(this.storage.get("countryId")),
          this.standardService.sharedLearingsFiltration.filterSet,
          StandardListType.AllStandards,
          this.standardService.sharedLearingsFiltration.searchKey,
          this.getAppliedFilters()
        )
        .subscribe((data: SelectedStandardDetail) => {
          this.navigateAccToType(
            data.standard.standardTypeId,
            data.standard.id
          );
          this.grsDetail = new SelectedStandardDetail(data);
          this.notes = this.sanitizer.bypassSecurityTrustHtml(
            this.grsDetail.standard.note
          );
          this.additionalInformation = this.sanitizer.bypassSecurityTrustHtml(
            this.grsDetail.standard.additionalInformation
          );
          this.loading = false;
          (error: string) => {
            this.error = error;
            this.loading = false;
          };
        });
    } else {
      this.standardService
        .getNextPreviousGrsInfomationByCurrentStandardId(
          grsID,
          isForward,
          this.cryptographyService.decryptData(this.storage.get("countryId")),
          this.standardService.sharedLearingsFiltration.filterSet,
          StandardListType.AllStandards,
          this.standardService.sharedLearingsFiltration.searchKey,
          this.getAppliedFilters()
        )
        .subscribe((data: SelectedStandardDetail) => {
          this.navigateAccToType(
            data.standard.standardTypeId,
            data.standard.id
          );

          this.grsDetail = new SelectedStandardDetail(data);
      
          this.notes = this.sanitizer.bypassSecurityTrustHtml(
            this.grsDetail.standard.note
          );
          this.additionalInformation = this.sanitizer.bypassSecurityTrustHtml(
            this.grsDetail.standard.additionalInformation
          );
          this.loading = false;
          (error: string) => {
            this.error = error;
            this.loading = false;
          };
        });
    }
  }

  addToNrbes(standardId, standardTypeId) {
    this.loading = true;
    this.standardService
      .addStandardToNrbes(
        standardId,
        this.cryptographyService.decryptData(this.storage.get("countryId")),
        standardTypeId === 0 ? 6 : 3,
        this.cryptographyService.decryptData(this.storage.get("userId"))
      )
      .subscribe(() => {
        this.loading = false;
        this.getDetailByGrbesId(this.grsDetail.standard.id);
      });
  }

  rowSelected(item: Standard): void {
    // this.standardService.fromLocationGRS = false;

    this.router.navigate(["/view-standard-detail/" + item.id]);
  }

  actionMenuOpen(event) {
    event.stopPropagation();
  }

  openDeleteModal(itemId, event) {
    event.stopPropagation();
    const modalRef = this.ngbModal.open(DeleteDialogComponent);
    modalRef.componentInstance.title =
      "Do you want to remove this standard from the list? ";
    modalRef.componentInstance.deleteItem = itemId;
    this.trigger.closeMenu();
    modalRef.componentInstance.deletePopupEmit.subscribe((deleteItemId) => {
      if (deleteItemId != null) {
        let grs = new SelectedStandard();
        grs.standard.id = deleteItemId;
        grs.standard.isDeleted = true;
        this.loading = true;
        this.standardService
          .removeGrbes(grs.standard)
          .subscribe((data: any) => {
            this.loading = false;
            this.getDetailByGrbesId(this.grsId);
          });
      }
      this.ngbModal.dismissAll();
    });
  }

  onEdit(id) {
    this.standardService.standardEditPreviousRoute =
      this.activeRoute.snapshot.routeConfig.path.split("/")[0] +
      "/" +
      this.grsDetail.standard.id;
    this.router.navigate(["/add-grs", id]);
  }
  downloadExcel() {
    this.standardService.downloadSingleStandardExcel(this.grsDetail, "global");
    return;
  }
}
