import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { StandardService } from "../services/standard.service";
import { Standard } from "../models/standard";
import { Router, ActivatedRoute } from "@angular/router";
import {
  Constant,
  StandardTypeEnum,
  StandardListType,
  UserRoleUserOperation,
} from "../core/constant";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DeleteDialogComponent } from "../delete-dialog/delete-dialog.component";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { FilteringGuide } from "../models/filteringGuide";
import { CryptographyService } from "../services/cryptography.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { StandardList } from "../models/standard-list";
import { SelectedStandard } from "../models/selected-standard";
import { environment } from "../../environments/environment";
import { EquipmentStandard } from "../models/equipment-standard";
import { EquipmentStandardService } from "../services/equipment -standard.service";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { debug } from "util";
import { StandardType } from "../core/constant";
import { Sort } from "@angular/material/sort";

@Component({
  selector: "app-standards-search",
  templateUrl: "./standards-search.component.html",
  styleUrls: ["./standards-search.component.scss"],
})
export class StandardsSearchComponent implements OnInit {
  query: string = "";
  standardType: number = 0;
  loading: boolean = false;
  appliedFilters: any = [];
  selected = "option2";
  grses: Standard[] = [];
  error: string = "";
  pageSizes: any[] = Constant.PageSizes;
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;

  page: number = 1;
  pageSize: number = 10;
  collectionSize: number = 0;
  userRole: number;
  get standardTypeEnum() {
    return StandardTypeEnum;
  }
  filterList: FilteringGuide[] = [];

  isPageEnabled: boolean = true;
  canEditEquipmentStandard: boolean;
  canDeleteEquipmentStandard: boolean;
  activeRoute: ActivatedRoute;
  constructor(
    private standardService: StandardService,
    private router: Router,
    private ngbModal: NgbModal,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private activatedRoute: ActivatedRoute,
    private cryptographyService: CryptographyService,
    private equipmentStandardService: EquipmentStandardService
  ) {}

  ngOnInit() {
    this.filterList = this.standardService.upcomingStandardFiltration.filterSet;
    this.pageSize = this.standardService.upcomingStandardFiltration.pageSize;
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    this.setUserPrivilages();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.query = params["query"];
      this.standardType = params["type"];
      this.page = 1;
      this.getStandards();
    });
  }

  selectedItemsPerPageChange(event) {
    this.page = 1;
    this.pageSize = event.value;
    this.getStandards();
  }

  getStandards(): void {
    console.log("Get standards");
    this.filterList = this.standardService.allStandardsFiltration.filterSet;
    console.log("selected filters", this.filterList);
    this.loading = true;
    this.standardService.searchAllFiltration.pageSize = this.pageSize;
    this.standardService
      .searchAllStandards(
        (this.page - 1) * this.pageSize,
        this.pageSize,
        Number(this.standardType),
        this.filterList,
        null,
        this.query
        // this.appliedFilters
        // this.type
      )
      .subscribe((data: StandardList) => {
        this.grses = data.standardDetailList.map((o) => new Standard(o));
        console.log("This.grsesss", this.grses);
        this.collectionSize = data.count;
        this.loading = false;
        (error: string) => {
          this.error = error;
          this.loading = false;
        };
      });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction === "" || !(this.grses.length > 0)) {
      return;
    }

    const isAsc = sort.direction === "asc";
    this.grses.sort((a, b) => {
      switch (sort.active) {
        case "title":
          return this.compare(a.title, b.title, isAsc);
        case "category":
          return this.compare(a.category.name, b.category.name, isAsc);
        case "desc":
          return this.compare(a.shortDescription, b.shortDescription, isAsc);
      }
    });
  }

  setUserPrivilages() {
    if (!environment.skipAuthentication) {
      let userRoleUserOperation = this.storage.get("userRoleUserOperation")
        ? this.cryptographyService.decryptData(
            this.storage.get("userRoleUserOperation")
          )
        : this.storage.get("userRoleUserOperation");
      if (userRoleUserOperation) {
        this.canEditEquipmentStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.CanEditEquipmentStandard
          ) !== -1;
        this.canDeleteEquipmentStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.CanDeleteEquipmentStandard
          ) !== -1;
      }
    }
  }
  rowSelected(item: Standard): void {
    switch (item.standardTypeId) {
      case 0:
        this.router.navigate(["/view-equipments-standard-detail/" + item.id]);
        break;

      case 1:
      case 2:
        this.router.navigate([
          "/global-recommendations-detail-view/" + item.id,
        ]);
        break;

      case 3:
        this.router.navigate(["/view-upcoming-standard-detail/" + item.id]);
        break;

      case 4:
        this.router.navigate(["/minimum-standard-detail-view/" + item.id]);
        break;

      case 5:
      case 6:
        this.router.navigate(["/view-equipments-standard-detail/" + item.id]);
        break;

      case 7:
      case 8:
        this.router.navigate(["/operations-standards-detail-view/" + item.id]);
        break;
    }
  }

  openDeleteModal(item, event) {
    event.stopPropagation();
    const modalRef = this.ngbModal.open(DeleteDialogComponent);
    modalRef.componentInstance.title =
      "Do you want to remove this standard from the list? ";
    modalRef.componentInstance.deleteItem = item;
    if (
      item.standardTypeId == StandardTypeEnum.GlobalMinimumRestaurantStandard ||
      item.standardTypeId == StandardTypeEnum.GlobalEquipmentStandard ||
      StandardTypeEnum.OperationsStandards
    ) {
      modalRef.componentInstance.message =
        "All the dependencies will be removed along with this standard";
    }
    this.trigger.closeMenu();
    modalRef.componentInstance.deletePopupEmit.subscribe((deleteItem) => {
      if (deleteItem != null) {
        if (item.standardTypeId == StandardTypeEnum.GlobalEquipmentStandard) {
          let equipmentStandard = new EquipmentStandard();
          equipmentStandard.id = deleteItem.equipmentStandard.id;
          equipmentStandard.isDeleted = true;
          this.loading = true;
          this.equipmentStandardService
            .updateEquipmentStandard(equipmentStandard)
            .subscribe((data: any) => {
              this.loading = false;
              this.page = 1;
              this.getStandards();
            });
        } else {
          let grs = new SelectedStandard();
          grs.standard.id = deleteItem.id;
          grs.standard.isDeleted = true;
          this.loading = true;
          this.standardService
            .removeGrbes(grs.standard)
            .subscribe((data: any) => {
              this.loading = false;
              this.page = 1;
              this.getStandards();
            });
        }
      }
      this.ngbModal.dismissAll();
    });
  }

  actionMenuOpen(event) {
    event.stopPropagation();
  }

  receivedFilteration($event) {
    this.isPageEnabled = false;
    this.filterList = $event;
    this.standardService.upcomingStandardFiltration.filterSet = this.filterList;
    this.pageSize = 10;
    this.page = 1;
    this.getStandards();
  }

  closeMatMenuAndOpenDeleteModal(item, event) {
    this.trigger.closeMenu();
    setTimeout(() => {
      this.openDeleteModal(item, event);
    }, 100);
  }

  onEdit(item) {
    this.standardService.standardEditPreviousRoute =
      this.activatedRoute.snapshot.routeConfig.path;
    if (item.standardType.id != this.standardTypeEnum.GlobalEquipmentStandard) {
      this.router.navigate(["/add-grs", item.id]);
    } else {
      this.router.navigate(["/edit-eq-standards-global", item.id]);
    }
  }

  downloadExcel() {
    this.standardService
      .downloadExcel(
        0,
        this.collectionSize,
        StandardListType.Upcoming,
        this.filterList,
        null,
        null
      )
      .subscribe(
        (response: any) => {
          let filename =
            "Upcoming_Standard_List " + new Date().toLocaleString();
          const blob = new Blob([response], {
            type: response.type,
          });
          if (
            (window.navigator as any) &&
            (window.navigator as any).msSaveOrOpenBlob
          ) {
            (window.navigator as any).msSaveOrOpenBlob(blob, filename);
            return;
          }
          var a = document.createElement("a");
          document.body.appendChild(a);

          let url = window.URL.createObjectURL(blob);

          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);

          //window.open(url);
        },
        (error: any) => {
          const modalRef = this.ngbModal.open(FailModuleComponent);
          modalRef.componentInstance.errorMessage = error.error
            ? error.error.Message
            : error.message;
        }
      );
  }
  getLocationNames(item): any {
    if (item.standardLocation && item.standardLocation.length > 0) {
      return item.standardLocation
        .map((loc) => loc.locationType.name)
        .join(", ");
    } else {
      return item.locationType.name;
    }
  }

  getStandardTypeName(id: number): string {
    let standardTYpeName: string = "";
    switch (id) {
      case 0:
        return (standardTYpeName = "Global Equipment Standard");

      case 1:
      case 2:
        return (standardTYpeName = "Global Approved Solution");

      case 4:
        return (standardTYpeName = "Global Building Standard");

      case 5:
        return (standardTYpeName = "Global Equipment Standard");

      case 6:
      case 8:
        return (standardTYpeName = "Global Operation Standard");
    }
  }
}
