export class AddendumFilterList {
  countryId: Array<number>;
  isRemoveDraftBeforeRequest: boolean;
  startingIndex: number;
  pageSize: number;
  userRoleId: number;
  isEquipmentAddendum: boolean;
  filterList: number[] = [];
  constructor(obj?: AddendumFilterList) {
    if (obj) {
      this.countryId = obj.countryId;
      this.isRemoveDraftBeforeRequest = obj.isRemoveDraftBeforeRequest;
      this.startingIndex = obj.startingIndex;
      this.pageSize = obj.pageSize;
      this.filterList = obj.filterList;
      this.userRoleId = obj.userRoleId;
      this.isEquipmentAddendum = obj.isEquipmentAddendum;
    }
  }
}
