import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import * as ExcelJS from "exceljs";
import { Standard } from "../models/standard";
import { LocationType } from "../models/location-type";
import { Category } from "../models/category";
import { StandardType } from "../models/standardType";
import { FilteringGuide } from "../models/filteringGuide";
import { FilterList } from "../models/filterList";
import { EquipmentClassType } from "../models/equipment-class-type";
import { StandardSearchView } from "../models/standard-search-view";
import { IdNameModel } from "../models/id-name-model";
import { NrbesFiltration } from "../models/nrbes-filtration";
import { LocationGrsList } from "../models/location-grs-list";
import { WishList } from "../models/wish-list";
import { StandardListType, MasterDataRequestedType } from "../core/constant";
import { SelectedStandard } from "../models/selected-standard";
import { StandardFiltration } from "../models/standard-filtration";
import { EquipmentType } from "../models/equipment-type";
import { Attachment } from "../models/attachment";
import { Location } from "@angular/common";
import { CryptographyService } from "./cryptography.service";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
// import { ResponseContentType, ResponseType } from "@angular/http";

@Injectable({
  providedIn: "root",
})
export class StandardService {
  fromLocationGRS: any = false;
  filterListFromLocGRS: any = [];
  defaultFilteration: any;
  allStandardsFiltration: FilterList = new FilterList();
  grsFiltration: FilterList = new FilterList();
  minimumStandardFiltration: FilterList = new FilterList();
  searchAllFiltration: FilterList = new FilterList();
  sharedLearingsFiltration: FilterList = new FilterList();
  equipmentStandardFiltration: FilterList = new FilterList();
  operationsStandardFiltration: FilterList = new FilterList();
  nationalEquipmentStandardFiltration: FilterList = new FilterList();
  obsoleteStandardFiltration: FilterList = new FilterList();
  locationGrsFiltration: FilterList = new FilterList();
  upcomingStandardFiltration: FilterList = new FilterList();
  nrbesFiltrations: FilterList = new FilterList();
  equipmentAddendumFiltration: FilterList = new FilterList();
  addendumFiltration: FilterList = new FilterList();
  standardEditPreviousRoute: string = "";
  isComingFromLocation: boolean = false;
  locationId: number = 0;
  isGrs: boolean;
  searchAllFilteration: any;

  searchKey: string;

  constructor(
    private http: HttpClient,
    private location: Location,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE)
    public storage: WebStorageService
  ) {}

  setIsGrs(isGrs: boolean) {
    this.isGrs = isGrs;
  }

  getLatestModifiedStandards(): Observable<any> {
    debugger;
    try {
      console.log(
        this.cryptographyService.decryptData(this.storage.get("countryId"))
      );
      console.log(
        this.cryptographyService.decryptData(this.storage.get("userRole"))
      );
    } catch (e) {
      console.log("ERR", e);
    }

    return this.http.post<any>("/api/Standard/GetLatestStandards", {
      countryId: this.cryptographyService.decryptData(
        this.storage.get("countryId")
      ), // get from local storage
      userRole: this.cryptographyService.decryptData(
        this.storage.get("userRole")
      ), // get from local storage
      standardCount: 10,
    });
  }

  getNrbesByCountryId(
    userId,
    countryId: Array<number>,
    startingIndex: number = 0,
    pageSize = 1,
    filterList: Array<FilteringGuide>,
    searchKey: string = "",
    isEquipment: boolean = false
  ): Observable<any> {
    let nrbesWithFilters = new FilterList();
    nrbesWithFilters.pageSize = pageSize;
    nrbesWithFilters.countryId = countryId;
    nrbesWithFilters.userId = userId;
    nrbesWithFilters.startingIndex = startingIndex;
    nrbesWithFilters.filterSet = filterList;
    nrbesWithFilters.searchKey = searchKey;
    nrbesWithFilters.IsEquipmentStandard = isEquipment;
    return this.http.post(
      "/api/standard/GetNrbesByCountryId",
      nrbesWithFilters
    );
  }

  addStandardToNrbes(standardId, countryId, standardTypeId, UserId) {
    let params = new HttpParams();
    params = params.append("standardId", standardId.toString());
    params = params.append("countryId", countryId.toString());
    params = params.append("standardTypeId", standardTypeId.toString());
    params = params.append("UserId", UserId.toString());

    return this.http.get("/api/standard/AddStandardToNrbes", {
      params: params,
    });
  }

  removeStandardFromNrbes(nrbesId) {
    let params = new HttpParams();
    params = params.append("nrbesId", nrbesId.toString());
    return this.http.get("/api/standard/removeStandardFromNrbes", {
      params: params,
    });
  }

  updateStandard(updateStandardCommand) {
    return this.http.post(
      "/api/standard/UpdateStandard",
      updateStandardCommand
    );
  }

  addStandard(standard: Standard) {
    try {
      return this.http.post("/api/standard/AddStandard", standard);
    } catch (e) {
      console.log(e);
    }
  }

  GetMasterDataForAddNrbes(userRole, userId) {
    let params = new HttpParams();
    params = params.append("userRole", userRole.toString());
    params = params.append("userId", userId.toString());
    return this.http.get("/api/standard/GetMasterDataForAddNrbes", {
      params: params,
    });
  }

  GetMasterDataForAddABUs(userRole, userId) {
    let params = new HttpParams();
    params = params.append("userRole", userRole.toString());
    params = params.append("userId", userId.toString());
    return this.http.get("/api/standard/GetMasterDataForAddABUs", {
      params: params,
    });
  }

  GetMasterDataForAddEditStandard(
    masterDataRequestedType: MasterDataRequestedType
  ) {
    let params = new HttpParams();
    params = params.append(
      "masterDataRequestedType",
      masterDataRequestedType.toString()
    );
    return this.http.get("/api/standard/GetMasterDataForAddEditStandard", {
      params: params,
    });
  }

  createNewNrbes(createNewNrbesCommand) {
    return this.http.post(
      "/api/standard/CreateNewNrbes",
      createNewNrbesCommand
    );
  }

  createNewABUStandard(createNewABUStandardCommand) {
    return this.http.post(
      "/api/standard/CreateNewABUStandards",
      createNewABUStandardCommand
    );
  }

  getAllStandards(
    startingIndex: number = 0,
    pageSize = 10,
    standardListType: StandardListType,
    filterList: Array<FilteringGuide>,
    countryId: Array<number> = null,
    searchKey: string = "",
    // type: number = 0,
    AppliedFilters: any = [1, 2, 3, 4]
  ): Observable<any> {
    let grbesWithFilters = new FilterList();
    grbesWithFilters.pageSize = pageSize;
    grbesWithFilters.startingIndex = startingIndex;
    grbesWithFilters.standardListType = standardListType;
    grbesWithFilters.countryId = countryId;
    grbesWithFilters.filterSet = filterList;
    grbesWithFilters.searchKey = searchKey;
    // grbesWithFilters.type = type;
    grbesWithFilters.AppliedFilters = AppliedFilters;
    return this.http.post("/api/standard/GetAllStandards", grbesWithFilters);
  }

  searchAllStandards(
    startingIndex: number = 0,
    pageSize = 10,
    standardType: number = 0,
    // standardListType: StandardListType,
    filterList: Array<FilteringGuide>,
    countryId: Array<number> = null,
    searchKey: string = ""
    // type: number = 0,
    // AppliedFilters: any = [1, 2, 3, 4]
  ): Observable<any> {
    let grbesWithFilters = new FilterList();
    let params = new HttpParams().set("standardType", standardType.toString());
    grbesWithFilters.pageSize = pageSize;
    grbesWithFilters.startingIndex = startingIndex;
    // grbesWithFilters.standardListType = standardListType;
    grbesWithFilters.countryId = countryId;
    grbesWithFilters.filterSet = filterList;
    grbesWithFilters.searchKey = searchKey;
    // grbesWithFilters.standardType = standardType;

    // grbesWithFilters.type = type;
    // grbesWithFilters.AppliedFilters = AppliedFilters;
    return this.http.post(
      "/api/standard/SearchAllStandards",
      grbesWithFilters,
      { params: params }
    );
  }

  getGlobalRecomendationsStandards(
    startingIndex: number = 0,
    pageSize = 10,
    standardListType: StandardListType,
    filterList: Array<FilteringGuide>,
    countryId: Array<number> = null,
    searchKey: string = ""
  ): Observable<any> {
    let grbesWithFilters = new FilterList();
    grbesWithFilters.pageSize = pageSize;
    grbesWithFilters.startingIndex = startingIndex;
    grbesWithFilters.standardListType = standardListType;
    grbesWithFilters.countryId = countryId;
    grbesWithFilters.filterSet = filterList;
    grbesWithFilters.searchKey = searchKey;
    return this.http.post(
      "/api/standard/GetGlobalRecomendations",
      grbesWithFilters
    );
  }

  GetLocationTypeByLocationTypeId(locationTypeId) {
    let params = new HttpParams();
    params = params.append("locationTypeId", locationTypeId);
    return this.http.get("/api/standard/GetLocationTypeByLocationTypeId", {
      params: params,
    });
  }
  //
  getStandardInfomationByStandardId(grsId): Observable<SelectedStandard> {
    let params = new HttpParams();
    params = params.append("standardId", grsId.toString());
    return this.http.get<SelectedStandard>(
      "/api/standard/GetGrbesInfomationByStandardId",
      { params: params }
    );
  }

  getGrbesDetailInfomationByStandardId(
    grsId,
    countryId: Array<number>,
    filterList: Array<FilteringGuide>,
    standardListType: StandardListType,
    searchKey,
    AppliedFilters: any = [1, 2, 3, 4]
  ): Observable<SelectedStandard> {
    if (AppliedFilters.length == 0) {
      if (this.location.path().includes("operations-standards-detail-view")) {
        AppliedFilters = [2];
      }
      if (this.location.path().includes("minimum-standard-detail-view")) {
        AppliedFilters = [3];
      }
      if (this.location.path().includes("global-recommendations-detail-view")) {
        AppliedFilters = [1];
      }
      if (this.location.path().includes("view-equipments-standard-detail")) {
        AppliedFilters = [4];
      }
    }
    let grsFiltration = new StandardFiltration();
    grsFiltration.standardId = grsId;
    grsFiltration.countryId = countryId;
    grsFiltration.filterSet = filterList;
    grsFiltration.standardListType = standardListType;
    grsFiltration.searchkey = searchKey;
    grsFiltration.AppliedFilters = AppliedFilters;
    if (this.fromLocationGRS == true) {
      grsFiltration.AppliedFilters = [2, 3];
      grsFiltration.filterSet = this.filterListFromLocGRS;
    }

    return this.http.post<SelectedStandard>(
      "/api/standard/GetGrbesDetailInfomationByStandardId",
      grsFiltration
    );
  }

  getFutureStandardDetailInfomationByStandardId(
    grsId,
    countryId,
    filterList: Array<FilteringGuide>
  ): Observable<SelectedStandard> {
    let grsFiltration = new StandardFiltration();
    grsFiltration.standardId = grsId;
    grsFiltration.countryId = countryId;
    grsFiltration.filterSet = filterList;
    return this.http.post<SelectedStandard>(
      "/api/standard/GetFutureStandardDetailInfomationByStandardId",
      grsFiltration
    );
  }

  getCurrentEquipmentRelatedStandards(equipmentId: number) {
    let params = new HttpParams();
    params = params.append("equipmentId", equipmentId.toString());
    return this.http.get<IdNameModel[]>(
      "/api/standard/GetCurrentEquipmentRelatedStandards",
      { params: params }
    );
  }

  getNrbesDetailInfomationByNrbesId(
    nrbesId,
    countryId: Array<number>,
    filterList: Array<FilteringGuide>,
    searchKey: string = "",
    isEquipmentStandard: boolean = false
  ): Observable<SelectedStandard> {
    let nrbesFiltration = new NrbesFiltration();
    nrbesFiltration.nrbesId = nrbesId;
    nrbesFiltration.countryId = countryId;
    nrbesFiltration.filterSet = filterList;
    nrbesFiltration.searchKey = searchKey;
    nrbesFiltration.isEquipmentStandard = isEquipmentStandard;
    return this.http.post<SelectedStandard>(
      "/api/standard/GetNrbesDetailInfomationByNrbesId",
      nrbesFiltration
    );
  }

  getNextPreviousNrbsInfomationByCurrentNrbesId(
    nrbesId,
    isForward,
    countryId: Array<number>,
    filterList: Array<FilteringGuide>,
    searchKey: string = "",
    isEquipmentStandard: boolean = false
  ): Observable<SelectedStandard> {
    let nrbesFiltration = new NrbesFiltration();
    nrbesFiltration.nrbesId = nrbesId;
    nrbesFiltration.isForward = isForward;
    nrbesFiltration.countryId = countryId;
    nrbesFiltration.filterSet = filterList;
    nrbesFiltration.searchKey = searchKey;
    nrbesFiltration.isEquipmentStandard = isEquipmentStandard;

    return this.http.post<SelectedStandard>(
      "/api/standard/GetNextPreviousNrbesInfomationByCurrentNrbesId",
      nrbesFiltration
    );
  }

  getNextPreviousGrsInfomationByCurrentStandardId(
    grsId,
    isForward,
    countryId: Array<number>,
    filterList: Array<FilteringGuide>,
    standardListType: StandardListType,
    searchKey: string = "",
    AppliedFilters = [1, 2, 3, 4]
  ): Observable<any> {
    if (AppliedFilters.length == 0) {
      if (AppliedFilters.length == 0) {
        if (this.location.path().includes("operations-standards-detail-view")) {
          AppliedFilters = [2];
        }
        if (this.location.path().includes("minimum-standard-detail-view")) {
          AppliedFilters = [3];
        }
        if (
          this.location.path().includes("global-recommendations-detail-view")
        ) {
          AppliedFilters = [1];
        }
        if (this.location.path().includes("view-equipments-standard-detail")) {
          AppliedFilters = [4];
        }
      }
    }
    let grsFiltration = new StandardFiltration();
    grsFiltration.standardId = grsId;
    grsFiltration.isForward = isForward;
    grsFiltration.countryId = countryId;
    grsFiltration.filterSet = filterList;
    grsFiltration.standardListType = standardListType;
    grsFiltration.searchkey = searchKey;
    grsFiltration.AppliedFilters = AppliedFilters;
    if (this.fromLocationGRS == true) {
      grsFiltration.AppliedFilters = [2, 3];
      grsFiltration.filterSet = this.filterListFromLocGRS;
    }
    return this.http.post<SelectedStandard>(
      "/api/standard/GetNextPreviousGrsInfomationByCurrentStandardId",
      grsFiltration
    );
  }

  getNextPreviousFutureStandardInfomationByCurrentStandardId(
    grsId,
    isForward,
    countryId: Array<number>,
    filterList: Array<FilteringGuide>,
    searchKey: string = ""
  ): Observable<SelectedStandard> {
    let grsFiltration = new StandardFiltration();
    grsFiltration.standardId = grsId;
    grsFiltration.isForward = isForward;
    grsFiltration.countryId = countryId;
    grsFiltration.filterSet = filterList;
    grsFiltration.searchkey = searchKey;
    return this.http.post<SelectedStandard>(
      "/api/standard/GetNextPreviousFutureStandardInfomationByCurrentStandardId",
      grsFiltration
    );
  }

  removeGrbes(standard: Standard) {
    return this.http.post("/api/standard/UpdateStandard", standard);
  }

  getAllLocationTypes(): Observable<LocationType[]> {
    return this.http.get<LocationType[]>(
      "/api/standard/GetAllLocationTypesWithStandards"
    );
  }

  getEquipmentStandardChipset(filterList: FilterList): Observable<any> {
    return this.http.post<LocationType[]>(
      "/api/standard/GetEquipmentStandardChipset",
      filterList
    );
  }

  getAllStandardTypes(isGrs, isUpcoming): Observable<StandardType[]> {
    let params = new HttpParams();
    params = params.append("isGrs", isGrs.toString());
    params = params.append("isUpcoming", isUpcoming.toString());
    return this.http.get<StandardType[]>("/api/standard/GetAllStandardTypes", {
      params: params,
    });
  }

  getAllCategories(): Observable<Category[]> {
    return this.http.get<Category[]>("/api/standard/GetAllCategories");
  }

  getAllEquipmentClassTypes(): Observable<EquipmentClassType[]> {
    return this.http.get<EquipmentClassType[]>(
      "/api/standard/GetAllEquipmentClassTypes"
    );
  }

  getAllEquipmentTypesByLocationId(locationId): Observable<EquipmentType[]> {
    let params = new HttpParams();
    params = params.append("locationId", locationId);
    return this.http.get<EquipmentType[]>(
      "/api/standard/GetAllEquipmentTypesByLocationId",
      { params: params }
    );
  }

  getAllEquipmentTypesByLocationIdList(
    locationIdList
  ): Observable<EquipmentType[]> {
    let params = new HttpParams();
    params = params.append("locationIdList", locationIdList);
    return this.http.get<EquipmentType[]>(
      "/api/standard/getAllEquipmentTypesByLocationIdList",
      { params: params }
    );
  }

  // here we also have to get the upcoming standards
  getMinimumStandardsBySearchKey(
    searchKey: string
  ): Observable<StandardSearchView[]> {
    let params = new HttpParams();
    params = params.append("searchKey", searchKey);
    return this.http.get<StandardSearchView[]>(
      "/api/standard/GetMinimumStandardsBySearchKey",
      { params: params }
    );
  }

  getAllStandardsByLocationAndCategory(
    startingIndex: number = 0,
    pageSize = 10,
    filterList: Array<FilteringGuide>,
    searchKey: string = ""
  ): Observable<LocationGrsList> {
    let grbesWithFilters = new FilterList();
    grbesWithFilters.pageSize = pageSize;
    grbesWithFilters.startingIndex = startingIndex;
    grbesWithFilters.filterSet = filterList;
    grbesWithFilters.searchKey = searchKey;
    return this.http.post<LocationGrsList>(
      "/api/standard/getAllStandardsByLocationAndCategory",
      grbesWithFilters
    );
  }

  getAllFutureStandards(
    startingIndex: number = 0,
    pageSize = 10
  ): Observable<WishList> {
    let wishListWithFilters = new FilterList();
    wishListWithFilters.pageSize = pageSize;
    wishListWithFilters.startingIndex = startingIndex;
    return this.http.post<WishList>(
      "/api/standard/GetAllFutureStandards",
      wishListWithFilters
    );
  }

  getAttachmentUrl(id, isAddendum) {
    //debugger;
    let params = new HttpParams();
    params = params.append("id", id);
    params = params.append("isAddendum", isAddendum);
    return this.http.get("/api/standard/GetAttachmentUrl", {
      params: params,
      responseType: "blob",
    });
  }

  downloadExcel(
    startingIndex: number = 0,
    pageSize = 10,
    standardListType: StandardListType,
    filterList: Array<FilteringGuide>,
    countryId: Array<number> = null,
    searchKey: string = ""
  ): Observable<any> {
    let grbesWithFilters = new FilterList();
    grbesWithFilters.pageSize = pageSize;
    grbesWithFilters.startingIndex = startingIndex;
    grbesWithFilters.standardListType = standardListType;
    grbesWithFilters.countryId = countryId;
    grbesWithFilters.filterSet = filterList;
    grbesWithFilters.searchKey = searchKey;
    return this.http.post(
      "/api/standard/GetExcelStandardDocumentUrl",
      grbesWithFilters,
      { responseType: "blob" }
    );
  }

  downloadTabWiseExcel(
    AppliedFilters: number[],
    collectionSize: any,
    filterList: any,
    searchkey: any
  ) {
    let grbesWithFilters = new FilterList();
    grbesWithFilters.pageSize = 1000;
    grbesWithFilters.startingIndex = 0;
    grbesWithFilters.standardListType = StandardListType.AllStandards;
    grbesWithFilters.countryId = null;
    grbesWithFilters.filterSet = filterList;
    grbesWithFilters.searchKey = searchkey;
    grbesWithFilters.AppliedFilters = AppliedFilters;
    return this.http.post(
      "/api/standard/GetExcelAllStandardDocumentUrl",
      grbesWithFilters,
      { responseType: "blob" }
    );
  }

  downloadAllExcel(
    AppliedFilters: number[],
    collectionSize: any,
    filterList: any,
    searchkey: any
  ) {
    let grbesWithFilters = new FilterList();
    grbesWithFilters.pageSize = collectionSize;
    grbesWithFilters.startingIndex = 0;
    grbesWithFilters.standardListType = StandardListType.AllStandards;
    grbesWithFilters.countryId = null;
    grbesWithFilters.filterSet = filterList;
    grbesWithFilters.searchKey = searchkey;
    grbesWithFilters.AppliedFilters = AppliedFilters;
    return this.http.post(
      "/api/standard/GetExcelStandardDocumentUrl",
      grbesWithFilters,
      { responseType: "blob" }
    );
  }

  async downloadSingleStandardExcel(
    data: any,
    standardType: string
  ): Promise<any> {
    // standardTypes can be: equipment, building, operations, global, upcoming, BU, market, obsolete

    // we will extract the required data depending upon type of standard in the dataArray
    let dataArray = [];
    if (standardType == "equipment") {
      const title: any = data.standard.equipmentStandard.title || "none";
      const description: any = data.standard.description || "none";
      const equipmentType: any =
        data.standard.equipmentStandard.equipmentType.name || "none";
      const equipmentClass: any =
        data.standard.equipmentStandard.equipmentClassType.name || "none";
      const model: any = data.standard.equipmentStandard.model || "none";
      const note: any = data.standard.note || "none";
      const effectiveDate: any = data.standard.effectiveFrom || "none";
      const obsoleteDate: any =
        data.standard.equipmentStandard.obsoleteDate || "none";
      const location: any =
        data.standard.equipmentStandard.locationType.name || "none";

      dataArray = [
        [
          "title",
          "description",
          "equipmentType",
          "equipmentClass",
          "model",
          "note",
          "effective date",
          "obsolete date",
          "location",
        ],
        [
          title,
          description,
          equipmentType,
          equipmentClass,
          model,
          note,
          effectiveDate,
          obsoleteDate,
          location,
        ],
      ];
    }

    if (standardType == "building") {
      const category = data.standard.category.name;
      const title = data.standard.title;
      const description = data.standard.description;
      const effectiveDate = data.standard.effectiveFrom;
      const newRestarauntEffectiveFrom =
        data.standard.newRestarauntEffectiveFrom;
      const note = data.standard.note;
      const additionalInformation = data.standard.additionalInformation;

      dataArray = [
        [
          "category",
          "title",
          "description",
          "effective date",
          "new restaraunt effective date",
          "note",
          "additional info",
        ],
        [
          category,
          title,
          description,
          effectiveDate,
          newRestarauntEffectiveFrom,
          note,
          additionalInformation,
        ],
      ];
    }

    if (standardType == "operations") {
      const category = data.standard.category.name;
      const title = data.standard.title;
      const description = data.standard.description;
      const effectiveDate = data.standard.effectiveFrom;
      const newRestarauntEffectiveFrom =
        data.standard.newRestarauntEffectiveFrom;
      const note = data.standard.note;
      const additionalInformation = data.standard.additionalInformation;

      dataArray = [
        [
          "category",
          "title",
          "description",
          "effective date",
          "new restaraunt effective date",
          "note",
          "additional info",
        ],
        [
          category,
          title,
          description,
          effectiveDate,
          newRestarauntEffectiveFrom,
          note,
          additionalInformation,
        ],
      ];
    }

    if (standardType == "global") {
      const category = data.standard.category.name;
      const title = data.standard.title;
      const description = data.standard.description;
      const effectiveDate = data.standard.effectiveFrom;
      const newRestarauntEffectiveFrom =
        data.standard.newRestarauntEffectiveFrom;
      const note = data.standard.note;
      const additionalInformation = data.standard.additionalInformation;

      dataArray = [
        [
          "category",
          "title",
          "description",
          "effective date",
          "new restaraunt effective date",
          "note",
          "additional info",
        ],
        [
          category,
          title,
          description,
          effectiveDate,
          newRestarauntEffectiveFrom,
          note,
          additionalInformation,
        ],
      ];
    }

    if (standardType == "upcoming") {
      const category = data.standard.category.name;
      const title = data.standard.title;
      const description = data.standard.description;
      const effectiveDate = data.standard.effectiveFrom;
      const newRestarauntEffectiveFrom =
        data.standard.newRestarauntEffectiveFrom;
      const note = data.standard.note;
      const additionalInformation = data.standard.additionalInformation;

      dataArray = [
        [
          "category",
          "title",
          "description",
          "effective date",
          "new restaraunt effective date",
          "note",
          "additional info",
        ],
        [
          category,
          title,
          description,
          effectiveDate,
          newRestarauntEffectiveFrom,
          note,
          additionalInformation,
        ],
      ];
    }

    if (standardType == "BU") {
      const category = data.standard.category.name;
      const title = data.standard.title;
      const description = data.standard.description;
      const effectiveDate = data.standard.effectiveFrom;
      const newRestarauntEffectiveFrom =
        data.standard.newRestarauntEffectiveFrom;
      const note = data.standard.note;
      const additionalInformation = data.standard.additionalInformation;

      dataArray = [
        [
          "category",
          "title",
          "description",
          "effective date",
          "new restaraunt effective date",
          "note",
          "additional info",
        ],
        [
          category,
          title,
          description,
          effectiveDate,
          newRestarauntEffectiveFrom,
          note,
          additionalInformation,
        ],
      ];
    }

    if (standardType == "market") {
      const category = data.standard.category.name;
      const title = data.standard.title;
      const description = data.standard.description;
      const effectiveDate = data.standard.effectiveFrom;
      const newRestarauntEffectiveFrom =
        data.standard.newRestarauntEffectiveFrom;
      const note = data.standard.note;
      const additionalInformation = data.standard.additionalInformation;

      dataArray = [
        [
          "category",
          "title",
          "description",
          "effective date",
          "new restaraunt effective date",
          "note",
          "additional info",
        ],
        [
          category,
          title,
          description,
          effectiveDate,
          newRestarauntEffectiveFrom,
          note,
          additionalInformation,
        ],
      ];
    }
    if (standardType == "obsoleteEquipment") {
      const category = data.standard.category.name;
      const title = data.standard.title;
      const description = data.standard.description;
      const effectiveDate = data.standard.effectiveFrom;
      const newRestarauntEffectiveFrom =
        data.standard.newRestarauntEffectiveFrom;
      const note = data.standard.note;
      const additionalInformation = data.standard.additionalInformation;
      const location = data.standard.equipmentStandard.locationType.name;
      const obsoleteDate = data.standard.equipmentStandard.obsoleteDate;

      dataArray = [
        [
          "category",
          "title",
          "description",
          "effective date",
          "obsoleteDate",
          "new restaraunt effective date",
          "note",
          "additional info",
          "location",
        ],
        [
          category,
          title,
          description,
          effectiveDate,
          obsoleteDate,
          newRestarauntEffectiveFrom,
          note,
          additionalInformation,
          location,
        ],
      ];
    }

    if (data.dependentStandards != null && data.dependentStandards.length > 0) {
      let dependentStandards = data.dependentStandards;
      dataArray.push([]);
      dataArray.push(["Global Recommendations"]);
      dataArray.push([
        "title",
        "descriptions",
        "category",
        "location",
        "stdType",
      ]);
      dependentStandards.map((dependentStandard) => {
        dataArray.push([
          dependentStandard.title,
          dependentStandard.description,
          dependentStandard.category.name,
          dependentStandard.locationType.name,
          dependentStandard.standardType.name,
        ]);
      });
    }

    // create a workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");
    dataArray.forEach((row) => {
      worksheet.addRow(row);
    });

    // create and download .xlsx file
    const blob = await workbook.xlsx.writeBuffer();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(
      new Blob([blob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    link.download = `${standardType.toUpperCase()}-STANDARD ${
      data.standard.title || ""
    } ${new Date().toLocaleString()}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // return
    return;
  }

  getMasterDataForAllStandards(userRole, userId): Observable<any> {
    return this.http.post("", { userRole, userId });
  }
}
