<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">Search Standard List</mat-card-title>
  </mat-card-header>
  <mat-card-content class="mcd-card-content">
    <div class="row filter-panel-row">
      <div class="col-12 filter-panel">
        <app-standard-search-filter></app-standard-search-filter>
      </div>
      <!-- <div class="col-3"></div> -->
    </div>
    <div>
      <app-locations-filter
        [isNrbes]="false"
        (messageEvent)="receivedFilteration($event)"
      ></app-locations-filter>
    </div>
    <div class="row">
      <div class="col-3">
        <mat-form-field>
          <mat-select
            [value]="pageSize"
            name="nOfRows"
            #items
            (selectionChange)="selectedItemsPerPageChange(items)"
          >
            <mat-option [value]="item.value" *ngFor="let item of pageSizes">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="table-wrapper" *ngIf="grses.length > 0">
      <table
        class="table nrbes-grid"
        matSort
        (matSortChange)="sortData($event)"
      >
        <thead class="thead-light">
          <tr>
            <th mat-sort-header="title" width="140" scope="col">Title</th>
            <th mat-sort-header="desc" scope="col-4" width="30%">
              Description
            </th>
            <th mat-sort-header="category" scope="col" width="170">Location</th>
            <th scope="col" width="170">Standard Type</th>
            <!-- <th scope="col" width="170">Existing Restaurant Effective From</th>
            <th scope="col" width="170">New Restaurant Effective From</th> -->
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of grses"
            (click)="rowSelected(item)"
            style="cursor: pointer"
          >
            <td class="title-cell">{{ item.title }}</td>
            <td class="description">
              <span title="{{ item.shortDescription }}" class="description">
                {{ item.shortDescription }}
              </span>
            </td>
            <td class="category-cell">{{ getLocationNames(item) }}</td>
            <td class="hide-sm">
              {{ getStandardTypeName(item.standardTypeId) }}
            </td>
            <!-- <td *ngIf="item.effectiveFrom" class="hide-sm">
              {{ item.effectiveFrom | date : "MM.dd.yyyy" }}
            </td>
            <td *ngIf="!item.effectiveFrom" class="hide-sm">N/A</td>
            <td *ngIf="item.newRestaurantsEffectiveFrom" class="hide-sm">
              {{ item.newRestaurantsEffectiveFrom | date : "MM.dd.yyyy" }}
            </td>
            <td *ngIf="!item.newRestaurantsEffectiveFrom" class="hide-sm">
              N/A
            </td> -->
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col">
          <ngb-pagination
            class="float-right"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="4"
            [rotate]="true"
            [ellipses]="true"
            [collectionSize]="collectionSize"
            (pageChange)="getStandards()"
          ></ngb-pagination>
        </div>
      </div>
    </div>
    <div *ngIf="grses.length == 0">
      Sorry you do not have any standard for selected location.
    </div>
  </mat-card-content>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
