export class MarketSupplier {
  supplierId: number;
  countryId: Array<number>;
  isAdded: boolean;
  constructor(obj?: MarketSupplier) {
    if (obj) {
      this.supplierId = obj.supplierId;
      this.countryId = obj.countryId;
      this.isAdded = obj.isAdded;
    }
  }
}
