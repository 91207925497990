import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { ThemePalette } from "@angular/material/core";
@Component({
  selector: "app-standard-search-filter",
  templateUrl: "./standard-search-filter.component.html",
  styleUrls: ["./standard-search-filter.component.scss"],
})
export class StandardSearchFilterComponent implements OnInit {
  searchQuery: string;
  filterOptions: any = [
    { id: "0", option: "All" },
    { id: "1", option: "Global Building Standards" },
    { id: "2", option: "Global Equipment Standards" },
    { id: "3", option: "Global Operation Standards" },
    { id: "4", option: "Global Approved Solutions" },
  ]; // Example options
  selectedOption: any;
  searchColor: ThemePalette = "primary";
  invalidQuery: Boolean = false;

  constructor(private route: ActivatedRoute, private router: Router) {}
  ngOnInit() {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.searchQuery = params.get("query") || "";
      this.selectedOption =
        this.filterOptions.find((e) => e.id == params.get("type")) ||
        this.filterOptions[0]; // Set default option if not provided
    });
  }

  selectOption(option: any, event: Event) {
    event.stopPropagation();
    console.log(option);
    this.selectedOption = option;
  }

  apply() {
    if (this.searchQuery == "") {
      this.invalidQuery = true;
      return;
    }
    this.invalidQuery = false;
    this.router.navigate(["/standards-search"], {
      queryParams: { query: this.searchQuery, type: this.selectedOption.id },
    });
  }
}
