import { Country } from "./country";
import { Standard } from "./standard";
import { AddendumStatus } from "./addendum-status";
import { AddendumStatusEnum, UserRoleEnum, Constant } from "../core/constant";
import { AddendumAttachment } from "./addendum-attachment";

export class Addendum {
  id: number;
  standardId: number;
  countryId: Array<number>;
  addendumStatusId: number;
  description: string;
  note: string;
  exceptionRenewalDate: Date;
  documentContent: any;
  created: Date;
  approvedOrRejectedDate: Date;
  expiryDate: Date;
  country: Array<Country>;
  standard: Standard;
  addendumStatus: AddendumStatus;
  userRoleWiseAddendumStatusName: string;
  createdUserId: number;
  addendumAttachment: AddendumAttachment[] = [];
  modifiedUserId: number;
  modified: Date;
  addendumApproval: any;

  //build standard Status Css class
  get addendumStatusCss() {
    if (this.addendumStatus) {
      return "mcd-pill " + this.addendumStatus.name.toLowerCase();
    }
  }

  //build standard Status Css for request date class
  get addendumStatusCssForReqDate() {
    if (this.addendumStatus) {
      let subClass = "";
      switch (this.addendumStatus.id) {
        case AddendumStatusEnum.Approved:
          subClass = "success";
          break;
        case AddendumStatusEnum.Rejected:
          subClass = "warning";
          break;
        case AddendumStatusEnum.Requested:
          subClass = "warning";
          break;
        case AddendumStatusEnum.Expired:
          subClass = "expire";
          break;
        case AddendumStatusEnum.DraftAfterRequest:
          subClass = "draft";
          break;
        case AddendumStatusEnum.DraftBeforeRequest:
          subClass = "draft";
          break;
        default:
          subClass = "";
      }
      return "mcd-date-box-header " + subClass;
    }
  }

  //build standard Status Css for approved or rejected date class
  get addendumStatusCssForApprovedOrRejectedDate() {
    if (this.addendumStatus) {
      let subClass = "";
      switch (this.addendumStatus.id) {
        case AddendumStatusEnum.Approved:
          subClass = "success";
          break;
        case AddendumStatusEnum.Rejected:
          subClass = "danger";
          break;
        case AddendumStatusEnum.Expired:
          subClass = "expire";
          break;
        default:
          subClass = "";
      }
      return "mcd-date-box-header " + subClass;
    }
  }

  get addendumStatusCssForexpiryDate() {
    if (this.addendumStatus) {
      let subClass = "";
      switch (this.addendumStatus.id) {
        case AddendumStatusEnum.Approved:
          subClass = "success";
          break;
        case AddendumStatusEnum.Expired:
          subClass = "expire";
          break;
        default:
          subClass = "";
      }
      return "mcd-date-box-header " + subClass;
    }
  }

  constructor(obj?: Addendum, userRole?: number) {
    if (obj) {
      this.id = obj.id;
      this.addendumApproval = obj.addendumApproval;
      this.standardId = obj.standardId;
      this.countryId = obj.countryId;
      this.addendumStatusId = obj.addendumStatusId;
      this.description = obj.description;
      this.note = obj.note;
      this.exceptionRenewalDate = obj.exceptionRenewalDate;
      this.documentContent = obj.documentContent;
      this.created = new Date(obj.created);
      this.approvedOrRejectedDate = obj.approvedOrRejectedDate
        ? new Date(obj.approvedOrRejectedDate)
        : null;
      this.expiryDate = obj.expiryDate ? new Date(obj.expiryDate) : null;
      this.country = obj.country.map((c) => new Country(c));
      this.standard = new Standard(obj.standard);
      this.addendumStatus = new AddendumStatus(obj.addendumStatus);
      if (userRole) {
        if (
          userRole != UserRoleEnum.BasicUser &&
          this.addendumStatus.id == AddendumStatusEnum.Requested
        ) {
          this.userRoleWiseAddendumStatusName =
            userRole == UserRoleEnum.GlobalAdmin
              ? this.addendumStatus.labelForGlobal
              : this.addendumStatus.labelForMarket;
        } else if (
          userRole != UserRoleEnum.BasicUser &&
          this.addendumStatus.id == AddendumStatusEnum.Approved
        ) {
          this.userRoleWiseAddendumStatusName =
            userRole == UserRoleEnum.GlobalAdmin
              ? this.addendumStatus.labelForGlobal
              : this.addendumStatus.labelForMarket;
        } else if (
          userRole != UserRoleEnum.BasicUser &&
          this.addendumStatus.id == AddendumStatusEnum.DraftBeforeRequest
        ) {
          this.userRoleWiseAddendumStatusName =
            this.addendumStatus.labelForMarket;
        } else if (
          userRole != UserRoleEnum.BasicUser &&
          this.addendumStatus.id == AddendumStatusEnum.DraftAfterRequest
        ) {
          this.userRoleWiseAddendumStatusName =
            userRole == UserRoleEnum.GlobalAdmin
              ? this.addendumStatus.labelForGlobal
              : this.addendumStatus.labelForMarket;
        } else {
          this.userRoleWiseAddendumStatusName = this.addendumStatus.name;
        }
      }
      this.createdUserId = obj.createdUserId;
      this.addendumAttachment = obj.addendumAttachment.map(
        (obj) => new AddendumAttachment(obj)
      );
      this.modifiedUserId = obj.modifiedUserId ? obj.modifiedUserId : null;
      this.modified = obj.modified ? new Date(obj.modified) : null;
    }
  }
}
