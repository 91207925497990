import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { StorageServiceModule } from "ngx-webstorage-service";
import { MaterialModule } from "./material";
import { CarouselModule } from "ngx-owl-carousel-o";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSortModule } from "@angular/material/sort";
import { MatRadioModule } from "@angular/material/radio";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { AppRoutingModule } from ".//app-routing.module";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";
import { AuthGuardService } from "./services/auth.guard.service";
import { RoleGuardService } from "./services/role.guard.service";
import { NotificationService } from "./services/notification.service";
import { OAuthModule, OAuthStorage } from "angular-oauth2-oidc";
import { QuillModule } from "ngx-quill";
import { MainCardComponent } from "./main-card/main-card.component";
import { LeftNavComponent } from "./left-nav/left-nav.component";
import { TableGridComponent } from "./table-grid/table-grid.component";
import { ModalDialogComponent } from "./modal-dialog/modal-dialog.component";
import { FaqModuleComponent } from "./faq-module/faq-module.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { RightNavComponent } from "./right-nav/right-nav.component";
import { EquipmentCardComponent } from "./equipment-card/equipment-card.component";
import { DeleteDialogComponent } from "./delete-dialog/delete-dialog.component";
import { KeyContactsComponent } from "./key-contacts/key-contacts.component";
import { AddContactComponent } from "./add-contact/add-contact.component";
import { NgbModule, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NrbesListComponent } from "./nrbes-list/nrbes-list.component";
import { AddendumComponent } from "./addendum/addendum.component";
import { AddendumListComponent } from "./addendum-list/addendum-list.component";
import { AddFaqComponent } from "./add-faq/add-faq.component";
import { EditNrbesComponent } from "./edit-nrbes/edit-nrbes.component";
import { CarouselComponent } from "./carousel/carousel.component";
import { AddGrsComponent } from "./add-grs/add-grs.component";
import { SuccessDialogComponent } from "./success-dialog/success-dialog.component";
import { NrbesDetailViewComponent } from "./nrbes-detail-view/nrbes-detail-view.component";
import { AddNrbesComponent } from "./add-nrbes/add-nrbes.component";
import { AddAbuStandardComponent } from "./add-abustandard/add-abustandard.component";
import { FilterTagsComponent } from "./filter-tags/filter-tags.component";
import { NotificationListComponent } from "./notification-list/notification-list.component";
import { McdLoaderComponent } from "./mcd-loader/mcd-loader.component";
import { AddAddendumComponent } from "./add-addendum/add-addendum.component";
import { McdLocationModuleComponent } from "./mcd-location-module/mcd-location-module.component";

// import { GlobalHttpErrorHandleService } from './services/globalHttpErrorHandleService';
import { FailModuleComponent } from "./fail-module/fail-module.component";
import { AddEquipmentComponent } from "./add-equipment/add-equipment.component";
import { EquipmentListComponent } from "./equipment-list/equipment-list.component";
import { EquipmentDetailViewComponent } from "./equipment-detail-view/equipment-detail-view.component";
import { LocationsComponent } from "./locations/locations.component";
import { InlineLoaderComponent } from "./inline-loader/inline-loader.component";
import { EmailTemplateComponent } from "./email-template/email-template.component";
import { LocationGrsListComponent } from "./location-grs-list/location-grs-list.component";
import { LocationGrsListFilterComponent } from "./location-grs-list-filter/location-grs-list-filter.component";
import { WishListGridComponent } from "./wishlist-grid/wishlist-grid.component";
import { WishlistDetailViewComponent } from "./wishlist-detail-view/wishlist-detail-view.component";
import { WishlistEquipmentDetailViewComponent } from "./wishlist-equipment-detail-view/wishlist-equipment-detail-view.component";
import { LocationTypeFilterComponent } from "./location-type-filter/location-type-filter.component";

import { EctComponent } from "./ect/ect.component";
import { AddEquipmentStandardComponent } from "./add-equipment-standard/add-equipment-standard.component";
import { ViewAllEquipmentStandardListComponent } from "./view-all-equipment-standard-list/view-all-equipment-standard-list.component";
import { ViewAllStandardDetailsViewComponent } from "./view-all-standard-details-view/view-all-standard-details-view.component";
import { ViewAllEquipmentsStandardDetailViewComponent } from "./view-all-equipments-standard-detail-view/view-all-equipments-standard-detail-view.component";
import { ViewAllObsoleteStandardListComponent } from "./view-all-obsolete-standard-list/view-all-obsolete-standard-list.component";
import { ViewAllUpcomingStandardListComponent } from "./view-all-upcoming-standard-list/view-all-upcoming-standard-list.component";
import { ViewAllUpcomingStandardDetailViewComponent } from "./view-all-upcoming-standard-detail-view/view-all-upcoming-standard-detail-view.component";
import { ViewAllUpcomingEquipmentDetailViewComponent } from "./view-all-upcoming-equipment-detail-view/view-all-upcoming-equipment-detail-view.component";
import { AddEquipmentStandardGlobalComponent } from "./add-equipment-standard-global/add-equipment-standard-global.component";
import { MinimumStandardDetailViewComponent } from "./view-all-minimum-standard-detail-view/view-all-minimum-standard-detail-view.component";
import { MinimumStandardListComponent } from "./view-all-minimum-standard-list/view-all-minimum-standard-list.component";
import { GlobalRecomendationsDetailViewComponent } from "./view-all-global-recomendations-detail-view/view-all-global-recomendations-detail-view.component";
import { GlobalRecomendationsListComponent } from "./view-all-global-recomentations-list/view-all-global-recomendations-list.component";
import { ViewAllNationalEquipmentStandardListComponent } from "./view-all-national-equipment-standard-list/view-all-national-equipment-standard-list.component";
import { ViewAllObsoleteStandardEquipmentDetailViewComponent } from "./view-all-obsolete-standard-list-detail-view/view-all-obsolete-standard-detail-view.component";
import { ViewAllNationalEquipmentsStandardDetailViewComponent } from "./view-all-national-equipment-standard-detail-view/view-all-national-equipments-standard-detail-view.component";
import { EquipmentListFilterComponent } from "./equipment-list-filter/equipment-list-filter.component";
import { ViewAllEquipmentAddendumComponent } from "./view-all-equipment-addendum/view-all-equipment-addendum.component";
import { EquipmentAddendumComponent } from "./equipment-addendum/equipment-addendum.component";
import { AddEquipmentAddendumComponent } from "./add-equipment-addendum/add-equipment-addendum.component";
import { ViewAllMnimumSandardListFilterComponent } from "./view-all-mnimum-sandard-list-filter/view-all-mnimum-sandard-list-filter.component";
import { ViewAllOperationsStandardsListFilterComponent } from "./view-all-operations-standards-list-filter/view-all-operations-standards-list-filter.component";
import { ViewAllGlobalRecomendationsListFilterComponent } from "./view-all-global-recomendations-list-filter/view-all-global-recomendations-list-filter.component";
import { ViewAllOperationsStandardsDetailViewComponent } from "./view-all-operations-standards-detail-view/view-all-operations-standards-detail-view.component";
import { AllStandardsComponent } from "./view-all-standards/view-all-standards.component";
import { EquipmentAddendumListComponent } from "./equipment-addendum-list/equipment-addendum-listcomponent";

import { ImageCropperModule } from "ngx-image-cropper";
import { SimpleModalModule } from "ngx-simple-modal-eq";
import { ModalImageCropperComponent } from "./modal-image-cropper/modal-image-cropper.component";
import { NgxImageCompressService } from "ngx-image-compress";

import { RichTextEditorAllModule } from "@syncfusion/ej2-angular-richtexteditor";
import { MatExpansionModule } from "@angular/material/expansion";
import { MarketAdminPanelComponent } from "./market-admin-panel/market-admin-panel.component";
import { GlobalAdminPanelComponent } from "./global-admin-panel/global-admin-panel.component";
import { ManageSuppliersComponent } from "./manage-suppliers/manage-suppliers.component";
import { WarningDialogComponent } from "./warning-dialog/warning-dialog.component";
import { ViewAllSupplierListComponent } from "./view-all-supplier-list/view-all-supplier-list.component";
import { AddSupplierComponent } from "./add-supplier/add-supplier.component";
import { AddEquipmentTypeComponent } from "./add-equipment-type/add-equipment-type.component";
import { AddCategoryComponent } from "./add-category/add-category.component";
import { ViewAllCategoryListComponent } from "./view-all-category-list/view-all-category-list.component";
import { ViewAllEquipmentTypeListComponent } from "./view-all-equipment-type-list/view-all-equipment-type-list.component";
import { ViewAllUserListComponent } from "./view-all-user-list/view-all-user-list.component";
import { AddUserComponent } from "./add-user/add-user.component";
import { UserDetailViewComponent } from "./user-detail-view/user-detail-view.component";
import { SupplierDetailViewComponent } from "./supplier-detail-view/supplier-detail-view.component";
import { AddNationalSupplierComponent } from "./add-national-supplier/add-national-supplier.component";
import { ViewAllNationalSupplierListComponent } from "./view-all-national-supplier-list/view-all-national-supplier-list.component";
import { NationalSupplierDetailViewComponent } from "./national-supplier-detail-view/national-supplier-detail-view.component";

import { ViewOperationsStandardListComponent } from "./view-all-operations-standards-list/view-all-operations-standards-list.component";
import { AddUsefulLinkComponent } from "./add-useful-link/add-useful-link.component";
import { ViewAllUsefulLinksComponent } from "./view-all-useful-links/view-all-useful-links.component";
import { UpdateApplicationStatementComponent } from "./update-application-statement/update-application-statement.component";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { ViewAllStandardsFilter } from "./view-all-standards-filter/view-all-standards-filter.component";
import { CloneNrbesComponent } from "./clone-nrbes/clone-nrbes.component";
import { CommonModule } from "@angular/common";
import { UpdateAddEndumComponent } from "./update-addendum/update-addendum.component";
import { RunViewScript } from "./run-view-script/run-view-script.component";
import { sideViewGloabalApprovedSolutions } from "./sideView-GloabalApprovedSolutions/sideView-GloabalApprovedSolutions";
import { SideViewUpcomingExceptionCountries } from "./sideView-UpcomingExceptionCountries/sideView-UpcomingExceptionCountries";
// import { sideViewGloabalApprovedSolutions } from "./sideView-GloabalApprovedSolutions";
import { StandardsSearchComponent } from "./standards-search/standards-search.component";
import { StandardSearchFilterComponent } from "./standard-search-filter/standard-search-filter.component";
import { LatestModifiedStandardsComponent } from "./latest-modified-standards/latest-modified-standards.component";
import { LocationsFilterComponent } from "./locations-filter/locations-filter.component";
import { AddAddendumUpcomingComponent } from "./add-addendum-upcoming/add-addendum-upcoming.component";
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UnauthorizedComponent,
    MainCardComponent,
    LeftNavComponent,
    TableGridComponent,
    ModalDialogComponent,
    FaqModuleComponent,
    NotificationsComponent,
    RightNavComponent,
    EquipmentCardComponent,
    DeleteDialogComponent,
    KeyContactsComponent,
    AddContactComponent,
    AddFaqComponent,
    DeleteDialogComponent,
    NrbesListComponent,
    AddendumComponent,
    AddendumListComponent,
    AddAddendumUpcomingComponent,
    EditNrbesComponent,
    CloneNrbesComponent,
    CarouselComponent,
    MinimumStandardDetailViewComponent,
    AddGrsComponent,
    SuccessDialogComponent,
    NrbesDetailViewComponent,
    AddNrbesComponent,
    AddAbuStandardComponent,
    FilterTagsComponent,
    MinimumStandardListComponent,
    GlobalRecomendationsDetailViewComponent,
    GlobalRecomendationsListComponent,
    NotificationListComponent,
    McdLoaderComponent,
    AddAddendumComponent,
    FailModuleComponent,
    AddEquipmentComponent,
    EquipmentDetailViewComponent,
    EquipmentListComponent,
    LocationsComponent,
    InlineLoaderComponent,
    EmailTemplateComponent,
    McdLoaderComponent,
    McdLocationModuleComponent,
    LocationGrsListComponent,
    LocationGrsListFilterComponent,
    WishListGridComponent,
    WishlistDetailViewComponent,
    WishlistEquipmentDetailViewComponent,
    EctComponent,
    AddEquipmentStandardComponent,
    ViewAllEquipmentStandardListComponent,
    ViewAllStandardDetailsViewComponent,
    ViewAllEquipmentsStandardDetailViewComponent,
    ViewAllObsoleteStandardListComponent,
    ViewAllObsoleteStandardEquipmentDetailViewComponent,
    ViewAllUpcomingStandardListComponent,
    ViewAllUpcomingStandardDetailViewComponent,
    ViewAllUpcomingEquipmentDetailViewComponent,
    AddEquipmentStandardGlobalComponent,
    ViewAllNationalEquipmentStandardListComponent,
    ViewAllObsoleteStandardEquipmentDetailViewComponent,
    LocationTypeFilterComponent,
    ViewAllNationalEquipmentsStandardDetailViewComponent,
    EquipmentListFilterComponent,
    ViewAllEquipmentAddendumComponent,
    EquipmentAddendumComponent,
    AddEquipmentAddendumComponent,
    ViewAllMnimumSandardListFilterComponent,
    ViewAllOperationsStandardsListFilterComponent,
    ViewAllOperationsStandardsDetailViewComponent,
    ViewAllGlobalRecomendationsListFilterComponent,
    EquipmentAddendumListComponent,
    ModalImageCropperComponent,
    MarketAdminPanelComponent,
    GlobalAdminPanelComponent,
    ManageSuppliersComponent,
    WarningDialogComponent,
    ViewAllSupplierListComponent,
    AddSupplierComponent,
    AddEquipmentTypeComponent,
    AddCategoryComponent,
    ViewAllCategoryListComponent,
    ViewAllEquipmentTypeListComponent,
    ViewAllUserListComponent,
    AddUserComponent,
    UserDetailViewComponent,
    SupplierDetailViewComponent,
    AddNationalSupplierComponent,
    ViewAllNationalSupplierListComponent,
    NationalSupplierDetailViewComponent,
    ViewOperationsStandardListComponent,
    AddUsefulLinkComponent,
    ViewAllUsefulLinksComponent,
    UpdateApplicationStatementComponent,
    AllStandardsComponent,
    ViewAllStandardsFilter,
    UpdateAddEndumComponent,
    RunViewScript,
    sideViewGloabalApprovedSolutions,
    SideViewUpcomingExceptionCountries,
    StandardsSearchComponent,
    StandardSearchFilterComponent,
    LatestModifiedStandardsComponent,
    LocationsFilterComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialModule,
    CarouselModule,
    FormsModule,
    AppRoutingModule,
    StorageServiceModule,
    ReactiveFormsModule,
    OAuthModule.forRoot(),
    QuillModule.forRoot(),
    NgbModule,
    MatCheckboxModule,
    RichTextEditorAllModule,
    MatRadioModule,
    SimpleModalModule,
    ImageCropperModule,
    MatExpansionModule,
    MatSortModule,
    SimpleModalModule,
    MatMenuModule,
    MatInputModule,
    CommonModule,
  ],
  providers: [
    AuthGuardService,
    RoleGuardService,
    NotificationService,
    NgbActiveModal,
    NgxImageCompressService,
    //{
    // provide: ErrorHandler,
    // useClass: GlobalHttpErrorHandleService
    //},
    { provide: OAuthStorage, useFactory: storageFactory },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
