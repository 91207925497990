import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class NRAuthInitializationService {
  private initialized = false;
  private tokenReceieved = false;
  isTokenReceieved(): boolean {
    return this.tokenReceieved;
  }
  setTokenReceieved(): void {
    this.tokenReceieved = true;
  }
  isInitialized(): boolean {
    return this.initialized;
  }

  setInitialized(): void {
    this.initialized = true;
  }
}
