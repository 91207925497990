import { Attachment } from "./attachment";

export class Supplier {
  id: number = 0;
  name: string;
  description: string;
  supplierLogoAttachment: Attachment = new Attachment();
  imageAttachmentContentAsBase64: string;
  countryId?: Array<number>;
  isGlobal: boolean;
  constructor(obj?: Supplier) {
    if (obj) {
      this.id = obj.id;
      this.name = obj.name;
      this.description = obj.description;
      this.supplierLogoAttachment = new Attachment(obj.supplierLogoAttachment);
      this.countryId = obj.countryId;
      this.isGlobal = obj.isGlobal;
    }
  }
}
