import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class NRInitializationService {
  private initialized = false;

  isInitialized(): boolean {
    return this.initialized;
  }

  setInitialized(): void {
    this.initialized = true;
  }
}
