export class SupplierFilter {
  startingIndex: number;
  pageSize: number;
  countryId: Array<number>;
  searchKey: string;
  constructor(obj?: SupplierFilter) {
    if (obj) {
      this.startingIndex = obj.startingIndex;
      this.pageSize = obj.pageSize;
      this.countryId = obj.countryId;
      this.searchKey = obj.searchKey;
    }
  }
}
