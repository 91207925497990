import { FilteringGuide } from "./filteringGuide";

export class NrbesFiltration {
  nrbesId: number;
  countryId: Array<number>;
  isEquipmentStandard: boolean;
  isForward: boolean;
  searchKey: string;
  filterSet: FilteringGuide[] = [];
}
