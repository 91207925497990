import { Component, Inject } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { Observable } from "rxjs";
import { StandardService } from "../services/standard.service";
import { LocationType } from "../models/location-type";
import { StandardType } from "../models/standardType";
import { Category } from "../models/category";
import { Standard } from "../models/standard";
import { ActivatedRoute, Router } from "@angular/router";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  StandardTypeEnum,
  StandardListType,
  UserRoleEnum,
  CategoryEnum,
  MasterDataRequestedType,
} from "../core/constant";
import { EquipmentClassType } from "../models/equipment-class-type";
import { StandardSearchView } from "../models/standard-search-view";
import { CryptographyService } from "../services/cryptography.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { SelectedStandard } from "../models/selected-standard";
import { SubLocationType } from "../models/sub-location-type";
import { DataRowOutlet } from "@angular/cdk/table";
import { ModalImageCropperComponent } from "../modal-image-cropper/modal-image-cropper.component";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { SimpleModalService } from "ngx-simple-modal-eq";
import { Subject } from "rxjs";
import { switchMap, debounceTime, distinctUntilChanged } from "rxjs/operators";

declare var $: any;
@Component({
  selector: "app-add-grs",
  templateUrl: "./add-grs.component.html",
  styleUrls: ["./add-grs.component.scss"],
})
export class AddGrsComponent {
  error: boolean = false;
  startTime: Date | null = null;
  endTime: Date | null = null;
  end: number;
  newCreatedStandard: any = [];
  searchSubject: Subject<string>;
  content: string = "Hello World!";
  loading: boolean = false;
  minimumStandardsLoading: boolean = false;
  globalstandards: boolean = false;
  addGrsForm: FormGroup;
  grs: SelectedStandard = new SelectedStandard();
  selected = "option2";
  stateCtrl = new FormControl();
  minimumStandadControl = new FormControl();
  minimumStandads: StandardSearchView[] = [];
  latestSearchResults: any = [];

  invalidImage: boolean = false;
  invalidAttachment: boolean = false;
  invalidImageRes: boolean = false;
  imageChangedEvent: any = "";

  locationTypes: LocationType[] = [];
  filteredLocationTypes: LocationType[] = [];
  standardTypes: StandardType[] = [];
  categories: Category[] = [];
  buildingCategories: Category[] = [];
  operationsCategories: Category[] = [];
  equipmentClassTypes: EquipmentClassType[] = [];
  subLocationTypes: SubLocationType[] = [];
  get standardTypeEnum() {
    return StandardTypeEnum;
  }
  get StandardListType() {
    return StandardListType;
  }
  get categoryEnum() {
    return CategoryEnum;
  }
  get userRoleEnum() {
    return UserRoleEnum;
  }
  getUniqueElementsByKey(array, key) {
    const seen = new Map();

    return array.filter((item) => {
      const keyValue = item[key];

      if (seen.has(keyValue)) {
        return false;
      } else {
        seen.set(keyValue, true);

        return true;
      }
    });
  }
  constructor(
    private standardService: StandardService,
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private ngbModal: NgbModal,
    private cryptographyService: CryptographyService,
    private SimpleModalService: SimpleModalService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {
    this.grs.standard.id = parseInt(
      this.activeRoute.snapshot.paramMap.get("grsId")
    );
  }

  ngOnInit(): void {
    this.grs.standard.userId = this.cryptographyService.decryptData(
      this.storage.get("userId")
    );
    if (this.grs.standard.id) {
      this.getGrbesDetailByGrbesId(this.grs.standard.id).subscribe(() => {
        this.setControlsValue();
      });
    }
    this.createForm();
    this.minimumStandadControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((data) => {
        this.onMinimumStandadSearch(data);
        $(document).ready(() => {
          $(".input-equipment-menu").keyup(function () {
            $(".equipment-menu").parents("div").addClass("equipments");
          });
        });
      });
    this.addGrsForm.controls.minimumStandadId.valueChanges.subscribe((data) =>
      console.log(
        "CHANGED IDS",
        this.addGrsForm.controls.minimumStandadId.value
      )
    );
  }

  /**
   * Create Form
   * @return void
   */
  createForm(): void {
    let locationTypesArray = [];
    let categoriesArray = [];

    this.addGrsForm = this.fb.group({
      title: ["", Validators.required],
      description: ["", Validators.required],
      newrestaurantdescription: ["", Validators.required],
      note: [""],
      additionalInformation: [""],
      categoryId: [""],
      locationTypeId: ["", Validators.required],
      standardTypeId: ["", Validators.required],
      subLocationTypeId: [""],
      effectiveFrom: [""],
      newRestaurantEffectiveFrom: [""],
      category: categoriesArray,
      minimumStandadId: [[]],
      locationType: locationTypesArray,
    });

    if (!this.grs.standard.id) {
      this.standardService
        .GetMasterDataForAddEditStandard(
          MasterDataRequestedType.AddGlobalStandard
        )
        .subscribe((data: any) => {
          this.buildingCategories = data.buildingStandardCategories;
          this.operationsCategories = data.operationsStandardsCategories;

          this.locationTypes = data.locationTypes;
          this.standardTypes = data.standardTypes;
          this.grs.standard.imageAttachment.id = 0;
          this.grs.standard.imageFileName = null;
          this.grs.standard.imageAttachmentContentAsBase64 = null;
          this.grs.standard.imageAttachment.fileName = null;
          this.grs.standard.imageAttachment.attachmentContentAsBase64 = null;

          if (this.locationTypes != null) {
            this.locationTypes
              .sort((firstItem, nextItem) =>
                firstItem.name.localeCompare(nextItem.name)
              )
              .forEach((element) => {
                locationTypesArray.push(
                  this.fb.group({
                    id: new FormControl(element.id),
                    name: new FormControl(element.name),
                  })
                );
              });
          }
          this.loading = false;
        });
    }
  }
  onSearchInputChange(searchTerm: string) {
    // console.log("On search", searchTerm);
    this.searchSubject.next(searchTerm);
  }
  onChangeStandardType(standardTypeId: number) {
    this.grs.standard.standardTypeId = standardTypeId;
    if (
      this.grs.standard.standardTypeId ==
        this.standardTypeEnum.GlobalMinimumRestaurantStandard ||
      this.grs.standard.standardTypeId ==
        this.standardTypeEnum.OperationsStandards
    ) {
      this.globalstandards = true;
      this.minimumStandadControl.setValidators(null);
      this.minimumStandadControl.updateValueAndValidity();
      this.addGrsForm.controls.minimumStandadId.setValidators(null);
      this.addGrsForm.controls.minimumStandadId.updateValueAndValidity();
      this.addGrsForm.controls.locationTypeId.setValidators(
        Validators.required
      );
      this.addGrsForm.controls.locationTypeId.updateValueAndValidity();
      if (
        this.grs.standard.standardTypeId ==
        this.standardTypeEnum.GlobalMinimumRestaurantStandard
      ) {
        this.addGrsForm.controls.categoryId.setValidators(Validators.required);
        this.addGrsForm.controls.categoryId.updateValueAndValidity();
      } else {
        this.addGrsForm.controls.categoryId.setValidators(null);
        this.addGrsForm.controls.minimumStandadId.updateValueAndValidity();
      }

      if (!this.grs.standard.id) {
        this.addGrsForm.controls.effectiveFrom.setValue(null);
        this.addGrsForm.controls.NewRestaurantEffectiveFrom.setValue(null);
      }
    } else {
      this.globalstandards = false;
      this.minimumStandadControl.setValidators(Validators.required);
      this.minimumStandadControl.updateValueAndValidity();
      this.addGrsForm.controls.minimumStandadId.setValidators(
        Validators.required
      );
      this.addGrsForm.controls.minimumStandadId.updateValueAndValidity();
      this.addGrsForm.controls.locationTypeId.setValidators(null);
      this.addGrsForm.controls.locationTypeId.updateValueAndValidity();
      this.addGrsForm.controls.categoryId.setValidators(null);
      this.addGrsForm.controls.categoryId.updateValueAndValidity();
      if (!this.grs.standard.id) {
        this.addGrsForm.controls.effectiveFrom.setValue(new Date());
        this.addGrsForm.controls.newRestaurantEffectiveFrom.setValue(
          new Date()
        );
      }
    }
  }

  getMinimumStandardsForSearchAfterLoading(x) {
    let latestSearchIds = this.latestSearchResults.map((o) => o.id);
    let selectedIds = this.addGrsForm.controls.minimumStandadId.value.map(
      (o) => o.id
    );
    return x.filter(
      (o) => latestSearchIds.includes(o.id) || selectedIds.includes(o.id)
    );
  }
  onMinimumStandadSearch(searchkey): any {
    this.latestSearchResults = [];
    console.log(this.addGrsForm.controls.minimumStandadId.value);
    if (searchkey) {
      if (searchkey.length > 2 && !this.grs.standard.id) {
        this.minimumStandardsLoading = true;
        this.standardService
          .getMinimumStandardsBySearchKey(searchkey)
          .subscribe((data: any) => {
            data.forEach((o) => {
              this.minimumStandads.push(o);
              this.latestSearchResults.push(o);
            });

            this.minimumStandads = this.getUniqueElementsByKey(
              this.minimumStandads,
              "id"
            );

            console.log(
              "EQUALITY COMPARISON",
              this.minimumStandads[0] ==
                this.addGrsForm.controls.minimumStandadId.value[0],
              this.minimumStandads[0],
              this.addGrsForm.controls.minimumStandadId.value[0]
            );
            this.minimumStandardsLoading = false;
            debugger;
          });
      }
    }
  }
  showSublocationField() {
    try {
      if (this.addGrsForm.controls.locationTypeId.value.includes(8)) {
        return true;
      }
    } catch (error) {
      /* error may occur when locationTypeId is not an array */
      return false;
    }
  }

  //getAllCategories(): void {
  //  this.loading = true;
  //  this.standardService.getAllCategories()
  //    .subscribe(
  //      (data: Category[]) => {
  //        this.categories = data.map(o => new Category(o));
  //        this.loading = false;
  //      });
  //}

  setLocationCategoryMapping(locationType) {
    this.addGrsForm.controls.locationTypeId.reset();
    this.addGrsForm.controls.locationTypeId.setValue(locationType.value);
    console.log("selected vm", this.addGrsForm.controls.locationTypeId.value);
    if (
      this.grs.standard.standardTypeId ==
        this.standardTypeEnum.OperationsStandards ||
      this.grs.standard.standardTypeId ==
        this.standardTypeEnum.GlobalMinimumRestaurantStandard
    ) {
      this.setSublocationsAndSublocationsvalidations(locationType);
    }
  }

  setCategory(category) {
    this.addGrsForm.controls.categoryId.setValue(category.value);
  }

  getAllLocationTypes(): void {
    this.standardService
      .getAllLocationTypes()
      .subscribe((data: LocationType[]) => {
        this.locationTypes = data.map((o) => new LocationType(o));
      });
  }

  getAllStandardTypes(): void {
    this.standardService
      .getAllStandardTypes(true, false)
      .subscribe((data: StandardType[]) => {
        this.standardTypes = data.map((o) => new StandardType(o));
      });
  }

  getStandardType(id) {
    let stdTypeNameMap = {
      1: "Obsolete Standard",
      2: "Building Standard",
      3: "Upcoming Standard",
      4: "Global Equipment Standard",
      5: "Market Equipment Standard",
      6: "Market Standard",
      7: "Location Standard",
      8: "Dependent Standard",
      9: "Global Approved Solution",
      10: "Shared Learnings and Recommendations",
      11: "Operations Standards",
    };
    return stdTypeNameMap[id];
  }

  startTimer() {
    this.startTime = new Date();
    // alert("timer started");
  }
  endTimer() {
    if (!this.startTime) {
      console.error("Start time is not set.");
      return;
    }
    this.endTime = new Date();
    const timeDiff = this.endTime.getTime() - this.startTime.getTime();
    const seconds = timeDiff / 1000;
    // alert("timer stopped");

    return seconds;
  }
  saveGrbes(): void {
    this.startTimer();
    this.newCreatedStandard = [];
    if (this.addGrsForm.valid) {
      this.grs.standard.standardTypeId =
        this.addGrsForm.controls.standardTypeId.value;
      this.grs.standard.title = this.addGrsForm.controls.title.value;
      this.grs.standard.description =
        this.addGrsForm.controls.description.value;
      this.grs.standard.newRestaurantDescription =
        this.addGrsForm.controls.newrestaurantdescription.value;
      this.grs.standard.userId = this.cryptographyService.decryptData(
        this.storage.get("userId")
      );
      this.grs.standard.note = this.addGrsForm.controls.note.value;
      this.grs.standard.additionalInformation =
        this.addGrsForm.controls.additionalInformation.value;
      this.grs.standard.effectiveFrom =
        this.addGrsForm.controls.effectiveFrom.value != null
          ? this.onBuildDateString(
              new Date(this.addGrsForm.controls.effectiveFrom.value)
                .toLocaleDateString("en-US")
                .replace(/\u200E/g, "")
            )
          : null;
      this.grs.standard.newRestaurantsEffectiveFrom =
        this.addGrsForm.controls.newRestaurantEffectiveFrom.value != null
          ? this.onBuildDateString(
              new Date(
                this.addGrsForm.controls.newRestaurantEffectiveFrom.value
              )
                .toLocaleDateString("en-US")
                .replace(/\u200E/g, "")
            )
          : null;

      if (
        this.grs.standard.standardTypeId ==
          this.standardTypeEnum.GlobalMinimumRestaurantStandard ||
        this.grs.standard.standardTypeId ==
          this.standardTypeEnum.OperationsStandards
      ) {
        if (
          this.grs.standard.standardTypeId ==
          this.standardTypeEnum.OperationsStandards
        ) {
          this.grs.standard.imageFileName =
            this.grs.standard.imageAttachment.fileName;
          this.grs.standard.subLocationTypeId = this.addGrsForm.controls
            .subLocationTypeId
            ? this.addGrsForm.controls.subLocationTypeId.value
            : null;
        }

        this.grs.standard.categoryId =
          this.addGrsForm.controls.categoryId.value;
        this.grs.standard.locationTypeId =
          this.addGrsForm.controls.locationTypeId.value;
      } else {
        this.grs.standard.dependentStandard.minimumStandadId =
          this.addGrsForm.controls.minimumStandadId.value;
      }

      if (this.grs.standard.id) {
        this.grs.standard.dependentStandard = null;
        this.updateAGrbes(this.grs.standard).subscribe((data) => {
          this.newCreatedStandard.push({
            name: this.grs.standard.title,
            totalTime: this.endTimer(),
            eventType: "NewStandardsPROD",
            status: "Modified",
            standardType: "GlobalStandard",
            session: JSON.parse(localStorage.getItem("NRBA_SESSION")).value,
            error: this.error,
            errorMessage: "No error",
          });
          console.log("Newlycretaedstandrd", this.newCreatedStandard);
          fetch(
            "https://insights-collector.newrelic.com/v1/accounts/4619405/events",
            {
              method: "POST",
              headers: {
                "Api-Key": "58be58f2497b3d9d4ed19a4bd40bd275FFFFNRAL",
              },
              body: JSON.stringify(this.newCreatedStandard),
            }
          );
          const modalRef = this.ngbModal.open(SuccessDialogComponent);
          modalRef.componentInstance.message = data.message;
          if (data == false) {
            modalRef.componentInstance.title = "Error";
            modalRef.componentInstance.message =
              "Some error occurred. Please try again after some time";
          }
          modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
            this.ngbModal.dismissAll();
            if (
              this.grs.standard.standardTypeId ==
              this.standardTypeEnum.OperationsStandards
            ) {
              this.router.navigate(["/view-all-standards"]);
            } else {
              this.onBackClickorEditRedirect();
            }
          });
          this.reset();
        });
      } else {
        this.grs.standard.id = 0;
        if (this.minimumStandads.length > 0) {
          console.log("TEST", this.addGrsForm.controls.minimumStandadId);
          this.grs.standard.dependentStandard = {
            minimumStandadId:
              this.addGrsForm.controls.minimumStandadId.value[0].id,
          };
          this.grs.standard.dependentStandards =
            this.addGrsForm.controls.minimumStandadId.value.map((e) => e.id);
        } else {
          this.grs.standard.dependentStandard = {
            DependentStandId: 0,
            Id: 0,
            MinimumStandId: 0,
            Title: null,
          };
          this.grs.standard.dependentStandards = null;
        }

        this.addAGrbes(this.grs.standard).subscribe((data) => {
          const modalRef = this.ngbModal.open(SuccessDialogComponent);
          this.newCreatedStandard.push({
            name: this.grs.standard.title,
            totalTime: this.endTimer(),
            eventType: "NewStandardsPROD",
            status: "Created",
            standardType: "GlobalStandard",
            session: JSON.parse(localStorage.getItem("NRBA_SESSION")).value,
            error: this.error,
            errorMessage: "No error",
          });
          console.log("Newlycretaedstandrd", this.newCreatedStandard);
          fetch(
            "https://insights-collector.newrelic.com/v1/accounts/4619405/events",
            {
              method: "POST",
              headers: {
                "Api-Key": "58be58f2497b3d9d4ed19a4bd40bd275FFFFNRAL",
              },
              body: JSON.stringify(this.newCreatedStandard),
            }
          );
          // let totalSeconds = this.endTimer();
          // alert(totalSeconds);
          modalRef.componentInstance.message = data.message;
          if (data == false) {
            modalRef.componentInstance.title = "Error";
            modalRef.componentInstance.message =
              "Some error occurred. Please try again after some time";
          }
          if (data.status == false) {
            modalRef.componentInstance.title = "Error";
          }
          modalRef.componentInstance.message = data.message;
          modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
            this.ngbModal.dismissAll();
            if (data.status) {
              if (
                this.grs.standard.standardTypeId ==
                this.standardTypeEnum.OperationsStandards
              ) {
                this.router.navigate(["/view-all-standards"]);
                this.reset();
              } else {
                this.router.navigate(["/view-all-standards"]);
                this.reset();
              }
            }
          });
        });
      }
    }
  }

  getGrbesDetailByGrbesId(grbesId: number): Observable<boolean> {
    this.loading = true;
    return new Observable<boolean>((observer) => {
      this.standardService
        .getStandardInfomationByStandardId(grbesId)
        .subscribe((data: SelectedStandard) => {
          this.grs = new SelectedStandard(data);
          this.buildingCategories.push(this.grs.standard.category);
          this.operationsCategories.push(this.grs.standard.category);
          this.standardTypes.push(this.grs.standard.standardType);
          this.locationTypes.push(this.grs.standard.locationType);
          if (this.grs.standard.locationType.subLocationTypes.length > 0) {
            this.subLocationTypes.push(this.grs.standard.subLocationType);
          }
          this.loading = false;
          observer.next(true);
        });
    });
  }

  addAGrbes(standard: Standard): Observable<any> {
    this.loading = true;
    return new Observable<any>((observer) => {
      this.standardService.addStandard(standard).subscribe(
        (data: any) => {
          this.loading = false;
          observer.next(data);
        },
        (error) => {
          this.loading = false;
          this.newCreatedStandard.push({
            name: this.grs.standard.title,
            totalTime: this.endTimer(),
            eventType: "NewStandardsPROD",
            status: "Failed",
            standardType: "GlobalStandard",
            session: JSON.parse(localStorage.getItem("NRBA_SESSION")).value,
            error: true,
            errorMessage: error.message,
          });
          console.log("Newlycretaedstandrd", this.newCreatedStandard);
          fetch(
            "https://insights-collector.newrelic.com/v1/accounts/4619405/events",
            {
              method: "POST",
              headers: {
                "Api-Key": "58be58f2497b3d9d4ed19a4bd40bd275FFFFNRAL",
              },
              body: JSON.stringify(this.newCreatedStandard),
            }
          );
          observer.next(false);
        }
      );
    });
  }

  updateAGrbes(standard: Standard): Observable<any> {
    this.loading = true;
    return new Observable<any>((observer) => {
      this.standardService.updateStandard(standard).subscribe(
        (data: any) => {
          this.loading = false;
          observer.next(data);
        },
        (error) => {
          this.loading = false;
          this.newCreatedStandard.push({
            name: this.grs.standard.title,
            totalTime: this.endTimer(),
            eventType: "NewStandardsPROD",
            status: "Failed",
            standardType: "GlobalStandards",
            session: JSON.parse(localStorage.getItem("NRBA_SESSION")).value,
            error: true,
            errorMessage: error.message,
          });
          console.log("Newlycretaedstandrd", this.newCreatedStandard);
          fetch(
            "https://insights-collector.newrelic.com/v1/accounts/4619405/events",
            {
              method: "POST",
              headers: {
                "Api-Key": "58be58f2497b3d9d4ed19a4bd40bd275FFFFNRAL",
              },
              body: JSON.stringify(this.newCreatedStandard),
            }
          );
          observer.next(false);
        }
      );
    });
  }

  reset() {
    this.addGrsForm.controls.title.reset();
    this.addGrsForm.controls.note.reset();
    this.addGrsForm.controls.additionalInformation.reset();
    this.addGrsForm.controls.description.reset();
    this.addGrsForm.controls.newrestaurantdescription.reset();
    this.addGrsForm.controls.effectiveFrom.reset();
    this.addGrsForm.controls.locationTypeId.reset();
    this.addGrsForm.controls.locationTypeId.setValue(null);
    this.addGrsForm.controls.categoryId.reset();
    this.addGrsForm.controls.categoryId.setValue(null);
    this.addGrsForm.controls.standardTypeId.reset();
    this.addGrsForm.controls.standardTypeId.setValue(null);
    this.addGrsForm.controls.minimumStandadId.reset();
    this.addGrsForm.controls.effectiveFrom.reset();
    this.addGrsForm.controls.newRestaurantEffectiveFrom.reset();
    this.minimumStandadControl.reset();
    this.buildingCategories = [];
    this.operationsCategories = [];

    this.grs.standard.imageAttachment.fileName = null;
    this.grs.standard.imageAttachment.attachmentContentAsBase64 = null;
    this.grs.standard.imageFileName = null;
    this.grs.standard.imageAttachmentContentAsBase64 = null;
    this.invalidImage = false;
    this.createForm();
  }

  setControlsValue() {
    this.addGrsForm.controls.locationTypeId.setValue(
      this.grs.standard.locationType.id
    );
    this.addGrsForm.controls.categoryId.setValue(this.grs.standard.category.id);
    this.addGrsForm.controls.standardTypeId.setValue(
      this.grs.standard.standardTypeId
    );
    this.addGrsForm.controls.title.setValue(this.grs.standard.title);
    this.addGrsForm.controls.title.disable();
    this.minimumStandadControl.disable();
    this.minimumStandadControl.setValue(
      this.grs.standard.dependentStandard.title
    );
    this.addGrsForm.controls.description.setValue(
      this.grs.standard.description
    );
    this.addGrsForm.controls.newrestaurantdescription.setValue(
      this.grs.standard.newRestaurantDescription
    );
    this.addGrsForm.controls.note.setValue(this.grs.standard.note);
    this.addGrsForm.controls.additionalInformation.setValue(
      this.grs.standard.additionalInformation
    );

    if (this.grs.standard.effectiveFrom != null) {
      this.addGrsForm.controls.effectiveFrom.setValue(
        new Date(this.grs.standard.effectiveFrom)
      );
    } else {
      this.addGrsForm.controls.effectiveFrom.setValue(
        this.grs.standard.effectiveFrom
      );
    }

    if (this.grs.standard.newRestaurantsEffectiveFrom != null) {
      this.addGrsForm.controls.newRestaurantEffectiveFrom.setValue(
        new Date(this.grs.standard.newRestaurantsEffectiveFrom)
      );
    } else {
      this.addGrsForm.controls.newRestaurantEffectiveFrom.setValue(
        this.grs.standard.newRestaurantsEffectiveFrom
      );
    }

    this.addGrsForm.controls.minimumStandadId.setValue([
      this.grs.standard.dependentStandard.id,
    ]);
    this.onChangeStandardType(this.grs.standard.standardTypeId);

    if (this.grs.standard.locationType.subLocationTypes.length > 0) {
      this.subLocationTypes.push(this.grs.standard.subLocationType);
      this.addGrsForm.controls.subLocationTypeId.setValue(
        this.grs.standard.subLocationTypeId
      );
    }
  }

  onBackClickorEditRedirect() {
    this.router.navigate([this.standardService.standardEditPreviousRoute]);
  }

  onBuildDateString(date: string) {
    let partsArray = date.split("/");
    let buildedDate = "";
    partsArray.forEach((x) => {
      if (x.length == 1 || x.length == 2) {
        if (x.length == 1) {
          buildedDate += ("0" + x).slice(-2) + "/";
        } else {
          buildedDate += x + "/";
        }
      } else {
        buildedDate += x;
      }
    });
    return buildedDate;
  }

  setSubLocation(subLocationType) {
    this.addGrsForm.controls.subLocationTypeId.reset();
    this.addGrsForm.controls.subLocationTypeId.setValue(subLocationType.value);
  }

  setSublocationsAndSublocationsvalidations(locationType) {
    locationType.value.map((e) => {
      this.locationTypes.filter((p) => {
        if (e == p.id && p.subLocationTypes.length > 0) {
          // console.log(p.subLocationTypes);
          this.subLocationTypes = p.subLocationTypes;
        }
      });
    });
    // this.subLocationTypes = this.locationTypes.filter(
    //   (p) => p.id == locationType.value
    // )[0].subLocationTypes;
    if (this.subLocationTypes.length > 0) {
    } else {
      this.addGrsForm.controls.subLocationTypeId.setValidators(null);
      this.addGrsForm.controls.subLocationTypeId.updateValueAndValidity();
    }
  }

  onRemoveImage() {
    this.grs.standard.imageAttachment.id = 0;
    this.grs.standard.imageFileName = null;
    this.grs.standard.imageAttachmentContentAsBase64 = null;
    this.grs.standard.imageAttachment.fileName = null;
    this.grs.standard.imageAttachment.attachmentContentAsBase64 = null;
    this.invalidImage = false;
    this.invalidImageRes = false;
  }

  onDownloadSelectedImage(id) {
    if (id) {
      return this.standardService.getAttachmentUrl(id, false).subscribe(
        (response: any) => {
          const blob = new Blob([response], {
            type: response.type,
          });
          if (
            (window.navigator as any) &&
            (window.navigator as any).msSaveOrOpenBlob
          ) {
            (window.navigator as any).msSaveOrOpenBlob(blob);
            return;
          }
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        (error: any) => {
          const modalRef = this.ngbModal.open(FailModuleComponent);
          modalRef.componentInstance.errorMessage = error.error
            ? error.error.Message
            : error.message;
        }
      );
    } else {
      fetch(this.grs.standard.imageAttachmentContentAsBase64)
        .then((res) => res.blob())
        .then((blob) => {
          if ((window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveBlob(
              blob,
              this.grs.standard.imageAttachment.fileName
            );
          } else {
            var a = document.createElement("a");
            a.href = this.grs.standard.imageAttachmentContentAsBase64;
            a.setAttribute(
              "download",
              this.grs.standard.imageAttachment.fileName
            );
            a.click();
          }
        });
    }
  }

  onSelectImage(event) {
    this.invalidImageRes = false;
    let selectedFile = event.target.files[0];
    this.imageChangedEvent = event.target.files[0];
    this.invalidImage = this.validateFileType(selectedFile.name);
    if (!this.invalidImage) {
      this.showPrompt().then((result) => {
        console.log(result);
        if (result == null) {
          this.grs.standard.imageAttachmentContentAsBase64 = null;
          event.target.value = null;
          this.grs.standard.imageAttachment.fileName = null;
        } else {
          this.grs.standard.imageAttachmentContentAsBase64 = result;
          event.target.value = null;
          this.grs.standard.imageAttachment.fileName = selectedFile.name;
        }
      });
    }
  }

  validateFileType(fileName: string) {
    let fileTypeArr = ["jpg", "jpeg", "png", "bmp"];
    if (fileName != null) {
      let fileNameArr = [];
      let splitedFileName = fileName.toLowerCase().split(".");
      splitedFileName.forEach(function (obj) {
        fileNameArr.push(obj);
      });
      fileNameArr = fileNameArr.reverse();
      if (!fileTypeArr.includes(fileNameArr[0])) {
        return true;
      }
      return false;
    }
  }

  validateImageResolution(selectedFile, status): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      if (status == false) {
        var url = URL.createObjectURL(selectedFile);
        var img = new Image();
        img.onload = function () {
          if (img.width != img.height) {
            observer.next(true);
          }
        };
        img.src = url;
      }
    });
  }

  showPrompt(): Promise<string> {
    return new Promise<string>((resolve) => {
      this.SimpleModalService.addModal(ModalImageCropperComponent, {
        title: "Crop your image",
        ratio: 1,
        event: this.imageChangedEvent,
      }).subscribe((image) => {
        resolve(image);
      });
    });
  }
}
