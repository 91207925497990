import { Component, Input, OnInit } from "@angular/core";
import { StandardService } from "../services/standard.service";
@Component({
  selector: "app-sideView-UpcomingExceptionCountries",
  templateUrl: "./sideView-UpcomingExceptionCountries.html",
  styleUrls: ["./sideView-UpcomingExceptionCountries.scss"],
})
export class SideViewUpcomingExceptionCountries implements OnInit {
  redirectUpcomingException: any = "/addendum/";
  exTitle: string = "";
  latestModification: any;
  addendums: any;
  dependentStandards: any;
  @Input() inputData: any;
  constructor(public standardService: StandardService) {}
  ngOnInit() {
    // this.standardService.getLatestModifiedStandards().subscribe((response) => {
    console.log("inputtt-upcomh", this.inputData);

    this.addendums = this.inputData.addendum;
    console.log("addednums-ucomng", this.addendums);
    // this.exTitle = "vipul";
    this.latestModification = [
      {
        title: "Retrofit Cost",
        description:
          "Before Retrofitting a spyhole, check with the local construction team that the price of a retrofit is more cost effective than replacing the door",
        Countries: ["Canada,UK, Spain, India"],
      },
      {
        title: "Retrofit Cost",
        description:
          "Before Retrofitting a spyhole, check with the local construction team that the price of a retrofit is more cost effective than replacing the door",
        Countries: ["Canada,UK, Spain, India"],
      },
    ];
    // });
  }
}
